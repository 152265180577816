import { Sticky } from "@pomle/react-viewstack";
import { Direction, Fade, Slide } from "@pomle/react-viewstack-transitions";
import { type ReactNode } from "react";

type Keys = keyof typeof Direction;
type Values = (typeof Direction)[Keys];

type Animation =
  | { animation: "fade"; direction?: never }
  | { animation: "slide"; direction: Values };

export function mount<T extends {}>(
  Component: (props: T) => ReactNode,
  params: Animation & { exact?: boolean }
) {
  return function render(match: { params: T; exact: boolean } | null) {
    const isMatch = params.exact ? match?.exact === true : match != null;

    return (
      <Animate
        active={isMatch}
        direction={params.direction}
        variant={params.animation}
      >
        <Sticky delay={100}>
          {isMatch && <Component {...match!.params} />}
        </Sticky>
      </Animate>
    );
  };
}

type AnimateProps = {
  active: boolean;
  children: ReactNode;
  direction?: Values;
  variant: "fade" | "slide";
};

function Animate({ active, children, direction, variant }: AnimateProps) {
  switch (variant) {
    case "fade":
      return <Fade active={active}>{children}</Fade>;
    case "slide":
      return (
        <Slide active={active} direction={direction!}>
          {children}
        </Slide>
      );
    default:
      return children;
  }
}
