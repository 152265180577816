import { PropsWithChildren } from "react";
import styles from "./styles.module.sass";

interface BackdropProps {
  active: boolean;
  /** @default 'blur' */
  kind?: "blur" | "subtle" | "transparent";
  onOutsideClick?: () => void;
  /** @default 'default' */
  size?: "default" | "half";
}

export function Backdrop({
  active,
  children,
  kind = "blur",
  onOutsideClick,
  size,
}: PropsWithChildren<BackdropProps>) {
  return (
    <div
      className={styles.Backdrop}
      data-active={active}
      data-kind={kind}
      data-size={size}
      onClick={(event) => {
        if (event.target !== event.currentTarget) {
          return;
        }
        onOutsideClick?.();
      }}
    >
      <div className={styles.content}>{children}</div>
    </div>
  );
}
