import { Typography } from "@cur8/maneki";
import type { ReactNode } from "react";
import { COLORS } from "render/ui/style/colors";
import styles from "./styles.module.sass";

export type BadgeVariant = "normal" | "warning" | "danger" | "noData";

interface BadgeProps {
  children?: ReactNode;
  noPadding?: boolean;
  variant: BadgeVariant;
}

function getVariantStyles(variant: BadgeVariant) {
  const styles: Record<
    BadgeVariant,
    { color: string; backgroundColor: string }
  > = {
    danger: {
      backgroundColor: COLORS.LIGHT_OPTIMISTIC_RED,
      color: COLORS.DARK_OPTIMISTIC_RED,
    },
    noData: {
      backgroundColor: COLORS.STEEL_GREY_200,
      color: COLORS.STEEL_GREY_800,
    },
    normal: {
      backgroundColor: COLORS.INTELLIGENT_BLUE_100_40,
      color: COLORS.DARK_INTELLIGENT_BLUE,
    },
    warning: {
      backgroundColor: COLORS.ALERT_ORANGE_100,
      color: COLORS.ALERT_ORANGE_900,
    },
  };

  return styles[variant];
}

export function Badge({ children, noPadding, variant }: BadgeProps) {
  return (
    <div
      style={{
        backgroundColor: getVariantStyles(variant).backgroundColor,
        color: getVariantStyles(variant).color,
        stroke: getVariantStyles(variant).color,
      }}
      className={styles.badge}
      data-padding={!noPadding}
    >
      <Typography variant="eyebrow-s" as="span">
        {children}
      </Typography>
    </div>
  );
}
