import type { MetricName } from "@cur8/measurements";
import type { FollowUpMessage, ImmutableVisitSummary } from "@cur8/rich-entity";
import { desc } from "lib/sort";
import type { DateTime } from "luxon";
import { useMemo } from "react";
import { useAppointmentSummariesQuery } from "render/hooks/api/queries/useAppointmentSummariesQuery";
import { getMetricLink } from "./getMetricLink";
import * as Trans from "./trans";

export function useSummaries() {
  const { data = [] } = useAppointmentSummariesQuery();

  return useMemo(
    () => data.map(toSummary).filter((s) => s.messages.length),
    [data]
  );
}

type Summary = {
  hasFeedback: boolean;
  lastUpdatedAt?: DateTime;
  messages: Message[];
  visitId: string;
};

type Message = {
  date?: DateTime;
  doctorUri?: string;
  id: string;
  notificationId?: string;
  seen: boolean;
  text: string;
  title: string | JSX.Element;
};

function toSummary(ivs: ImmutableVisitSummary): Summary {
  return {
    hasFeedback: !!ivs.memberFeedback,
    lastUpdatedAt: ivs.audit.lastModified?.timeStamp,
    messages: [
      ...ivs.followUpMessages
        .map(toMessage)
        .filter((m) => m.text)
        .toSorted(desc((m) => m.date)),
      {
        date: ivs.visitDate,
        doctorUri: ivs.audit.created?.userUri?.toString(),
        id: ivs.itemVersion,
        seen: ivs.isSummarySeen,
        text: mapMetricLinks(ivs.summaryText),
        title: <Trans.BodyScan />,
      },
    ],
    visitId: ivs.visitId,
  };
}

function toMessage(fum: FollowUpMessage): Message {
  return {
    date: fum.audit.created?.timeStamp,
    doctorUri: fum.useGenericAuthor
      ? undefined
      : fum.audit.created?.userUri?.toString(),
    id: fum.notificationId,
    notificationId: fum.notificationId,
    seen: fum.isMessageSeen,
    text: mapMetricLinks(fum.body),
    title: fum.title,
  };
}

/**
 * Replaces hyperlinks containing MetricName placeholders with actual urls.
 * @example
 * mapMetricLinks('Foo [BMI](body.bmi) bar');
 * // 'Foo [BMI](?metric=bmi) bar'
 */
function mapMetricLinks(text: string) {
  return text.replaceAll(
    hyperlinkRegex,
    (_, text: string, link: MetricName) => `[${text}](${getMetricLink(link)})`
  );
}

const hyperlinkRegex = /\[([^[\]]+)\]\(([^()]+)\)/g;
