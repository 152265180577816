import { Weight } from "@cur8/measurements";

export function toStone(force: Weight) {
  const stone = force.kilograms * 0.1575;
  const justStone = Math.floor(stone);
  const pounds = (stone - justStone) * 14;

  return {
    pounds,
    stone: justStone,
  };
}
