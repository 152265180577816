import type { Query } from "@pomle/paths";
import { PathLink } from "@pomle/react-router-paths";
import type { ReactNode } from "react";

type QueryLinkProps<Q extends Query<any>> = {
  children: ReactNode;
  query: Q;
  params: Parameters<Q["build"]>[0];
};

export function QueryLink<Q extends Query<any>>({
  children,
  query,
  params,
}: QueryLinkProps<Q>) {
  return <PathLink to={`?${query.build(params)}`}>{children}</PathLink>;
}
