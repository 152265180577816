import PointCloudVideoWideMP4 from "assets/video/pointcloud/background-pointcloud-wide.mp4";
import PointCloudImageWide from "assets/video/pointcloud/background-pointcloud-wide.png";
import PointCloudVideoWideWebM from "assets/video/pointcloud/background-pointcloud-wide.webm";
import PointCloudVideoMP4 from "assets/video/pointcloud/background-pointcloud.mp4";
import PointCloudImage from "assets/video/pointcloud/background-pointcloud.png";
import PointCloudVideoWebm from "assets/video/pointcloud/background-pointcloud.webm";
import { useState } from "react";
import { useIsViewportAtLeast } from "render/hooks/useIsViewportAtLeast";
import { breakpoints } from "render/ui/style/breakpoints";
import styles from "./styles.module.sass";

const minMd = `(min-width: ${breakpoints.md})`;

export function VideoBackground() {
  const [opacity, setOpacity] = useState(0);
  const key = useIsViewportAtLeast("md") ? "desktop" : "mobile";

  return (
    <>
      <picture key={`${key}_picture`}>
        <source srcSet={PointCloudImageWide} media={minMd} />
        <source srcSet={PointCloudImage} />
        <img
          className={styles.videoBackgroundImage}
          src={PointCloudImageWide}
          alt="wave-background"
        />
      </picture>
      <video
        key={`${key}_video`}
        style={{
          opacity,
        }}
        onPlay={() => setOpacity(1)}
        className={styles.videoBackground}
        preload="auto"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={PointCloudVideoWideWebM} type="video/webm" media={minMd} />
        <source src={PointCloudVideoWideMP4} type="video/mp4" media={minMd} />
        <source src={PointCloudVideoWebm} type="video/webm" />
        <source src={PointCloudVideoMP4} type="video/mp4" />
      </video>
    </>
  );
}
