import { APITypesV1 } from "@cur8/api-client";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

export function isExpired(code: APITypesV1.DiscountToken) {
  return code.isValid === false || code.expired === true;
}

export function selectActiveInvitationCodes(codes: APITypesV1.DiscountToken[]) {
  return codes.filter((code) => !isExpired(code));
}

const queryKey = (patientId: string) => ["invitation-codes", patientId];
const queryFn = (apiClient: APIClient, patientId: string) => async () => {
  const result =
    await apiClient.billing.getPatientDiscountTokenCodes(patientId).result;

  return result.items;
};

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export function useInvitationCodesQuery<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    ...options,
    queryFn: queryFn(apiClient, patientId),
    queryKey: queryKey(patientId),
  });
}

export function useActiveInvitationCodesQuery<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  return useInvitationCodesQuery({
    ...options,
    select: selectActiveInvitationCodes,
  });
}
