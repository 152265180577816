import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>HbA1c</>,
  sv_SE: <>HbA1c</>,
});

const MetricLabel = localize({
  en_GB: <>mmol/mol</>,
  sv_SE: <>mmol/mol</>,
});

const FAQ = {
  Aim: localize({
    en_GB: (
      <>
        You should aim to keep your HbA1c below 39 mmol/mol to have an optimal
        value and reduce the risk of being diagnosed with diabetes. Prediabetes
        is preventable through lifestyle changes such as weight loss and
        reducing fast carbohydrates from your diet.
      </>
    ),
    sv_SE: (
      <>
        Du bör sträva efter att hålla ditt HbA1cunder 39 mmol/mol för att ha ett
        optimalt värde och minska risken för att bli diagnostiserad med
        diabetes. Prediabetes är möjligt att påverka och förhindra genom
        livsstilsåtgärder som att gå ner i vikt och minska snabba kolhydrater i
        din kost.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        A high result could increase the risk of being diagnosed with diabetes.
      </>
    ),
    sv_SE: (
      <>Ett högt resultat ökar risken att bli diagnostiserad med diabetes.</>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        A low result can be a sign that your body is handling glucose very
        efficiently. It can also be caused by problems with haemoglobin (the Hb
        part of HbA1c), if your body is losing red blood cells too quickly, then
        this can falsely lower the HbA1c test.
      </>
    ),
    sv_SE: (
      <>
        Ett lågt resultat kan vara ett tecken på att din kropp hanterar glukos
        mycket effektivt. Detta ses oftast hos yngre personer och personer med
        mycket muskelmassa. I vissa fall kan lågt HbA1c orsakas av olika
        sjukdomstillstånd som påverkar hemoglobin.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        The trend in HbA1c tells you how well your body is handling glucose. If
        you are carrying extra weight around your middle (called visceral fat)
        then this will make your body less sensitive to insulin. This will make
        your metabolism work less well, and your HbA1c will rise between tests.
        If you see your HbA1c climbing upward then it can be a sign that you
        need to exercise more, eat more healthily, or reduce your alcohol
        intake. If you have a healthy HbA1c and it is stable over time, then you
        are doing well at balancing your body’s food and exercise levels with
        your metabolism. If you are making positive lifestyle changes to lose
        weight or eat more healthily you can monitor your HbA1c over time and
        use it to motivate yourself.
      </>
    ),
    sv_SE: (
      <>
        Trenden i HbA1c talar om hur väl din kropp hanterar ditt blodsocker. Om
        du ser ditt HbA1c stiga över tid kan det vara ett tecken på att du
        behöver träna mer, äta hälsosammare eller minska ditt alkoholintag. Om
        ditt HbA1c påvisar ett hälsosamt resultat och är stabilt över tid
        innebär detta att du har en god balans mellan din livsstil i form av
        träning och kost. Genom att följa trenden kan du även utvärdera effekten
        av livsstilsförändringar.
      </>
    ),
  }),
  Why: localize({
    en_GB: (
      <>
        The HbA1c test measures how much glucose has stuck to the haemoglobin in
        your blood. Haemoglobin is the pigment in red blood cells which carries
        oxygen and gives blood its colour. People with high glucose levels will
        have more glucose stuck to their haemoglobin than people with low
        glucose levels. Because red blood cells live for a couple of months the
        HbA1c test tells us how well your body has been handling glucose over
        the preceding 6 to 8 weeks. It reacts slowly to changes in blood
        glucose, and because of this it is great for monitoring the trend.
      </>
    ),
    sv_SE: (
      <>
        HbA1c mäter hur dina sockervärden har sett ut de senaste 6 till 8
        veckorna och är därför en bra markör för att bedöma skydd mot diabetes
        och följa trender. Blodsocker lagras i hemoglobin, ett pigment i röda
        blodkroppar som transporterar syre och ger blodet dess färg. Ju högre
        blodsockernivåer du har i genomsnitt över tid kommer det att lagras mer
        i ditt hemoglobin, vartefter HbA1c stiger.
      </>
    ),
  }),
};

export const Trans = { FAQ, MetricLabel, MetricName };
