import { MetricRiskInterval, RiskLabel } from "@cur8/measurements";
import { asc } from "lib/sort";
import { ReactNode } from "react";
import { ChartRange } from "../types";
import { inRange } from "./inRange";
import { Trans } from "./trans";

const LABEL_MAP: Record<RiskLabel, ReactNode> = {
  balanced: <Trans.Balanced />,
  diabetes: <Trans.Diabetes />,
  elevated: <Trans.Elevated />,
  high: <Trans.High />,
  "left deviation": "-",
  low: <Trans.Low />,
  mild: <Trans.Mild />,
  moderate: <Trans.Moderate />,
  normal: <Trans.Normal />,
  optimal: <Trans.Optimal />,
  "pre-diabetes": <Trans.PreDiabetes />,
  prolonged: "-",
  "right deviation": "-",
  shortened: "-",
  unbalanced: <Trans.Imbalanced />,
  undefined: <Trans.Unknown />,
  "very high": <Trans.VeryHigh />,
  "very low": <Trans.VeryLow />,
  "very unbalanced": <Trans.VeryImbalanced />,
};

export function toChartRanges(
  ranges: MetricRiskInterval[] | undefined = []
): ChartRange[] {
  return ranges.map((range) => {
    return {
      from: range.start.value,
      fromIsInclusive: !!range.start.inclusive,
      label: LABEL_MAP[range.riskLabel],
      risk: range.riskLevel,
      to: range.end.value,
      toIsInclusive: !!range.end.inclusive,
    };
  });
}

export function capToMaxRanges({
  ranges,
  current,
  previous,
  maxNumOfRanges = 3,
}: {
  ranges: ChartRange[];
  current?: number;
  previous?: number;
  maxNumOfRanges?: number;
}) {
  if (!current) {
    return ranges.slice(0, maxNumOfRanges);
  }

  const sortedRanges = ranges.toSorted(asc((r) => r.to));
  const activeRange = sortedRanges.find(inRange(current));
  const previousRange = sortedRanges.find(inRange(previous));
  const previousIndex = previousRange && sortedRanges.indexOf(previousRange);
  const currentIndex = activeRange ? sortedRanges.indexOf(activeRange) : 0;

  const [start, end] = getRange({
    currentIndex,
    desiredOutputLength: maxNumOfRanges,
    length: ranges.length,
    previousIndex,
  });

  return sortedRanges.slice(start, end);
}

function getRange({
  currentIndex: cur,
  previousIndex: prev,
  length,
  desiredOutputLength,
}: {
  currentIndex: number;
  previousIndex: number | undefined;
  length: number;
  desiredOutputLength: number;
}): [number, number] {
  // if gap between the values is greater than desired output length, then show all the ranges necessary
  if (prev != null && Math.abs(cur - prev) > desiredOutputLength) {
    return [Math.min(prev, cur), Math.max(prev, cur)];
  }
  // else find range that contains prev and current index, and matches the desired output length
  else {
    let rangeStartIndex = Math.max(
      0,
      prev != null && prev <= cur
        ? cur - prev > 1
          ? prev
          : prev - 1
        : cur > 2
          ? cur - 2
          : cur - 1
    );

    let rangeEndIndex = rangeStartIndex + desiredOutputLength;

    // if range end index exceeds list length, anchor at list end and work backwards to get range start
    if (rangeEndIndex > length) {
      rangeEndIndex = length;
      rangeStartIndex = Math.max(0, rangeEndIndex - desiredOutputLength);
    }

    return [rangeStartIndex, rangeEndIndex];
  }
}
