import { useNav, useQueryParams } from "@pomle/react-router-paths";
import { ViewStack } from "@pomle/react-viewstack";
import { Direction, Slide } from "@pomle/react-viewstack-transitions";
import { splitSlotId } from "lib/visit/splitSlotId";
import { useState } from "react";
import { useVisitPrerequisites } from "render/hooks/api/useVisitPrerequisites";
import { paths, queries } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { OnboardingFlowView } from "render/views/OnboardingView/components/OnboardingFlowView";
import { OnboardingStudiesSummaryView } from "render/views/OnboardingView/components/OnboardingStudiesSummaryView";
import { OnboardingStudiesConsentView } from "render/views/OnboardingView/components/OnboardingStudyConsentView";

enum ActiveView {
  Studies = 0,
  StudiesConsent = 1,
  OnBoarding = 2,
}

interface AppointmentOnboardingProps {
  slotId: string;
}

export function AppointmentOnboarding({ slotId }: AppointmentOnboardingProps) {
  const { visitId } = splitSlotId(slotId);
  const [params] = useQueryParams(queries.onboarding);
  const visitPrerequisites = useVisitPrerequisites({
    origin: params.origin[0],
    visitId,
  });
  const [activeView, setActiveView] = useState<number>(() => {
    switch (params.initialStep[0]) {
      case "onboarding":
        return ActiveView.OnBoarding;
      case "studies-consent":
        return ActiveView.StudiesConsent;
      case "studies":
      default:
        return ActiveView.Studies;
    }
  });
  const nav = {
    appointment: useNav(paths.appointment),
    home: useNav(paths.root),
    prereq: useNav(paths.appointmentPrereq.root),
  };

  return (
    <FullScreenPageLayout>
      <ViewStack>
        <Slide
          active={activeView >= ActiveView.Studies}
          direction={Direction.Right}
        >
          <OnboardingStudiesSummaryView
            onClose={() => {
              if (params.origin[0] === "prereq") {
                nav.prereq.go({ slotId });
              } else {
                nav.appointment.go({ slotId });
              }
            }}
            onNext={() => {
              setActiveView(ActiveView.StudiesConsent);
            }}
          />
        </Slide>
        <Slide
          active={activeView >= ActiveView.StudiesConsent}
          direction={Direction.Right}
        >
          <OnboardingStudiesConsentView
            onClose={() => {
              if (params.origin[0] === "prereq") {
                nav.prereq.go({ slotId });
              } else {
                setActiveView(ActiveView.Studies);
              }
            }}
            onSave={() => {
              if (params.origin[0] === "prereq") {
                nav.prereq.go({ slotId });
              } else if (!visitPrerequisites.hasCompletedOnboarding) {
                setActiveView(ActiveView.OnBoarding);
              } else {
                nav.appointment.go({ slotId });
              }
            }}
            visitId={visitId}
          />
        </Slide>
        <Slide
          active={activeView >= ActiveView.OnBoarding}
          direction={Direction.Right}
        >
          <OnboardingFlowView
            onSuccess={() => {
              if (params.origin[0] === "prereq") {
                nav.prereq.go({ slotId });
                return;
              }
              const nextStep = visitPrerequisites.getNextStep({
                after: "questionnaire",
              });
              if (nextStep) {
                nextStep(slotId);
                return;
              }
              nav.appointment.go({ slotId });
            }}
            onNavBack={() => {
              if (params.origin[0] === "prereq") {
                nav.prereq.go({ slotId });
              } else if (params.initialStep[0] === "onboarding") {
                nav.appointment.go({ slotId });
              } else {
                setActiveView(ActiveView.Studies);
              }
            }}
            visitId={visitId}
          />
        </Slide>
      </ViewStack>
    </FullScreenPageLayout>
  );
}
