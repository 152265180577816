import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Blood pressure</>,
  sv_SE: <>Blodtryck</>,
});

const MetricUnit = localize<{ unit: string }>({
  en_GB: ({ unit }) => <>{unit} mmHg</>,
  sv_SE: ({ unit }) => <>{unit} mmHg</>,
});

const FAQ = {
  Aim: localize({
    en_GB: (
      <>
        Ideally, you should aim for an optimal systolic blood pressure reading
        below 120 mmHg and 80 mmHg. Optimal blood pressure increases your
        protection against cardiovascular disease. Your doctor might advise
        treatment if your blood pressure climbs above 140/90. Regular
        monitoring, along with healthy lifestyle choices can help you to
        maintain your blood pressure within the ideal range, promoting overall
        heart health and longevity.
      </>
    ),
    sv_SE: (
      <>
        Helst bör du eftersträva ett optimalt systoliskt blodtryck under 120
        mmHg. Ett optimalt blodtryck ökar ditt skydd mot hjärt-kärlsjukdomar. Du
        kan minska ditt blodtryck genom att öka din konditionsträning,
        eftersträva ett normalt BMI och begränsa ditt saltintag och
        alkoholkonsumtion. Din läkare kan rekommendera medicinsk behandling om
        ditt blodtryck stiger över 140/90. Regelbunden övervakning, tillsammans
        med hälsosamma livsstilsvanor kan hjälpa dig att hålla ditt blodtryck
        inom det optimala intervallet och främja en god hälsa.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        A high blood pressure reading (hypertension) suggests that your heart is
        working harder than normal to circulate blood. This can be due to
        narrowed or stiffened arteries, an increased volume of blood, or your
        heart beating faster or more forcefully. Conditions like obesity, a
        sedentary lifestyle, excessive salt intake, high alcohol consumption and
        genetics can all contribute to developing hypertension. The damage from
        high blood pressure adds up over the years, damaging the walls of your
        blood vessels and heart. If left untreated, it increases the risk of
        heart disease, stroke, and kidney problems.
      </>
    ),
    sv_SE: (
      <>
        Ett högt blodtryck (hypertoni) betyder att ditt hjärta arbetar hårdare
        än normalt för att pumpa runt blod. Detta kan till exempel bero på
        förträngningar eller stelhet i dina blodkärl eller att ditt hjärta slår
        snabbare eller kraftigare. Tillstånd som övervikt, en stillasittande
        livsstil, överdrivet saltintag, högt alkoholintag och genetik kan alla
        bidra till att utveckla hypertoni. Skada från högt blodtryck ackumuleras
        över åren hos såväl blodkärl som hjärta. Om det förblir obehandlat ökar
        risken för hjärtsjukdomar, stroke och njurproblem.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        Low blood pressure (hypotension) can be perfectly healthy. If it causes
        problems like dizziness or fainting, then you should see your doctor
        about it. People who exercise a lot will have blood pressure at or below
        the lower limit of normal. Causes of low blood pressure also include
        dehydration, some medications, cardiovascular or metabolic problems.
      </>
    ),
    sv_SE: (
      <>
        Ett lågt blodtryck (hypotoni) utan besvär med yrsel eller svimning är
        helt normalt och hälsosamt. Det är vanligt med lågt blodtryck hos långa
        och smala personer och framför allt bland kvinnor. Andra orsaker till
        lågt blodtryck är uttorkning, biverkningar av mediciner och problem
        kopplat till din metabolism eller hjärthälsa.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        Monitoring your blood pressure over time can help you to understand how
        your lifestyle is affecting your blood pressure and your risk of
        developing diseases in the long-term. Blood pressure tends to rise as we
        get older, you can minimize and even stop this by doing plenty of
        cardiovascular exercise, eating a healthy low salt diet and minimizing
        alcohol consumption.
      </>
    ),
    sv_SE: (
      <>
        Att övervaka ditt blodtryck över tid kan hjälpa dig att förstå hur din
        livsstil påverkar ditt blodtryck och din risk att utveckla
        hjärt-kärlsjukdom på lång sikt. Blodtrycket stiger i regel med ålder på
        grund av åderförkalkning varför det är viktigt att hålla det på optimala
        och normala nivåer så länge som möjligt.
      </>
    ),
  }),
  Why: localize({
    en_GB: (
      <>
        Measuring your blood pressure is important because it offers insights
        into the health of your circulatory system, particularly the force your
        heart uses to pump blood around your body, and the health of your blood
        vessels. By comparing the pressure in your arteries when your heart
        beats (systolic pressure) to the pressure when your heart is at rest
        (diastolic pressure), we gain valuable information about the health of
        your heart and blood vessels.
      </>
    ),
    sv_SE: (
      <>
        Att mäta ditt blodtryck är grundläggande för att förstå hur din
        hjärt-kärlhälsa står till. Det förklarar vilken kraft ditt hjärta
        använder för att pumpa runt blod i kroppen samt hur dina blodkärl mår.
        Ett blodtryck består av trycket i dina artärer när ditt hjärta pumpar
        (systoliskt blodtryck) och trycket när ditt hjärta vilar (diastoliskt
        blodtryck).
      </>
    ),
  }),
};

export const Trans = { FAQ, MetricName, MetricUnit };
