import { APIFetchError } from "@cur8/api-client";
import { baseApiUrlResolver } from "@cur8/config";
import { APIClient, createClient } from "lib/api/client";
import { createContext, useCallback, useContext, useMemo } from "react";
import {
  SeverityLevel,
  useAppInsights,
} from "render/context/AppInsightsContext";
import { useConfig } from "./ConfigContext";
import { useMSAL } from "./MSALContext";

type APIContextState = {
  client?: APIClient;
};

const INITIAL: APIContextState = {
  client: undefined,
};

const Context = createContext<APIContextState>(INITIAL);

interface APIContextProps {
  children: React.ReactNode;
}

export function APIContext({ children }: APIContextProps) {
  const config = useConfig();
  const { auth, instance, logout } = useMSAL();
  const appInsights = useAppInsights();

  const getToken = useCallback(async () => {
    if (!auth?.account) {
      throw new Error("No account");
    }
    const request = {
      account: auth.account,
      scopes: config.appConfig.msal.scopes.token,
    };
    const response = await instance.acquireTokenSilent(request);
    return response.accessToken;
  }, [auth, config, instance]);

  const api = useMemo(() => {
    return {
      client: createClient({
        buildURL: baseApiUrlResolver(config),
        getToken,
        onError: (error: any) => {
          if (error instanceof APIFetchError) {
            appInsights.trackException({
              exception: error,
              severityLevel: SeverityLevel.Error,
            });
            if (error.response.status === 401) {
              // if api returns 401 it means our session has expired
              // we go with logout here to do a full page refresh
              // that way we clear all old sessions and do a new login after
              logout();
            }
          }
        },
      }),
    };
  }, [config, getToken, logout, appInsights]);

  return <Context.Provider value={api}>{children}</Context.Provider>;
}

export function useAPIContext() {
  return useContext(Context);
}

export function useAPIClient() {
  const { client } = useAPIContext();
  if (!client) {
    throw new Error("API Client not initialized");
  }
  return client;
}
