import {
  Answer,
  Answers,
  isMultiChoiceResponse,
  Response,
} from "@cur8/questionnaire";
import { localeToISO639Code } from "lib/i18n/localeToISO639Code";
import {
  CardioConditions,
  CardioConditionsEnum,
  CardioConditionsValvularDisease,
} from "lib/questionnaire/question-flows/onboarding/questions/cardioConditions";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { useLocale } from "render/context/InternationalizationContext";
import { MultiChoiceInputWithDescriptions } from "render/ui/presentation/Questionnaire/components/MultiChoiceInputWithDescriptions";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function CardioConditionsStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(CardioConditions);
  const label = TransQuestions.CardioConditions.Question();
  const { englishLabel } = TransQuestions.CardioConditions.Question;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={CardioConditions}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(CardioConditions);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.CardioConditionsExplanation />,
        openText: <Trans.General.WhyAreWeAsking />,
      }}
    >
      <MultiChoiceInputWithDescriptions
        question={CardioConditions}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(CardioConditions, {
            answer,
            questionAnswer: {
              answer: {
                $type: "textarray",
                value: CardioConditions.getValue(answer),
              },
              englishLabel,
              label,
              languageCode,
              questionId: "cardioConditions",
            },
          });
        }}
      />
    </QuestionView>
  );
}

function ctaContent(answer: Answer<Response> | undefined) {
  if (
    answer &&
    isMultiChoiceResponse(answer.response) &&
    answer.response.choiceIndices.length
  ) {
    return <Trans.General.Continue />;
  }
  return <TransQuestions.CardioConditions.NotSure />;
}

function CardioConditionsValvularDiseaseStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(CardioConditionsValvularDisease);
  const label = TransQuestions.CardioConditions.ValvularDiseaseQuestion();
  const { englishLabel } =
    TransQuestions.CardioConditions.ValvularDiseaseQuestion;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);
  const cta = ctaContent(answer);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={CardioConditionsValvularDisease}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(CardioConditionsValvularDisease);
      }}
      cta={cta}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: (
          <Trans.Steps.CardioConditionsValvularDiseaseExplanation />
        ),
        openText: <Trans.General.WhyAreWeAsking />,
      }}
    >
      <MultiChoiceInputWithDescriptions
        question={CardioConditionsValvularDisease}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(CardioConditionsValvularDisease, {
            answer,
            questionAnswer: {
              answer: {
                $type: "textarray",
                value: CardioConditionsValvularDisease.getValue(answer),
              },
              englishLabel,
              label,
              languageCode,
              questionId: "cardioConditions.valvularDisease",
            },
          });
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(CardioConditionsStep);

  if (
    answers
      .lookup(CardioConditions)
      ?.includes(CardioConditionsEnum.ValvularDisease)
  ) {
    steps.push(CardioConditionsValvularDiseaseStep);
  }
}
