import { useRiskMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";

export function useNumOfScans(side: "left" | "right") {
  const { data: left } = useRiskMetric("cardio.brachial_pressure.left");
  const { data: right } = useRiskMetric("cardio.brachial_pressure.right");

  if (!left || !right) {
    return;
  }

  const numOfScanLeft = left.length;
  const numOfScanRight = right.length;

  return side === "left" ? numOfScanLeft : numOfScanRight;
}
