import { localize } from "render/context/InternationalizationContext";

export const Trans = {
  Button: localize({
    en_GB: `OK, let's begin`,
    sv_SE: "OK, låt oss börja",
  }),
  ContentOne: localize({
    en_GB: (
      <>
        Before your scan, please take a moment to answer our health assessment.
      </>
    ),
    sv_SE: (
      <>Innan din scan ber vi dig svara på några frågor rörande din hälsa.</>
    ),
  }),
  ContentTwo: localize({
    en_GB: (
      <>
        Your answers will prepare your medical team and help them personalise
        your scan. The more info you can give us, the better.
      </>
    ),
    sv_SE: (
      <>
        Dina svar kommer att hjälpa vårt medicinska team att förbereda sig och
        göra din scan mer personlig. Ju mer detaljerad info du kan ge oss, desto
        bättre blir det.
      </>
    ),
  }),
  Time: localize({
    en_GB: "It takes about 5 minutes to answer",
    sv_SE: "Det tar ca 5 minuter att svara på frågorna",
  }),
  Title: localize<{ name: string }>({
    en_GB: ({ name }) => `Hi ${name},`,
    sv_SE: ({ name }) => `Hej ${name},`,
  }),
};
