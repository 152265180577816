import { useQueryParams } from "@pomle/react-router-paths";
import { queries } from "render/routes/paths";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";
import { MetricDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Details";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useRiskMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

export function MetricLymphocytesDetails() {
  const { data } = useRiskMetric("bloodwork.white_blood_cells.lymphocytes");

  const [params, setParams] = useQueryParams(queries.metric);
  const numOfScans = data?.length || 1;
  const scanNum = params.scan[0] || numOfScans;
  const setScanNum = (num: number) => setParams({ scan: [num] });

  if (!data) {
    return;
  }

  const { cur: current, prev: previous } = getMetricHistory(data, scanNum);

  const unit = "x10⁹/L";
  const { riskIntervals, riskLevel } = current?.riskRanges ?? {};

  const values = {
    metricRating: riskLevel,
    previousValue: previous?.unit[unit],
    ranges: toChartRanges(riskIntervals),
    scanDate: current?.measurement.timestampStart,
    subLabel: unit,
    value: current?.unit[unit],
  };

  return (
    <MetricDetails
      label={<Trans.MetricName />}
      values={values}
      scanNum={scanNum}
      setScanNum={setScanNum}
      numOfScans={numOfScans}
      FAQText={Trans.FAQ}
      hideChange
    />
  );
}
