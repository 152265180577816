import { localeToISO639Code } from "lib/i18n/localeToISO639Code";
import { AdditionalWorries } from "lib/questionnaire/question-flows/onboarding/questions/additionalWorries";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { useLocale } from "render/context/InternationalizationContext";
import { TextInput } from "render/ui/presentation/Questionnaire/components/TextInput";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function AdditionalWorriesStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(AdditionalWorries);
  const label = TransQuestions.AdditionalWorries.Question();
  const { englishLabel } = TransQuestions.AdditionalWorries.Question;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={AdditionalWorries}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(AdditionalWorries);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.AdditionalWorriesExplanation />,
        openText: <Trans.General.WhyAreWeAsking />,
      }}
    >
      <TextInput
        placeholder={Trans.General.WriteYourAnswer()}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(AdditionalWorries, {
            answer,
            questionAnswer: {
              answer: {
                $type: "text",
                value: AdditionalWorries.getValue(answer).trim(),
              },
              englishLabel,
              label,
              languageCode,
              questionId: "additionalWorries",
            },
          });
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[]) {
  steps.push(AdditionalWorriesStep);
}
