import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: "Unit of measurement",
  sv_SE: "Måttenhet",
});

const Description = localize({
  en_GB: "Choose your preference",
  sv_SE: "Välj din preferens",
});

const Actions = {
  Save: localize({
    en_GB: "Save",
    sv_SE: "Spara",
  }),
  Saving: localize({
    en_GB: "Saving",
    sv_SE: "Sparar",
  }),
};

export const Unit = {
  Imperial: localize({
    en_GB: "Imperial",
    sv_SE: "Imperial",
  }),
  Metric: localize({
    en_GB: "Metric",
    sv_SE: "Metrisk",
  }),
};

export const Trans = {
  Actions,
  Description,
  Title,
  Unit,
};
