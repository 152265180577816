import { localize } from "render/context/InternationalizationContext";

export const HeartAndCirculation = localize({
  en_GB: "Heart & circulation",
  sv_SE: "Hjärta och cirkulation",
});

export const BloodFats = localize({
  en_GB: "Blood fats",
  sv_SE: "Blodfetter",
});

export const Body = localize({
  en_GB: "Body",
  sv_SE: "Kropp",
});

export const Diabetes = localize({
  en_GB: "Diabetes",
  sv_SE: "Diabetes",
});

export const OtherBloodValues = localize({
  en_GB: "Other blood values",
  sv_SE: "Andra blodvärden",
});

export const Skin = localize({
  en_GB: "Skin",
  sv_SE: "Hud",
});
