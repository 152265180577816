import { APITypesV1 } from "@cur8/api-client";
import { useMemo } from "react";
import { Values } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Details/types";
import { Trans } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/trans";
import { BadgeState } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/types";

type useBadgeStateProps = Pick<Values, "value" | "metricRating">;

export function useBadgeState({
  value,
  metricRating,
}: useBadgeStateProps): BadgeState | undefined {
  const badgeState = useMemo(() => {
    const noData = {
      text: <Trans.NoData />,
      variant: "noData",
    } as const;

    const warning = {
      text: <Trans.Warning />,
      variant: "warning",
    } as const;

    const normal = {
      text: <Trans.Normal />,
      variant: "normal",
    } as const;

    const optimal = {
      text: <Trans.Optimal />,
      variant: "normal",
    } as const;

    const record: Record<APITypesV1.RiskType, BadgeState> = {
      [APITypesV1.RiskType.Optimal]: optimal,
      [APITypesV1.RiskType.Unknown]: normal,
      [APITypesV1.RiskType.Normal]: normal,
      [APITypesV1.RiskType.LowRisk]: warning,
      [APITypesV1.RiskType.Risk]: warning,
      [APITypesV1.RiskType.ModerateRisk]: warning,
      [APITypesV1.RiskType.HighRisk]: warning,
      [APITypesV1.RiskType.ImmediateRisk]: warning,
    };

    if (value === undefined) {
      return noData;
    }

    if (metricRating === undefined) {
      return undefined;
    }

    return record[metricRating];
  }, [value, metricRating]);

  return badgeState;
}
