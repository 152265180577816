import { localize } from "render/context/InternationalizationContext";

const TokenInvalid = {
  Description: localize({
    en_GB: "We were unable to check you in. Please try again.",
    sv_SE: "Vi kunde inte checka in dig. Vänligen försök igen.",
  }),
  Header: localize({
    en_GB: "Oh no...",
    sv_SE: "Åh nej...",
  }),
};

const SessionVersionNotSupported = {
  Description: localize({
    en_GB: "We were unable to check you in. Please try again.",
    sv_SE: "Vi kunde inte checka in dig. Vänligen försök igen.",
  }),
  Header: localize({
    en_GB: "Oh no...",
    sv_SE: "Åh nej...",
  }),
};

export const SignOut = localize({
  en_GB: "Sign out",
  sv_SE: "Logga ut",
});

export const Trans = {
  SessionVersionNotSupported,
  SignOut,
  TokenInvalid,
};
