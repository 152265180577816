import { Event, Action } from "render/hooks/useTracking";

const category = "Onboarding";

export const onboardingEvent = {
  openContactsSidebarClick: (value: "open" | "close"): Event => {
    return {
      action: Action.Click,
      category,
      label: "open_contacts_sidebar",
      value,
    };
  },
};
