import { ChoiceQuestion, MultiChoiceQuestion } from "@cur8/questionnaire";
import { Trans } from "./trans";

export enum SkinConditionsEnum {
  Yes = "Yes",
  No = "No",
}

export enum SkinConditionsTypeEnum {
  Acne = "Acne",
  Eczema = "Eczema",
  Psoriasis = "Psoriasis",
  Rosacea = "Rosacea",
  SkinCancer = "Skin cancer",
  SomethingElse = "Something else",
}

export enum SkinCancerTypeEnum {
  ActinicKeratosis = "Actinic Keratosis",
  BCC = "BCC",
  Melanoma = "Melanoma",
  SCC = "SCC",
  NotSure = "Not sure",
}

export const SkinConditions = new ChoiceQuestion(
  <Trans.SkinConditions.Question />,
  [
    [<Trans.SkinConditions.No />, SkinConditionsEnum.No],
    [<Trans.SkinConditions.Yes />, SkinConditionsEnum.Yes],
  ]
);

export const SkinConditionsType = new MultiChoiceQuestion(
  <Trans.SkinConditions.TypeQuestion />,
  [
    [
      {
        description: <Trans.SkinConditions.AcneDescription />,
        label: <Trans.SkinConditions.Acne />,
      },
      SkinConditionsTypeEnum.Acne,
    ],
    [
      {
        description: <Trans.SkinConditions.EczemaDescription />,
        label: <Trans.SkinConditions.Eczema />,
      },
      SkinConditionsTypeEnum.Eczema,
    ],
    [
      {
        description: <Trans.SkinConditions.PsoriasisDescription />,
        label: <Trans.SkinConditions.Psoriasis />,
      },
      SkinConditionsTypeEnum.Psoriasis,
    ],
    [
      {
        description: <Trans.SkinConditions.RosaceaDescription />,
        label: <Trans.SkinConditions.Rosacea />,
      },
      SkinConditionsTypeEnum.Rosacea,
    ],
    [
      {
        description: <Trans.SkinConditions.SkinCancerDescription />,
        label: <Trans.SkinConditions.SkinCancer />,
      },
      SkinConditionsTypeEnum.SkinCancer,
    ],
    [
      { description: undefined, label: <Trans.SkinConditions.SomethingElse /> },
      SkinConditionsTypeEnum.SomethingElse,
    ],
  ]
);

export const SkinConditionsSkinCancerType = new ChoiceQuestion(
  <Trans.SkinConditions.SkinCancerQuestion />,
  [
    [
      {
        description: <Trans.SkinConditions.ActinicKeratosisDescription />,
        label: <Trans.SkinConditions.ActinicKeratosis />,
      },
      SkinCancerTypeEnum.ActinicKeratosis,
    ],
    [
      {
        description: <Trans.SkinConditions.BCCDescription />,
        label: <Trans.SkinConditions.BCC />,
      },
      SkinCancerTypeEnum.BCC,
    ],
    [
      {
        description: <Trans.SkinConditions.MelanomaDescription />,
        label: <Trans.SkinConditions.Melanoma />,
      },
      SkinCancerTypeEnum.Melanoma,
    ],
    [
      {
        description: <Trans.SkinConditions.SCCDescription />,
        label: <Trans.SkinConditions.SCC />,
      },
      SkinCancerTypeEnum.SCC,
    ],
    [
      { description: undefined, label: <Trans.SkinConditions.NotSure /> },
      SkinCancerTypeEnum.NotSure,
    ],
  ]
);
