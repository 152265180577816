import { Typography } from "@cur8/maneki";
import { PathLink, useNav } from "@pomle/react-router-paths";
import { ReactComponent as ChevronRightIcon } from "assets/icons/16x16/16x16_chevron-right.svg";
import { ReactComponent as CloseIcon } from "assets/icons/24x24/24x24_close.svg";
import { paths } from "render/routes/paths";
import { DateReadable } from "render/ui/format/DateReadable";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { IconButton } from "render/ui/trigger/IconButton";
import { useSummaries } from "render/views/Report/AppointmentDataView/hooks/useSummaries";
import styles from "./styles.module.sass";
import * as Trans from "./trans";

export function ReportHistory() {
  // the first item is not "history", it's the "latest"
  // so we ignore it in this view
  const summaries = useSummaries().slice(1);
  const nav = {
    item: useNav(paths.appointmentSummaryHistoryItem),
    summary: useNav(paths.appointmentSummary),
  };

  return (
    <FullScreenPageLayout background="porcelain">
      <LogoHeader
        leftElement={
          <IconButton onClick={nav.summary.on({})} icon={<CloseIcon />} />
        }
        sticky
      />
      <div className={styles.ReportHistory}>
        <Typography variant="display-s">
          <Trans.Title />
        </Typography>
        <ul className={styles.list}>
          {summaries
            .flatMap((s) => s.messages)
            .map((message) => (
              <li key={message.id} className={styles.item}>
                <PathLink to={nav.item.to({ messageId: message.id })}>
                  <div className={styles.itemText}>
                    <Typography variant="label-m" color="default">
                      {message.title}
                    </Typography>
                    {message.date && (
                      <Typography variant="body-xs" color="subtle">
                        <DateReadable date={message.date} />
                      </Typography>
                    )}
                  </div>
                  <ChevronRightIcon />
                </PathLink>
              </li>
            ))}
        </ul>
      </div>
    </FullScreenPageLayout>
  );
}
