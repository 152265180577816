import { Metric } from "lib/api/metrics";
import { withDecimals } from "lib/math";
import { QueryLink } from "render/routes/QueryLink";
import { queries } from "render/routes/paths";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useRiskMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric as MetricLayout, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

export function MetricABI() {
  const { data: abiLeft, isLoading: isLoadingLeft } =
    useRiskMetric("cardio.abi.left");
  const { data: abiRight, isLoading: isLoadingRight } =
    useRiskMetric("cardio.abi.right");

  if (isLoadingRight || isLoadingLeft) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (!abiLeft || !abiRight) {
    return null;
  }

  const { cur: latestLeftAbi, prev: prevLeftAbi } = getMetricHistory(abiLeft);
  const { cur: latestRightAbi, prev: prevRightAbi } =
    getMetricHistory(abiRight);

  const latestHighestAbi = highestAbi(latestLeftAbi, latestRightAbi);
  const score = latestHighestAbi?.unit["ratio"];
  const prevScore = highestAbi(prevLeftAbi, prevRightAbi)?.unit["ratio"];
  const { riskLevel } = latestHighestAbi?.riskRanges ?? {};

  return (
    <QueryLink query={queries.metric} params={{ metric: ["abi"] }}>
      <MetricLayout
        label={<Trans.MetricName />}
        value={fixed(score)}
        metricRating={riskLevel}
        description=""
        previousValue={fixed(prevScore)}
      />
    </QueryLink>
  );
}

function fixed(value: number | undefined) {
  if (value == null) {
    return;
  }
  return withDecimals(value, 2);
}

function highestAbi(
  leftAbi?: Metric<"cardio.abi.left">,
  rightAbi?: Metric<"cardio.abi.right">
) {
  return max([leftAbi, rightAbi], (p) => p?.unit["ratio"]);
}

function max<T>(items: T[], map: (value: T) => number | undefined) {
  return items
    .map((item) => ({ item, value: map(item) ?? 0 }))
    .reduce((current, previous) =>
      current.value > previous.value ? current : previous
    ).item;
}
