import { localize } from "render/context/InternationalizationContext";

const City = {
  London: localize({
    en_GB: "United Kingdom [London]",
    sv_SE: "Storbritannien [London]",
  }),
  Stockholm: localize({
    en_GB: "Sweden [Stockholm]",
    sv_SE: "Sverige [Stockholm]",
  }),
};

const CTA = {
  AddCode: localize({
    en_GB: "Add code",
    sv_SE: "Lägg till kod",
  }),
  ApplePay: localize({
    en_GB: "Apple Pay",
    sv_SE: "Apple Pay",
  }),
  CardPayment: localize({
    en_GB: "Card payment",
    sv_SE: "Kortbetalning",
  }),
  GooglePay: localize({
    en_GB: "Google Pay",
    sv_SE: "Google Pay",
  }),
};

const Header = localize({
  en_GB: "Checkout",
  sv_SE: "Betalning",
});

const Description = localize({
  en_GB:
    "You will be able to choose a time for your Neko Body Scan after the payment is completed.",
  sv_SE:
    "Du kommer att kunna välja en tid för din Neko Body Scan efter att betalningen har slutförts",
});

const Product = {
  Name: localize({
    en_GB: "Neko Body Scan",
    sv_SE: "Neko Body Scan",
  }),
  Total: localize({
    en_GB: "Total:",
    sv_SE: "Summa:",
  }),
};

const Code = {
  Label: localize({
    en_GB: "Have an invite code ?",
    sv_SE: "Har du en inbjudningskod?",
  }),
};

const CodeForm = {
  CTA: localize({
    en_GB: "Apply",
    sv_SE: "Använd",
  }),
  Label: localize({
    en_GB: "Invite code",
    sv_SE: "Inbjudningskod",
  }),
};

const Error = {
  InvalidInvitationCode: localize({
    en_GB: "Invalid invitation code",
    sv_SE: "Ogiltig inbjudningskod",
  }),
  Unexpected: localize({
    en_GB: "An unexpected error has occurred",
    sv_SE: "Ett oväntat fel uppstod",
  }),
};

export const Trans = {
  CTA,
  City,
  Code,
  CodeForm,
  Description,
  Error,
  Header,
  Product,
};
