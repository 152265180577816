import { APITypesV1, APITypesV2 } from "@cur8/api-client";
import { Patient, Slot, Visit } from "@cur8/rich-entity";
import { useNav, useQueryParams, useRouter } from "@pomle/react-router-paths";
import { ReactComponent as ChatIcon } from "assets/icons/24x24/24x24_chat.svg";
import { ReactComponent as MenuIcon } from "assets/icons/24x24/24x24_menu.svg";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useBookingTokens } from "render/hooks/api/queries/useBookingTokens";
import { useEarliestUpcomingVisitQuery } from "render/hooks/api/queries/useEarliestUpcomingVisitQuery";
import { useActiveInvitationCodesQuery } from "render/hooks/api/queries/useInvitationCodesQuery";
import { useLatestAttendedVisitQuery } from "render/hooks/api/queries/useLatestAttendedVisitQuery";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useSlotQuery } from "render/hooks/api/queries/useSlotQuery";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { paths, queries } from "render/routes/paths";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { IconButton } from "render/ui/trigger/IconButton";
import { FadeIn } from "render/views/HomeView/components/_shared/FadeIn";
import { LinkTo } from "render/views/HomeView/components/_shared/LinkTo/LinkTo";
import { homeViewTestIds } from "render/views/HomeView/testIds";
import { SharedTrans } from "render/views/trans";
import { BookStage } from "./components/BookStage";
import { BookingUpcomingStage } from "./components/BookingUpcomingStage";
import { GiveConsentStage } from "./components/GiveConsentStage";
import { VisitSummaryReadyStage } from "./components/VisitSummaryReadyStage";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface HomeViewContentRendererProps {
  patient: Patient;
  bookingTokens: APITypesV2.BookingTokenV2[];
  attendedVisit: Visit | undefined;
  upcomingVisitSlot: Slot | undefined;
}

function HomeViewContentRenderer({
  patient,
  attendedVisit,
  upcomingVisitSlot,
  bookingTokens,
}: HomeViewContentRendererProps) {
  const { consent } = patient;

  const showConsentScreen = useMemo(() => {
    const hasConsent = consent.createdAt;
    const consentHasExpired =
      consent.expiresAt && consent.expiresAt < DateTime.now();
    return !hasConsent || consentHasExpired;
  }, [consent]);

  if (
    showConsentScreen &&
    patient.primaryConsentRuleset === APITypesV1.ConsentRuleset.Gdpr
  ) {
    return <GiveConsentStage />;
  }

  if (attendedVisit) {
    return (
      <VisitSummaryReadyStage
        attendedVisit={attendedVisit}
        upcomingVisitSlot={upcomingVisitSlot}
        bookingTokens={bookingTokens}
        patient={patient}
      />
    );
  }
  if (showConsentScreen) {
    return <GiveConsentStage />;
  }

  if (upcomingVisitSlot) {
    return <BookingUpcomingStage patient={patient} slot={upcomingVisitSlot} />;
  } else {
    return <BookStage bookingTokens={bookingTokens} patient={patient} />;
  }
}

interface ViewData {
  patient: Patient;
  bookingTokens: APITypesV2.BookingTokenV2[];
  earliestUpcomingVisitSlot: Slot | undefined;
  latestAttendedVisit: Visit | undefined;
}

export function HomeView() {
  const [isReady, setIsReady] = useState(false);
  const [params] = useQueryParams(queries.payment);
  const { history } = useRouter();

  const contactUsPopup = useContactUsPopup();

  const nav = {
    checkout: useNav(paths.checkout, queries.payment),
    discountTokens: useNav(paths.profileTokens),
    profile: useNav(paths.profileCredentials),
  };
  const patientQuery = usePatientQuery();
  const bookingTokensQuery = useBookingTokens();
  const latestAttendedVisitQuery = useLatestAttendedVisitQuery();
  const earliestUpcomingVisitQuery = useEarliestUpcomingVisitQuery();
  const invitationCodeQuery = useActiveInvitationCodesQuery();

  const earliestUpcomingVisitSlotQuery = useSlotQuery({
    slotId: earliestUpcomingVisitQuery.data?.slotId ?? null,
  });

  const viewData = useMemo(() => {
    if (patientQuery.data == null) {
      return;
    }
    if (bookingTokensQuery.data == null) {
      return;
    }
    if (!latestAttendedVisitQuery.isFetched) {
      return;
    }
    if (!earliestUpcomingVisitQuery.isFetched) {
      return;
    }
    if (!latestAttendedVisitQuery.isFetched) {
      return;
    }
    if (
      earliestUpcomingVisitQuery.data != null &&
      earliestUpcomingVisitSlotQuery.data == null
    ) {
      return;
    }
    const result: ViewData = {
      bookingTokens: bookingTokensQuery.data,
      earliestUpcomingVisitSlot: earliestUpcomingVisitSlotQuery.data,
      latestAttendedVisit: latestAttendedVisitQuery.data ?? undefined,
      patient: patientQuery.data,
    };
    return result;
  }, [
    patientQuery.data,
    bookingTokensQuery.data,
    latestAttendedVisitQuery.isFetched,
    latestAttendedVisitQuery.data,
    earliestUpcomingVisitQuery.isFetched,
    earliestUpcomingVisitQuery.data,
    earliestUpcomingVisitSlotQuery.data,
  ]);

  const bookingTokensCount = bookingTokensQuery.data
    ? bookingTokensQuery.data?.length
    : undefined;
  const hasCode = params.code.length !== 0;

  useEffect(() => {
    if (isReady) {
      return;
    }
    if (!viewData) {
      return;
    }

    if (hasCode && bookingTokensCount === 0) {
      history.replace(nav.checkout.to({}, { code: params.code }));
      return;
    }

    setIsReady(true);
  }, [
    history,
    isReady,
    bookingTokensCount,
    hasCode,
    nav.checkout,
    params.code,
    viewData,
  ]);

  const availableDiscountTokensCount = useMemo(() => {
    if (!invitationCodeQuery.data) {
      return 0;
    }
    let partialDiscounts = 0;
    let fullDiscounts = 0;

    invitationCodeQuery.data
      .filter((token) => token.isRedeemed === false && token.isValid === true)
      .forEach((token) => {
        switch (token.discountTokenType) {
          case APITypesV1.DiscountTokenType.SingleFullDiscount:
            fullDiscounts += 1;
            break;
          case APITypesV1.DiscountTokenType.SinglePartialDiscount:
            partialDiscounts += 1;
            break;
        }
      });

    return partialDiscounts || fullDiscounts;
  }, [invitationCodeQuery.data]);

  return (
    <FullScreenPageLayout
      background="transparent"
      data-testid={homeViewTestIds.container}
    >
      <BurgerLayout>
        <LogoHeader
          hideLogo
          leftElement={
            <IconButton onClick={nav.profile.on({})} icon={<MenuIcon />} />
          }
          rightElement={
            <IconButton
              ariaLabel={SharedTrans.ContactUs()}
              onClick={contactUsPopup.emit}
              icon={<ChatIcon />}
            />
          }
        />
        <div className={styles.body}>
          <div className={styles.content}>
            {viewData && (
              <>
                <HomeViewContentRenderer
                  patient={viewData.patient}
                  bookingTokens={viewData.bookingTokens}
                  attendedVisit={viewData.latestAttendedVisit}
                  upcomingVisitSlot={viewData.earliestUpcomingVisitSlot}
                />
                {availableDiscountTokensCount > 0 ? (
                  <FadeIn offset={176}>
                    <LinkTo
                      href={nav.discountTokens.to({})}
                      head={<Trans.Invite.Title />}
                      variant="wide"
                      description={
                        <Trans.Invite.Description
                          tokenCount={availableDiscountTokensCount}
                        />
                      }
                      data-testid={homeViewTestIds.inviteButton}
                    />
                  </FadeIn>
                ) : null}
              </>
            )}
          </div>
        </div>
        <></>
      </BurgerLayout>
    </FullScreenPageLayout>
  );
}
