import { fromAPI } from "@cur8/rich-entity";
import { skipToken, useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { QueryOptions } from "typings/query";

function queryFn(api: APIClient, slotId: string) {
  return api.bookingV2.getSlot({ slotId }).result.then(fromAPI.toSlot);
}
function queryKey(slotId: string | null) {
  return ["slot", slotId] as const;
}

type Body = Awaited<ReturnType<typeof queryFn>>;
type Key = ReturnType<typeof queryKey>;

export function useSlotQuery<T = Body>(
  { slotId }: { slotId: string | null },
  options: QueryOptions<Body, Key, T> = {}
) {
  const api = useAPIClient();

  return useQuery({
    ...options,
    queryFn: slotId ? () => queryFn(api, slotId) : skipToken,
    queryKey: queryKey(slotId),
  });
}
