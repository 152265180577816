import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

let counter = 0;

type Popup = {
  id: string;
  content: ReactNode;
};

type PopupContextValue = {
  clear(): void;
  create(): {
    emit(content: ReactNode): void;
    close(): void;
  };
  isActive: boolean;
  popups: Popup[];
};

const Context = createContext<PopupContextValue | null>(null);

type PopupContextProps = {
  children: ReactNode;
};

export function PopupContext({ children }: PopupContextProps) {
  const [popups, setPopups] = useState<Popup[]>([]);

  const clear = useCallback(() => setPopups([]), []);

  const create = useCallback(() => {
    const id = `${++counter}`;
    return {
      close() {
        setPopups((p) => p.filter((popup) => popup.id !== id));
      },
      emit(content: React.ReactNode) {
        setPopups((p) => [...p, { content, id }]);
      },
    };
  }, []);

  return (
    <Context.Provider
      value={{
        clear,
        create,
        isActive: popups.length > 0,
        popups,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function usePopup() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("usePopup without PopupContext");
  }
  return context;
}
