export function defaultSide<T extends string>(
  data: Measures<T>,
  getValue: (unit?: Record<T, number>) => number | undefined
) {
  const getLatestValue = (m?: Measure<T>[]) => getValue(m?.at(-1)?.unit) ?? 0;
  const left = getLatestValue(data.left);
  const right = getLatestValue(data.right);
  return left >= right ? "left" : "right";
}

type Measures<T extends string> = {
  left: Measure<T>[] | undefined;
  right: Measure<T>[] | undefined;
};

type Measure<T extends string> = {
  unit: Record<T, number>;
};
