import { APITypesV1 } from "@cur8/api-client";
import { Typography } from "@cur8/maneki";
import { useNav, useQueryParams } from "@pomle/react-router-paths";
import { ReactComponent as ClockSmallIcon } from "assets/icons/16x16/16x16_clock.svg";
import { ReactComponent as QuestionIcon } from "assets/icons/24x24/24x24_chat.svg";
import { ReactComponent as CrossIcon } from "assets/icons/24x24/24x24_close.svg";
import { ReactNode, useCallback } from "react";
import { useMSAL } from "render/context/MSALContext";
import { useIdentityVerificationStatus } from "render/hooks/api/queries/useIdentityVerificationStatus";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { paths, queries } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Skeleton } from "render/ui/presentation/Skeleton";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface AppointmentIdentityRequiredViewProps {
  slotId: string;
}

function Layout({ text, actions }: { text?: ReactNode; actions?: ReactNode }) {
  return (
    <>
      <div className={styles.text}>{text}</div>
      <div className={styles.actions}>{actions}</div>
    </>
  );
}

function PendingState({ onSubmit }: { onSubmit: () => void }) {
  return (
    <Layout
      text={
        <>
          <Typography variant="display-s" color="default">
            <Trans.Pending.Title />
          </Typography>
          <div className={styles.subText}>
            <Typography variant="body-m" color="subtle">
              <Trans.Pending.Paragraph1 />
            </Typography>
            <div className={styles.textWithIcon}>
              <ClockSmallIcon />
              <Typography variant="body-s" color="default">
                <Trans.Pending.SubParagraph1 />
              </Typography>
            </div>
          </div>
        </>
      }
      actions={
        <ActionButton onClick={onSubmit} variant="suggestion">
          <Trans.Pending.VerifyCta />
        </ActionButton>
      }
    />
  );
}

function SuccessState({ onDone }: { onDone: () => void }) {
  return (
    <Layout
      text={
        <>
          <Typography variant="display-s">
            <Trans.Success.Title />
          </Typography>
          <Typography style={{ marginTop: "12px" }} variant="body-m">
            <Trans.Success.Paragraph />
          </Typography>
        </>
      }
      actions={
        <ActionButton onClick={onDone} variant="suggestion">
          <Trans.Success.CTA />
        </ActionButton>
      }
    />
  );
}

function SubmittedState({ onDone }: { onDone: () => void }) {
  return (
    <Layout
      text={
        <>
          <Typography variant="display-s">
            <Trans.Submitted.Title />
          </Typography>
          <Typography variant="body-m" color="default">
            <Trans.Submitted.Text />
          </Typography>
        </>
      }
      actions={
        <ActionButton onClick={onDone} variant="suggestion">
          <Trans.Submitted.Done />
        </ActionButton>
      }
    />
  );
}

function DeclinedState({
  onClose,
  onTryAgain,
}: {
  onTryAgain: () => void;
  onClose: () => void;
}) {
  return (
    <Layout
      text={
        <>
          <Typography variant="display-s">
            <Trans.Fail.Title />
          </Typography>
          <Typography variant="body-m" color="default">
            <Trans.Fail.Paragraph1 />
          </Typography>
        </>
      }
      actions={
        <>
          <ActionButton onClick={onTryAgain} variant="suggestion">
            <Trans.Fail.TryAgain />
          </ActionButton>
          <ActionButton onClick={onClose} variant="secondary">
            <Trans.Fail.Skip />
          </ActionButton>
        </>
      }
    />
  );
}

export function AppointmentIdentityRequiredView({
  slotId,
}: AppointmentIdentityRequiredViewProps) {
  const contactUsPopup = useContactUsPopup();
  const nav = {
    appointment: useNav(paths.appointment),
    appointmentPrereq: useNav(
      paths.appointmentPrereq.root,
      queries.appointmentPrereq
    ),
    appointmentPrereqIDV: useNav(
      paths.appointmentPrereq.identityVerification,
      queries.appointmentPrereq
    ),
  };
  const [params] = useQueryParams(queries.appointmentPrereq);

  const { stepUpAccess } = useMSAL();

  const controls = {
    onClose: useCallback(() => {
      if (params.origin.at(0) === "prereq") {
        nav.appointmentPrereq.go({ slotId });
      } else {
        nav.appointment.go({ slotId });
      }
    }, [nav.appointment, nav.appointmentPrereq, params.origin, slotId]),
    openContactUs: contactUsPopup.emit,

    setupIdv: useCallback(() => {
      const redirectUri = nav.appointmentPrereqIDV.to(
        { slotId },
        { origin: params.origin }
      );

      stepUpAccess("idv", redirectUri);
    }, [params.origin, nav.appointmentPrereqIDV, slotId, stepUpAccess]),
  };

  const identityVerificationStatusQuery = useIdentityVerificationStatus();
  const identityVerificationStatus =
    identityVerificationStatusQuery.data?.identityVerificationStatus;

  return (
    <FullScreenPageLayout>
      <div className={styles.OnboardingIdvResultView}>
        <div className={styles.sticky}>
          <LogoHeader
            leftElement={
              <IconButton onClick={controls.onClose} icon={<CrossIcon />} />
            }
            rightElement={
              <IconButton
                icon={<QuestionIcon display="block" />}
                onClick={controls.openContactUs}
              />
            }
          />
        </div>
        <div className={styles.body}>
          <div className={styles.content}>
            {identityVerificationStatus ? (
              <>
                {identityVerificationStatus ===
                APITypesV1.IdentityVerificationStatus.Verified ? (
                  <SuccessState onDone={controls.onClose} />
                ) : null}
                {identityVerificationStatus ===
                APITypesV1.IdentityVerificationStatus.Pending ? (
                  <PendingState onSubmit={controls.setupIdv} />
                ) : null}
                {identityVerificationStatus ===
                APITypesV1.IdentityVerificationStatus.Declined ? (
                  <DeclinedState
                    onClose={controls.onClose}
                    onTryAgain={controls.setupIdv}
                  />
                ) : null}
                {identityVerificationStatus ===
                APITypesV1.IdentityVerificationStatus.Submitted ? (
                  <SubmittedState onDone={controls.onClose} />
                ) : null}
              </>
            ) : (
              <Layout
                text={
                  <>
                    <div className={styles.skeletonBig}>
                      <Skeleton />
                    </div>
                    <div className={styles.skeletonSmall}>
                      <Skeleton />
                    </div>
                  </>
                }
                actions={
                  <div className={styles.skeletonCTA}>
                    <Skeleton />
                  </div>
                }
              />
            )}
          </div>
        </div>
      </div>
    </FullScreenPageLayout>
  );
}
