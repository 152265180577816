import { fromAPI } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { DateTime } from "luxon";
import { useAPIClient } from "render/context/APIContext";
import { QueryOptions } from "typings/query";

// There is a bug in the backend that if we send them ISO format, with a time zone
// it returns slots that are in the past
const TIME_FORMAT_FOR_BACKEND = "yyyy-LL-dd'T'HH:mm:ss";

//These are hardcoded ids that come from the backed
//For the initial release of multi visit we want to query only for body scan slots
//Once the booking token returns additional meta data, these can be removed
export enum ScanVisitTypeId {
  UK = "4dd622c1-e65b-484e-84d5-87bbe78beffa",
  SE = "Scan",
}

export function queryFn(
  api: APIClient,
  siteIds: string[],
  {
    pageSize,
    start,
    end,
    visitTypeIds,
  }: {
    pageSize?: number;
    start?: DateTime;
    end?: DateTime;
    visitTypeIds?: string[];
  }
) {
  return api.bookingV2
    .querySlots({
      pageSize,
      siteIds,
      startTime: {
        end: end?.toFormat(TIME_FORMAT_FOR_BACKEND),
        start: start?.toFormat(TIME_FORMAT_FOR_BACKEND),
      },
      visitTypeIds,
    })
    .result.then((response) => {
      return response.items.map(fromAPI.toBookableSlot);
    });
}
function queryKey(
  slotIds: string[],
  pageSize: number,
  start: DateTime | undefined,
  end: DateTime | undefined,
  visitTypeIds: string[] | undefined
) {
  return [
    "slots",
    slotIds.join(","),
    pageSize,
    start?.toFormat(TIME_FORMAT_FOR_BACKEND) ?? "no-start-time",
    end?.toFormat(TIME_FORMAT_FOR_BACKEND) ?? "no-end-time",
    visitTypeIds?.join(",") ?? "no-visit-type-ids",
  ];
}

type Body = Awaited<ReturnType<typeof queryFn>>;
type Key = ReturnType<typeof queryKey>;

export function useSiteSlotsQuery<T = Body>(
  {
    siteIds,
    end,
    start,
    pageSize,
    visitTypeIds,
  }: {
    siteIds: string[];
    start?: DateTime;
    end?: DateTime;
    pageSize: number;
    visitTypeIds: ScanVisitTypeId[] | undefined;
  },
  options: QueryOptions<Body, Key, T> = {}
) {
  const api = useAPIClient();

  return useQuery({
    ...options,
    queryFn: () =>
      queryFn(api, siteIds, { end, pageSize, start, visitTypeIds }),
    queryKey: queryKey(siteIds, pageSize, start, end, visitTypeIds),
  });
}
