import { CountryCode } from "lib/country";
import { useLatestAttendedVisitQuery } from "render/hooks/api/queries/useLatestAttendedVisitQuery";

export function useLatestVisitCountry() {
  return useLatestAttendedVisitQuery({
    select(visit) {
      return visit?.siteId.split("-").at(0) as CountryCode;
    },
  });
}
