import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useRiskMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

type useAnklePressureValuesType = {
  scanNum?: number;
  side: "left" | "right";
};

export function useAnklePressureValues({
  scanNum,
  side,
}: useAnklePressureValuesType) {
  const { data: left } = useRiskMetric("cardio.ankle_pressure.left");
  const { data: right } = useRiskMetric("cardio.ankle_pressure.right");

  if (!left || !right) {
    return;
  }

  const { cur: curLeft, prev: prevLeft } = getMetricHistory(left, scanNum);
  const { cur: curRight, prev: prevRight } = getMetricHistory(right, scanNum);

  const [cur, prev] =
    side === "left" ? [curLeft, prevLeft] : [curRight, prevRight];

  const { riskIntervals, riskLevel } = cur?.riskRanges ?? {};

  return {
    metricRating: riskLevel,
    previousValue: prev?.unit.mmHg,
    ranges: toChartRanges(riskIntervals),
    // https://dev.azure.com/cur8/Engineering/_workitems/edit/26138
    rangesCapHigh: 180,
    rangesCapLow: 50,
    scanDate: cur?.measurement.timestampStart,
    subLabel: <Trans.MetricUnit />,
    value: cur?.unit.mmHg,
  };
}
