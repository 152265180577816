import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { VideoBackground } from "render/views/_shared/VideoBackground";
import { AtriumOnboardingCompleted } from "./components/AtriumOnboardingCompleted";

export function AtriumSignIn() {
  return (
    <FullScreenPageLayout background="transparent">
      <AtriumOnboardingCompleted />
      <VideoBackground />
    </FullScreenPageLayout>
  );
}
