import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Glucose</>,
  sv_SE: <>Glukos</>,
});

const MetricLabel = localize({
  en_GB: <>mmol/L</>,
  sv_SE: <>mmol/L</>,
});

const FAQ = {
  Aim: localize({
    en_GB: <>You should aim for a glucose result between 4 and 7 mmol/L. </>,
    sv_SE: <>Du bör sikta på ett glukos-värde mellan 4 och 7 mmol/L.</>,
  }),
  High: localize({
    en_GB: (
      <>
        A high result can be a sign that your body is not handling glucose
        efficiently. Our bodies handle glucose less efficiently as we age, and
        putting on weight, particularly around the middle can make this happen
        faster. There are other types of diabetes that can develop very quickly
        such as gestational diabetes (which develops in pregnancy) and type 1
        diabetes which develops in childhood and young adulthood.
      </>
    ),
    sv_SE: (
      <>
        Ett högt resultat kan vara ett tecken på att din kropp inte omsätter
        blodsocker effektivt. Våra kroppar omsätter blodsockret mindre effektivt
        när vi åldras. Viktuppgång och förhöjt bukomfång kan sänka förbränningen
        och omsättningen i kroppen, vilket leder till förhöjda sockernivåer i
        blodet. Andra vanliga orsaker är graviditetsdiabetes och typ 1 diabetes.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        A low glucose result below 3.9 mmol/L can be a sign that you haven’t
        eaten before your test. Some people get a low blood glucose shortly
        after eating, this can happen if your pancreas (the organ which makes
        insulin) is too efficient at telling your body tissues to absorb
        glucose. If you take medicines for diabetes then it could be a sign that
        your medication needs adjusting, you should discuss your medicine with
        your doctor if this applies to you.
      </>
    ),
    sv_SE: (
      <>
        Ett lågt glukosresultat kan vara ett tecken på att du inte har ätit
        innan ditt test. Vissa människor får ett lågt blodsocker kort efter att
        ha ätit, detta kan hända om din bukspottkörtel (organet som producerar
        insulin) är för effektiv på att producera insulin. Om du tar mediciner
        mot diabetes kan det vara ett tecken på att din medicin behöver justeras
        och du bör diskutera din medicin med din läkare om detta gäller dig.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        Because glucose changes rapidly from minute to minute, the trend between
        tests doesn’t tell you very much. If you want to see the trend for how
        well your body is handling glucose you should look at your HbA1c result.
      </>
    ),
    sv_SE: (
      <>
        Eftersom blodsocker ändras snabbt från minut till minut, säger trenden
        mellan testerna inte så mycket. Om du vill se trenden för hur väl din
        kropp hanterar blodsocker bör du titta på ditt HbA1c-resultat.
      </>
    ),
  }),
  Why: localize({
    en_GB: (
      <>
        Your blood glucose changes from one minute to the next, in response to
        what you have eaten, when you ate it, and your levels of activity. It
        gives us a snapshot view of how your body is handling glucose at the
        time of your scan. This can help us with spotting the early signs of
        diabetes, and it is particularly helpful at detecting the types of
        diabetes that develop quickly.
      </>
    ),
    sv_SE: (
      <>
        Ditt glukosvärde (blodsocker) förändras från en minut till en annan, som
        svar på vad du har ätit, när du ätit det och dina aktivitetsnivåer. Det
        ger oss en ögonblicksbild av hur din kropp omsätter blodsockret vid
        tidpunkten för din skanning. Detta kan hjälpa oss att upptäcka de tidiga
        tecknen på diabetes, och det är särskilt användbart för att upptäcka de
        typer av diabetes som utvecklas snabbt.
      </>
    ),
  }),
};

export const Trans = { FAQ, MetricLabel, MetricName };
