import { createInternationalizationContext } from "@pomle/react-node-i18n";
import Cookies from "js-cookie";
import { DateTime, Settings } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { getInitialLanguage } from "render/context/InternationalizationContext/lib/getInitialLanguage";
import { SeverityLevel, useAppInsights } from "../AppInsightsContext";
import { COOKIE_NAME, getCookieDomain } from "./utils";

export const Locales = ["en_GB", "sv_SE"] as const;
export type Locale = (typeof Locales)[number];

const {
  localize,
  useLocale,
  InternationalizationProvider,
  useInternationalization,
} = createInternationalizationContext(Locales);

interface InternationalizationContextProps {
  children: React.ReactNode;
}

export function InternationalizationContext({
  children,
}: InternationalizationContextProps) {
  const { appInsights } = useAppInsights();
  const [locale, setLocale] = useState(getInitialLanguage);
  const cacheLocale = useCallback(
    (lang: string) => {
      try {
        Cookies.set(COOKIE_NAME, lang, {
          domain: getCookieDomain(),
          expires: DateTime.now().plus({ days: 90 }).toJSDate(),
        });
      } catch (exception) {
        if (exception instanceof Error) {
          appInsights.trackException({
            error: exception,
            exception: exception,
            severityLevel: SeverityLevel.Error,
          });
        }
      }
    },
    [appInsights]
  );

  useEffect(() => {
    cacheLocale(locale2lang(locale));
  }, [locale, cacheLocale]);

  const setLocaleHandler = useCallback((loc: Locale) => {
    setLocale(loc);
    Settings.defaultLocale = locale2lang(loc);
  }, []);

  useEffect(() => {
    document.documentElement.lang = locale2lang(locale);
  }, [locale]);

  return (
    <InternationalizationProvider locale={locale} onChange={setLocaleHandler}>
      {children}
    </InternationalizationProvider>
  );
}

export const FALLBACK_LOCALE: Locale = "en_GB";

export { localize, useInternationalization, useLocale };

declare global {
  interface NavigatorLanguage {
    // Non standard language settings (IE)
    browserLanguage?: string;
    userLanguage?: string;
  }
}

export function locale2lang(locale: Locale): string {
  return locale.toString().substring(0, 2);
}
