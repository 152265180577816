import { localize } from "render/context/InternationalizationContext";

const IntroText = localize({
  en_GB:
    "In less than an hour, you collected millions of data points to better understand your health. Your future self thanks you.",
  sv_SE:
    "På mindre än en timme har du samlat miljontals datapunkter för att förstå din hälsa bättre. Ditt framtida jag tackar dig.",
});

const Share = localize({
  en_GB: <>Share</>,
  sv_SE: <>Dela</>,
});

const ViewReport = localize({
  en_GB: <>View Report</>,
  sv_SE: <>Se rapport</>,
});

const ScanCard = localize({
  en_GB: <>Scan card</>,
  sv_SE: <>Scankort</>,
});

export const Trans = {
  IntroText,
  ScanCard,
  Share,
  ViewReport,
};
