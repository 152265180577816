import { PropsWithChildren, forwardRef } from "react";
import styles from "./styles.module.sass";

type FullScreenPageLayoutProps = {
  /** @default 'light-aluminium' */
  background?: "light-aluminium" | "porcelain" | "transparent";
  className?: string;
};

export const FullScreenPageLayout = forwardRef<
  HTMLDivElement,
  PropsWithChildren<FullScreenPageLayoutProps>
>(({ background = "light-aluminium", children, className, ...props }, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      className={[className, styles.FullScreenPageLayout]
        .filter(Boolean)
        .join(" ")}
      data-background={background}
    >
      {children}
    </div>
  );
});
