import { ReactComponent as LogoIcon } from "assets/logo.svg";
import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface LogoHeaderProps {
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  hideLogo?: boolean;
  sticky?: boolean;
}

export function LogoHeader({
  leftElement,
  rightElement,
  hideLogo = false,
  sticky,
}: LogoHeaderProps) {
  return (
    <div
      className={styles.header}
      data-centered={!hideLogo}
      data-sticky={sticky}
    >
      <div className={styles.left}>{leftElement}</div>
      {!hideLogo && <LogoIcon />}
      <div className={styles.right}>{rightElement}</div>
    </div>
  );
}
