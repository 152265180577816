import { fromAPI } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

export const queryKey = (patientId: string) => ["patient", patientId];
const queryFn = (apiClient: APIClient, patientId: string) => async () => {
  const patientDTO = await apiClient.patient.fetchPatient({ patientId }).result;
  const result = fromAPI.toPatientDetails(patientDTO);

  return result;
};

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export function usePatientQuery<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    ...options,
    queryFn: queryFn(apiClient, patientId),
    queryKey: queryKey(patientId),
    staleTime: Infinity,
  });
}
