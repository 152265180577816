import { Typography } from "@cur8/maneki";
import { ReactComponent as QuestionIcon } from "assets/icons/24x24/24x24_chat.svg";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { IconButton } from "render/ui/trigger/IconButton";
import { SharedTrans } from "render/views/trans";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function AtriumOnboardingCompleted() {
  const contactUsPopup = useContactUsPopup();
  return (
    <FullScreenPageLayout background="transparent">
      <BurgerLayout>
        <LogoHeader
          rightElement={
            <IconButton
              ariaLabel={SharedTrans.ContactUs()}
              icon={<QuestionIcon display="block" />}
              onClick={() => {
                contactUsPopup.emit();
              }}
            />
          }
        />
        <div className={styles.body}>
          <div className={styles.content}>
            <Typography variant="display-s">
              <Trans.Title />
            </Typography>
          </div>
        </div>
        <></>
      </BurgerLayout>
    </FullScreenPageLayout>
  );
}
