import { Typography } from "@cur8/maneki";
import { ComponentProps, ReactNode } from "react";
import styles from "./styles.module.sass";

type MetricGroupProps = {
  children: ReactNode;
  /** @default 'group' */
  kind?: "group" | "subgroup";
  title: ReactNode;
};

export function MetricGroup({
  children,
  kind = "group",
  title,
}: MetricGroupProps) {
  const [as, variant] = kindToGroup[kind];
  return (
    <section className={styles.MetricGroup} data-kind={kind}>
      <Typography as={as} variant={variant}>
        {title}
      </Typography>
      <div className={styles.cards}>{children}</div>
    </section>
  );
}

const kindToGroup: Record<Kind, [as: "h2" | "h3", variant: TypographyVariant]> =
  {
    group: ["h2", "title-l"],
    subgroup: ["h3", "title-s"],
  };

type Kind = Exclude<MetricGroupProps["kind"], undefined>;

// TODO: remove and import from @cur8/maneki when available
type TypographyVariant = ComponentProps<typeof Typography>["variant"];
