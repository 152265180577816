import { Typography } from "@cur8/maneki";
import { useGiveConsentMutation } from "render/hooks/mutations/consent/useGiveConsentMutation";
import { useRevokeConsentMutation } from "render/hooks/mutations/consent/useRevokeConsentMutation";
import { Button } from "render/ui/trigger/Button";
import styles from "./styles.module.sass";
import * as Trans from "./trans";

type ConsentButtonProps = {
  consentId: string;
  documentUri: string;
  signed: boolean;
  signatureId: string | undefined;
};

function RevokeConsentButton({
  signatureId,
}: {
  signatureId: string | undefined;
}) {
  const revokeConsentMutation = useRevokeConsentMutation();

  return (
    <Button
      variant="outlined"
      onClick={({ currentTarget }) => {
        if (revokeConsentMutation.isPending) {
          return;
        }
        revokeConsentMutation.mutate({ consentId: signatureId });
        scrollToTop(currentTarget);
      }}
    >
      <Typography variant="label-m" color="warning">
        <Trans.RevokeConsent />
      </Typography>
    </Button>
  );
}

function SignConsentButton({
  signatureId,
  documentUri,
  consentId,
}: {
  consentId: string;
  documentUri: string;
  signatureId: string | undefined;
}) {
  const giveConsentMutation = useGiveConsentMutation();

  return (
    <div className={styles.renewConsentButton}>
      <Button
        variant="active"
        onClick={({ currentTarget }) => {
          if (giveConsentMutation.isPending) {
            return;
          }
          giveConsentMutation.mutate({
            consentMetadataId: consentId,
            documentUri,
          });
          scrollToTop(currentTarget);
        }}
      >
        <Typography variant="label-m">
          {signatureId ? <Trans.RenewConsent /> : <Trans.Consent />}
        </Typography>
      </Button>
    </div>
  );
}

export function ConsentButton({
  consentId,
  documentUri,
  signed,
  signatureId,
}: ConsentButtonProps) {
  if (signed) {
    return <RevokeConsentButton signatureId={signatureId} />;
  }
  return (
    <SignConsentButton
      signatureId={signatureId}
      consentId={consentId}
      documentUri={documentUri}
    />
  );
}

function scrollToTop(element: Element) {
  getScrollParent(element)?.scrollTo({ behavior: "smooth", top: 0 });
}

function getScrollParent(element: Element | null) {
  if (element == null) {
    return element;
  }
  if (element.scrollHeight > element.clientHeight) {
    return element;
  }
  return getScrollParent(element.parentElement);
}
