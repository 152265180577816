import { useEffect, useRef } from "react";

type ObserveIntersectionProps = {
  onIntersection(intersecting: boolean): void;
};

export function ObserveIntersection({
  onIntersection,
}: ObserveIntersectionProps) {
  const ref = useRef<HTMLSpanElement>(null);
  const callbackRef = useRef(onIntersection);

  useEffect(() => {
    callbackRef.current = onIntersection;
  }, [onIntersection]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const observer = new IntersectionObserver(([entry]) => {
      callbackRef.current(entry.isIntersecting);
    });
    observer.observe(ref.current);

    return () => observer.disconnect();

    // NOTE: The empty effect dependency array is an optimisation, we only
    //       create the observer once, keep the callback as a ref, and
    //       always call the current function.
    //       Otherwise we'd have to keep destroying and recreating Observers
    //       every time the function pointer changes, potentially missing
    //       intersection events in the mean time.
  }, []);

  return <span ref={ref} style={{ display: "contents" }} />;
}
