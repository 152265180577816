/* eslint-disable sort-keys */
import { default as vars } from "../../styles/export-vars.module.sass";

export const breakpoints = {
  sm: vars["breakpoint-sm"],
  md: vars["breakpoint-md"],
  lg: vars["breakpoint-lg"],
  xl: vars["breakpoint-xl"],
};

export type Breakpoint = keyof typeof breakpoints;
