import { Typography } from "@cur8/maneki";
import { ReactComponent as Squircle } from "assets/squircle.svg";
import { Children, ReactNode } from "react";
import styles from "./styles.module.sass";

export type InfoProps = {
  title: ReactNode;
  children: JSX.Element[];
};

export function Info(props: InfoProps) {
  return (
    <div className={styles.info}>
      <Typography variant="title-m">{props.title}</Typography>
      <InfoList>{props.children}</InfoList>
    </div>
  );
}

export function InfoList(props: Pick<InfoProps, "children">) {
  return (
    <ul className={styles.list}>
      {Children.map(props.children, (item) => (
        <li>
          <Squircle className={styles.bullet} />
          <Typography variant="body-m" color="subtle">
            {item}
          </Typography>
        </li>
      ))}
    </ul>
  );
}
