import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Eosinophils</>,
  sv_SE: <>Eosinofiler</>,
});

const MetricLabel = localize({
  en_GB: <>x10⁹/L</>,
  sv_SE: <>x10⁹/L</>,
});

const FAQ = {
  Aim: localize({
    en_GB: (
      <>
        A normal eosinophil count is generally considered to be between 0.0-0.5
        x 10^9/L, but this can vary slightly depending on the lab and testing
        method.
      </>
    ),
    sv_SE: (
      <>
        Ett normalt antal eosinofiler anses generellt vara mellan 0,0-0,5 x
        10^9/L, men detta kan variera något beroende på laboratorium och
        testmetod.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        An elevated eosinophil count, known as eosinophilia, often indicates an
        allergic reaction, asthma or eczema. Less commonly, it can signal
        parasitic infection, autoimmune diseases or other serious conditions. In
        allergic conditions, such as hay fever, eosinophils respond to allergens
        and may cause inflammation. For parasitic infections, they play a role
        in attacking the parasites.
      </>
    ),
    sv_SE: (
      <>
        Ett förhöjt antal eosinofiler, känt som eosinofili, indikerar ofta en
        allergisk reaktion, astma eller eksem. Mindre vanligt kan det signalera
        parasitisk infektion, autoimmuna sjukdomar eller andra allvarliga
        tillstånd. Vid allergiska tillstånd, såsom hösnuva, svarar eosinofiler
        på allergener och kan orsaka inflammation.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        A low eosinophil count is less common and usually not a cause for
        concern. It can occur in response to steroid medication, or stress.
        Alcohol intoxication is another potential cause of low eosinophil
        levels. It’s rare for a low eosinophil count to signify a medical
        problem on its own, so it is usually used to understand the causes for
        other abnormal blood cells.
      </>
    ),
    sv_SE: (
      <>
        Ett lågt antal eosinofiler är ovanligt men i regel ingen anledning till
        oro. Det kan uppstå som svar på kortisonbehandling eller stress. Det är
        sällsynt att ett lågt antal eosinofiler innebär ett medicinskt problem.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        A persistent increase in eosinophil count over time may indicate a
        chronic allergic condition or, more rarely, a long-term parasitic
        infection. If your eosinophil levels are consistently high, it might be
        worth investigating for underlying allergies or hidden infections or
        problems with the production of blood cells. A consistent decrease is
        usually not significant but can occur in response to certain medications
        or chronic stress.
      </>
    ),
    sv_SE: (
      <>
        En ihållande ökning av antalet eosinofiler över tid kan indikera ett
        kroniskt allergiskt tillstånd eller, mer sällan, en långvarig
        parasitinfektion. Om dina eosinofilnivåer är konsekvent höga kan det
        vara värt att undersöka för underliggande allergier eller dolda
        infektioner eller problem med produktionen av blodkroppar.
      </>
    ),
  }),
  Why: localize({
    en_GB: (
      <>
        Eosinophils are a type of white blood cell part of your immune system.
        They play a key role in your body's response to allergens, asthma, and
        parasitic infections. They also participate in the control of other
        immune responses. Monitoring eosinophil levels can help in diagnosing
        various health conditions, particularly those related to allergic
        reactions and parasitic infections.
      </>
    ),
    sv_SE: (
      <>
        Eosinofiler är den typ av ditt immunsystem som spelar en nyckelroll i
        din kropps svar på allergener, astma och parasitinfektioner. Övervakning
        av eosinofilnivåer kan hjälpa till att diagnostisera olika
        hälsotillstånd, särskilt de som är relaterade till allergiska reaktioner
        och parasitinfektioner.
      </>
    ),
  }),
};

export const Trans = { FAQ, MetricLabel, MetricName };
