import { fromAPI } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";

export function useJoinConciergeSessionQuery({
  sessionId,
}: {
  sessionId: string;
}) {
  const apiClient = useAPIClient();

  return useQuery({
    queryFn: queryFn(apiClient, sessionId),
    queryKey: queryKey(sessionId),
  });
}

function queryFn(apiClient: APIClient, sessionId: string) {
  return async () => {
    const sessionDTO = await apiClient.checkinSession.joinSession({
      sessionId,
    }).result;

    return fromAPI.toCheckinSessionState(sessionDTO);
  };
}

function queryKey(sessionId: string) {
  return ["join-concierge-session", sessionId];
}
