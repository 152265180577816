import { Patient } from "@cur8/rich-entity";
import { Info } from "./Info";
import { Trans } from "./trans";

type Props = {
  patient: Patient;
};

export function HelpSection(props: Props) {
  const mailAddress =
    props.patient.preferredCountry === "GB"
      ? "support-uk@nekohealth.com"
      : "hello@nekohealth.com";

  return (
    <Info title={<Trans.NeedHelp />}>
      <Trans.HelpItems.FAQ />
      <Trans.HelpItems.EMail
        mailLink={<a href={`mailto:${mailAddress}`}>{mailAddress}</a>}
      />
    </Info>
  );
}
