import { RiskLevel } from "@cur8/measurements";
import { GripStrength as GripStrengthReference } from "@cur8/reference-data";
import { Sex } from "@cur8/rich-entity";
import { calcAge } from "lib/age";
import { DateTime } from "luxon";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { queries } from "render/routes/paths";
import { QueryLink } from "render/routes/QueryLink";
import { pickHighestSide } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricGripStrength/highestSide";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useRiskMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

function inRange(val: number, start: number, end: number) {
  return val >= start && val <= end;
}

export function MetricGripStrength() {
  const { data: patient, isLoading: isPatientLoading } = usePatientQuery();
  const { data: left, isLoading: isLoadingLeft } = useRiskMetric(
    "body.grip_strength.left"
  );
  const { data: right, isLoading: isLoadingRight } = useRiskMetric(
    "body.grip_strength.right"
  );

  if (isPatientLoading || isLoadingLeft || isLoadingRight) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (!left || !right || !patient) {
    return null;
  }

  const { cur: currentLeft, prev: prevLeft } = getMetricHistory(left);
  const { cur: currentRight, prev: prevRight } = getMetricHistory(right);

  const { gripStrength: currentHighestGripStrength, side: currentHighestSide } =
    pickHighestSide({
      left: currentLeft,
      right: currentRight,
    });
  const prevHighestGripStrength =
    currentHighestSide === "left" ? prevLeft : prevRight;

  const age = patient?.dateOfBirth
    ? calcAge(patient.dateOfBirth, DateTime.now())
    : NaN;

  const gripStrengthReference =
    patient?.sex === Sex.Female
      ? GripStrengthReference.Female
      : GripStrengthReference.Male;

  const reference = gripStrengthReference.find((chunk) =>
    inRange(age, chunk.age.from, chunk.age.to)
  );

  const offset =
    currentHighestGripStrength !== undefined &&
    gripStrengthReference &&
    reference
      ? currentHighestGripStrength.unit.kilograms - reference?.dominant.average
      : NaN;

  const metricRating = offset < 0 ? RiskLevel.Risk : RiskLevel.Normal;

  return (
    <QueryLink query={queries.metric} params={{ metric: ["grip-strength"] }}>
      <Metric
        label={<Trans.MetricName />}
        description={<Trans.MetricUnit />}
        metricRating={metricRating}
        value={currentHighestGripStrength?.unit.kilograms}
        previousValue={prevHighestGripStrength?.unit.kilograms}
      />
    </QueryLink>
  );
}
