import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

const queryKey = (patientId: string, path?: string) => [
  "blob",
  "patient",
  patientId,
  path,
];
const queryFn =
  (apiClient: APIClient, patientId: string, path: string) => async () => {
    const head = await apiClient.blob.headPatientBlob({ path, patientId })
      .result;

    return {
      async getFile() {
        const file = await apiClient.blob.fetchPatientBlob({ path, patientId })
          .result;
        return new File([await file.blob()], path);
      },
      size: head.headers.get("content-length"),
    };
  };

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export function useFileQuery<T = Body>(
  path: string,
  options?: QueryOptions<Body, Key, T>
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    ...options,
    queryFn: queryFn(apiClient, patientId, path),
    queryKey: queryKey(patientId, path),
  });
}
