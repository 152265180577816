import { QueryLink } from "render/routes/QueryLink";
import { queries } from "render/routes/paths";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useRiskMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

export function MetricBloodworkNonHdl() {
  const { data, isLoading } = useRiskMetric("bloodwork.non_hdl");

  if (isLoading) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (!data) {
    return null;
  }

  const { cur, prev } = getMetricHistory(data);

  const unit = "mmol/L";
  const { riskLevel } = cur?.riskRanges ?? {};

  return (
    <QueryLink query={queries.metric} params={{ metric: ["non-hdl"] }}>
      <Metric
        label={<Trans.MetricName />}
        metricRating={riskLevel}
        value={cur?.unit[unit]}
        description={<Trans.MetricUnit />}
        previousValue={prev?.unit[unit]}
      />
    </QueryLink>
  );
}
