import { withDecimals } from "lib/math";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useRiskMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";

type useABIValuesType = {
  scanNum?: number;
  side: "left" | "right";
};

export function useABIValues({ scanNum, side }: useABIValuesType) {
  const metricName = side === "right" ? "cardio.abi.right" : "cardio.abi.left";
  const { data: abi } = useRiskMetric(metricName);

  if (!abi) {
    return;
  }

  const { cur: curAbi, prev: prevAbi } = getMetricHistory(abi, scanNum);
  const score = curAbi?.unit["ratio"];
  const prevScore = prevAbi?.unit["ratio"];
  const { riskIntervals, riskLevel } = curAbi?.riskRanges ?? {};

  return {
    metricRating: riskLevel,
    previousValue: prevScore && withDecimals(prevScore, 2),
    ranges: toChartRanges(riskIntervals),
    // https://dev.azure.com/cur8/Engineering/_workitems/edit/26138
    rangesCapHigh: 1.6,
    rangesCapLow: 0.5,
    scanDate: curAbi?.measurement.timestampStart,
    subLabel: undefined,
    value: score && withDecimals(score, 2),
  };
}
