import { APITypesV1 } from "@cur8/api-client";
import {
  MetricName,
  RiskRange,
  Unit,
  toRiskRanges,
  toUnit,
} from "@cur8/measurements";
import { Measurement, fromAPI } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { CountryCode } from "lib/country";

export type MetricGroup = Partial<Record<string, Metric>>;
export type Metric<M extends MetricName = MetricName> = {
  name: M;
  measurement: Measurement;
  riskRanges: RiskRange<M>;
  unit: Unit<M>;
};

function toMetric<M extends MetricName>(
  metricName: M,
  measurement: Measurement,
  countryCode: CountryCode
): Metric<M> {
  return {
    measurement: measurement as Metric<M>["measurement"],
    name: metricName,
    riskRanges: toRiskRanges(metricName, measurement.riskRanges, countryCode),
    unit: toUnit<M>(metricName, measurement.dimensions),
  };
}

type Query<M extends MetricName> = APITypesV1.MeasurementQueryParams & {
  countryCode: CountryCode;
  metricName: M;
};

export function queryMetrics<M extends MetricName>(
  api: APIClient["measurement"],
  query: Query<M>,
  options: { signal?: AbortSignal }
) {
  const req = api.queryMeasurements(query);

  options.signal?.addEventListener("abort", () => req.abandon());

  return req.result.then((response) => {
    return response.items.map(fromAPI.toMeasurement).map((measurement) => {
      return toMetric<M>(query.metricName, measurement, query.countryCode);
    });
  });
}
