import { Answers } from "@cur8/questionnaire";
import { localeToISO639Code } from "lib/i18n/localeToISO639Code";
import {
  IsSnuser,
  IsSnusingEnum,
  QuitSnusingRecency,
  SnusHowOften,
} from "lib/questionnaire/question-flows/onboarding/questions/snus";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { useLocale } from "render/context/InternationalizationContext";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function IsSnuserStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(IsSnuser);
  const label = TransQuestions.Snus.Question();
  const { englishLabel } = TransQuestions.Snus.Question;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={IsSnuser}
      onNext={() => {
        onResetAnswer(IsSnuser);
        onNext();
      }}
      onPrev={() => {
        onPrev();
        onResetAnswer(IsSnuser);
      }}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.SnusExplanation />,
        openText: <Trans.General.WhyAreWeAsking />,
      }}
    >
      <ChoiceInput
        question={IsSnuser}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(IsSnuser, {
            answer,
            questionAnswer: {
              answer: {
                $type: "stringenum",
                value: IsSnuser.getValue(answer) || "",
              },
              englishLabel,
              label,
              languageCode,
              questionId: "snus.style",
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function WeeklySnusCanCountStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(SnusHowOften);
  const label = TransQuestions.Snus.HowOften();
  const { englishLabel } = TransQuestions.Snus.HowOften;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={SnusHowOften}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(SnusHowOften);
      }}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.SnusExplanation />,
        openText: <Trans.General.WhyAreWeAsking />,
      }}
    >
      <ChoiceInput
        question={SnusHowOften}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(SnusHowOften, {
            answer,
            questionAnswer: {
              answer: {
                $type: "stringenum",
                value: SnusHowOften.getValue(answer) || "",
              },
              englishLabel,
              label,
              languageCode,
              questionId: "snus.frequency",
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function QuitSnusingRecencyStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(QuitSnusingRecency);
  const label = TransQuestions.Snus.QuitSnusingRecency();
  const { englishLabel } = TransQuestions.Snus.QuitSnusingRecency;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={QuitSnusingRecency}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(QuitSnusingRecency);
      }}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.SnusExplanation />,
        openText: <Trans.General.WhyAreWeAsking />,
      }}
    >
      <ChoiceInput
        question={QuitSnusingRecency}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(QuitSnusingRecency, {
            answer,
            questionAnswer: {
              answer: {
                $type: "stringenum",
                value: QuitSnusingRecency.getValue(answer) || "",
              },
              englishLabel,
              label,
              languageCode,
              questionId: "snus.quitSnusingAt",
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(IsSnuserStep);

  if (answers.lookup(IsSnuser) === IsSnusingEnum.Yes) {
    steps.push(WeeklySnusCanCountStep);
  } else if (answers.lookup(IsSnuser) === IsSnusingEnum.Quit) {
    steps.push(QuitSnusingRecencyStep);
  }
}
