import { localize } from "render/context/InternationalizationContext";

export const Question = localize({
  en_GB: "Did you find this summary valuable?",
  sv_SE: "Tyckte du att sammanfattningen var värdefull?",
});

export const Yes = localize({
  en_GB: "Yes",
  sv_SE: "Ja",
});

export const No = localize({
  en_GB: "No",
  sv_SE: "Nej",
});

export const Why = localize({
  en_GB: "Why did you choose this rating?",
  sv_SE: "Varför valde du detta betyg?",
});

export const Knowledgeable = localize({
  en_GB: "Knowledgeable",
  sv_SE: "Kunskapsrik",
});

export const Personal = localize({
  en_GB: "Personal",
  sv_SE: "Personlig",
});

export const EasyToUnderstand = localize({
  en_GB: "Easy to understand",
  sv_SE: "Lätt att förstå",
});

export const TooLong = localize({
  en_GB: "Too long",
  sv_SE: "För lång",
});

export const HardToUnderstand = localize({
  en_GB: "Hard to understand",
  sv_SE: "Svår att förstå",
});

export const MissingInfo = localize({
  en_GB: "Missing info",
  sv_SE: "Saknar information",
});

export const Generic = localize({
  en_GB: "Generic",
  sv_SE: "Generisk",
});

export const Other = localize({
  en_GB: "Other",
  sv_SE: "Annat",
});

export const AdditionalFeedback = localize({
  en_GB: "Add additional feedback (optional)",
  sv_SE: "Lägg till ytterligare feedback (valfritt)",
});

export const Submit = localize({
  en_GB: "Submit",
  sv_SE: "Skicka in",
});

export const Skip = localize({
  en_GB: "Skip",
  sv_SE: "Hoppa över",
});

export const Thanks = localize({
  en_GB: "Thank you for your feedback",
  sv_SE: "Tack för din feedback",
});
