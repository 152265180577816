import { QueryLink } from "render/routes/QueryLink";
import { queries } from "render/routes/paths";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useRiskMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

export function MetricBloodOxygen() {
  const { data, isLoading } = useRiskMetric("cardio.oxygen_saturation");

  if (isLoading) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (!data) {
    return null;
  }

  const { cur, prev } = getMetricHistory(data);
  const { riskLevel } = cur?.riskRanges ?? {};

  return (
    <QueryLink query={queries.metric} params={{ metric: ["blood-oxygen"] }}>
      <Metric
        label={<Trans.MetricName />}
        value={cur?.unit.percent}
        metricRating={riskLevel}
        description={<Trans.MetricUnit />}
        previousValue={prev?.unit.percent}
        showPreviousInsteadOfChange
      />
    </QueryLink>
  );
}
