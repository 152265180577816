import { useMutation, useQueryClient } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useSnackbar } from "notistack";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { queryKey as patientConsentsQueryKey } from "render/hooks/api/queries/usePatientConsentsQuery";
import { queryKey as validPatientConsentsQueryKey } from "render/hooks/api/queries/useValidPatientConsentsQuery";
import * as Trans from "./trans";

type RevokeConsentPayload = {
  consentId: string | undefined;
};

function mutationFunction(
  apiClient: APIClient,
  patientId: string,
  payload: RevokeConsentPayload
) {
  const { consentId } = payload;
  if (consentId == null) {
    return Promise.reject(new Error("No id for the consent provided"));
  }
  return apiClient.consent.revokeConsent({ consentId, patientId }).result;
}

export function useRevokeConsentMutation() {
  const queryClient = useQueryClient();
  const { patientId } = useSession();
  const apiClient = useAPIClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (payload: RevokeConsentPayload) =>
      mutationFunction(apiClient, patientId, payload),
    onError() {
      enqueueSnackbar(<Trans.RevokeConsentError />, { variant: "error" });
    },
    async onSuccess() {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: patientConsentsQueryKey(patientId),
        }),
        queryClient.invalidateQueries({
          queryKey: validPatientConsentsQueryKey(patientId),
        }),
      ]);
    },
  });
}
