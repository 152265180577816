import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as ArrowLeftIcon } from "assets/icons/24x24/24x24_arrow_left.svg";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { IconButton } from "render/ui/trigger/IconButton";
import { useSummaries } from "render/views/Report/AppointmentDataView/hooks/useSummaries";
import { Summary } from "../Summary";
import styles from "./styles.module.sass";

type ReportHistoryItemProps = {
  messageId: string;
};

export function ReportHistoryItem({ messageId }: ReportHistoryItemProps) {
  const summaries = useSummaries();
  const nav = {
    history: useNav(paths.appointmentSummaryHistory),
  };

  const summaryMessage = summaries
    .flatMap((summary) =>
      summary.messages.map((message) => ({ message, summary }))
    )
    .find((m) => m.message.id === messageId);

  // wait for data to load
  if (!summaryMessage) {
    return;
  }

  const { message, summary } = summaryMessage;

  return (
    <FullScreenPageLayout background="porcelain">
      <LogoHeader
        leftElement={
          <IconButton onClick={nav.history.on({})} icon={<ArrowLeftIcon />} />
        }
        sticky
      />
      <div className={styles.ReportHistoryItem}>
        <Summary
          date={message.date}
          doctorUri={message.doctorUri}
          hasFeedback={summary.hasFeedback}
          notificationId={message.notificationId}
          seen={message.seen}
          text={message.text}
          title={message.title}
          visitId={summary.visitId}
        />
      </div>
    </FullScreenPageLayout>
  );
}
