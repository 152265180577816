import { MetricGroup } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/MetricGroup";
import { MetricABI } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricABI";
import { MetricAnklePressure } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricAnklePressure";
import { MetricBMI } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBMI";
import { MetricBasophils } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBasophils";
import { MetricBloodPressure } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodPressure/MetricBloodPressure";
import { MetricBloodworkHdl } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodworkHdl";
import { MetricBloodworkLdl } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodworkLdl";
import { MetricBloodworkNonHdl } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodworkNonHdl";
import { MetricCRP } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricCRP";
import { MetricCholesterol } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricCholesterol";
import { MetricEosinophils } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricEosinophils";
import { MetricEyePressure } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricEyePressure/MetricEyePressure";
import { MetricGlucose } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricGlucose";
import { MetricGripStrength } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricGripStrength";
import { MetricHBA1C } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricHBA1C";
import { MetricHaemoglobin } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricHaemoglobin";
import { MetricLymphocytes } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricLymphocytes";
import { MetricNeutrophils } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricNeutrophils";
import { MetricSkinLesions } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricSkinLesions";
import { MetricTriglycerides } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricTriglycerides";
import { MetricWeight } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricWeight";
import { MetricWhiteBloodCells } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricWhiteBloodCells";
import { MetricBloodOxygen } from "./components/metrics/MetricBloodOxygen";
import { MetricHeartRate } from "./components/metrics/MetricHeartRate";
import * as Trans from "./trans";

export function MetricsSection() {
  return (
    <>
      <MetricGroup title={<Trans.HeartAndCirculation />}>
        <MetricBloodPressure />
        <MetricAnklePressure />
        <MetricABI />
        <MetricHeartRate />
        <MetricBloodOxygen />
      </MetricGroup>

      <MetricGroup title={<Trans.BloodFats />} kind="subgroup">
        <MetricCholesterol />
        <MetricBloodworkHdl />
        <MetricBloodworkNonHdl />
        <MetricBloodworkLdl />
        <MetricTriglycerides />
        <MetricCRP />
      </MetricGroup>

      <MetricGroup title={<Trans.Body />}>
        <MetricEyePressure />
        <MetricGripStrength />
        <MetricWeight />
        <MetricBMI />
      </MetricGroup>

      <MetricGroup title={<Trans.Diabetes />} kind="subgroup">
        <MetricHBA1C />
        <MetricGlucose />
      </MetricGroup>

      <MetricGroup title={<Trans.OtherBloodValues />} kind="subgroup">
        <MetricHaemoglobin />
        <MetricWhiteBloodCells />
        <MetricNeutrophils />
        <MetricEosinophils />
        <MetricLymphocytes />
        <MetricBasophils />
      </MetricGroup>

      <MetricGroup title={<Trans.Skin />}>
        <MetricSkinLesions />
      </MetricGroup>
    </>
  );
}
