import { APITypesV1, APITypesV2 } from "@cur8/api-client";
import { Typography } from "@cur8/maneki";
import { Patient } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { useMemo } from "react";
import { paths } from "render/routes/paths";
import { FadeIn } from "render/views/HomeView/components/_shared/FadeIn";
import { LinkTo } from "render/views/HomeView/components/_shared/LinkTo/LinkTo";
import { homeViewTestIds } from "render/views/HomeView/testIds";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface BookStageProps {
  patient: Patient;
  bookingTokens: APITypesV2.BookingTokenV2[];
}

export function BookStage({ patient, bookingTokens }: BookStageProps) {
  const nav = {
    booking: useNav(paths.booking),
    payment: useNav(paths.checkout),
  };

  const canBook = useMemo(() => {
    return patient.flags.includes(APITypesV1.PatientFlag.CanBook);
  }, [patient.flags]);

  const hasBookingTokens = useMemo(() => {
    return (
      bookingTokens.filter((bookingToken) => bookingToken.availableForBooking)
        .length > 0
    );
  }, [bookingTokens]);

  return (
    <div className={styles.BookStage}>
      {!canBook && hasBookingTokens && (
        <FadeIn offset={112}>
          <Typography variant="display-s">
            <Trans.ScanPrebooked />
          </Typography>
          <Typography variant="title-m">
            <Trans.WeWillReachYou />
          </Typography>
        </FadeIn>
      )}

      {!canBook && !hasBookingTokens && (
        <>
          <FadeIn offset={112}>
            <div className={styles.waitlistStage}>
              <Typography variant="display-s">
                <Trans.NoToken.Welcome />
              </Typography>
              <Typography variant="body-m">
                <Trans.NoToken.WhenItsYourTurn data-testid="1avs" />
              </Typography>
            </div>
          </FadeIn>
        </>
      )}

      {canBook && (
        <>
          <FadeIn offset={112}>
            <Typography variant="display-s">
              <Trans.Greeting patient={patient} />
            </Typography>
          </FadeIn>
          <FadeIn offset={176}>
            <LinkTo
              variant="square"
              href={hasBookingTokens ? nav.booking.to({}) : nav.payment.to({})}
              head={<Trans.Book />}
              description={<Trans.SlotStatus.Available />}
              data-testid={homeViewTestIds.bookScanButton}
            />
          </FadeIn>
        </>
      )}
    </div>
  );
}
