import { APITypesV1 } from "@cur8/api-client";
import { Typography } from "@cur8/maneki";
import { checkIfSigned } from "lib/consents/checkIfSigned";
import { useLocalisedMetadataDocumentUri } from "lib/consents/documentUri";
import { formatToDesignSystem } from "lib/consents/formatToDesignSystem";
import { MappedConsent } from "lib/consents/mapConsents";
import { parseDocument } from "lib/legal/parseDocument";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { useLegalDocumentQuery } from "render/hooks/api/queries/useLegalDocumentQuery";
import { ListLink } from "render/ui/trigger/ListLink";
import { ConsentButton } from "render/views/ProfileView/components/ConsentDocument/components/ConsentButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

type ContentProps = {
  consent: MappedConsent;
};

export function ConsentDetails({ consent }: ContentProps) {
  const [ephemeralToken, setEphemeralToken] = useState<string>();
  const api = useAPIClient();
  const { patientId } = useSession();
  const isSigned = checkIfSigned(consent.relevantSignature);
  const documentUri = useLocalisedMetadataDocumentUri(consent);
  const hasSignedLatest = checkHasSignedLatest(
    documentUri,
    consent.relevantSignature?.documentUrl
  );

  const fetchEphemeralToken = useCallback(() => {
    api.patient
      .createEphermeralToken(
        { patientId },
        { requestMethod: APITypesV1.RequestMethod.RenderPatientConsent }
      )
      .result.then((response) => {
        setEphemeralToken(response.token);
      });
  }, [api.patient, patientId]);

  useEffect(() => {
    fetchEphemeralToken();
  }, [fetchEphemeralToken]);

  const consentPDFDownloadLink = useMemo(() => {
    if (ephemeralToken == null) return;

    const url = api.consentV2.getConsentHistory({
      consentId: consent.consentMetadata.consentId,
      patientId,
      token: ephemeralToken,
    });

    return url.toString();
  }, [
    api.consentV2,
    consent.consentMetadata.consentId,
    ephemeralToken,
    patientId,
  ]);

  const { data } = useLegalDocumentQuery(documentUri, {
    enabled: Boolean(documentUri),
    select: parseDocument,
  });

  const title = data?.title;
  const description = data?.description;
  const headElements = data?.summary?.getElementsByTagName("*");
  const parsedHead = headElements && formatToDesignSystem(headElements);
  const bodyElements = data?.scrollable?.getElementsByTagName("*");
  const parsedBody = bodyElements && formatToDesignSystem(bodyElements);

  if (documentUri == null || parsedHead == null || parsedBody == null) {
    return;
  }

  return (
    <div className={styles.consentContainer}>
      <section className={styles.section}>
        <Typography variant="title-l">{title}</Typography>
        <Typography variant="body-m">{description}</Typography>
        {consentPDFDownloadLink && hasSignedLatest === false ? (
          <Layout>
            <Typography variant="body-xs" color="subtle">
              <Trans.LatestVersion link={consentPDFDownloadLink} />
            </Typography>
          </Layout>
        ) : undefined}
      </section>
      <section className={styles.section}>
        {parsedHead}
        {parsedBody}
      </section>
      {consent.relevantSignature?.id && consentPDFDownloadLink && (
        <section className={styles.section}>
          <div className={styles.sectionTitle}>
            <Typography variant="title-m">
              <Trans.MoreInfo />
            </Typography>
          </div>
          <ul className={styles.list}>
            <li>
              <div className={styles.consentBorder} />
              <ListLink
                variant="download"
                href={consentPDFDownloadLink}
                target="_blank"
              >
                <Trans.ConsentHistory />
              </ListLink>
            </li>
          </ul>
        </section>
      )}
      <ConsentButton
        consentId={consent.consentMetadata.consentId}
        documentUri={documentUri}
        signed={isSigned}
        signatureId={consent.relevantSignature?.id}
      />
    </div>
  );
}

function Layout({ children }: { children: React.ReactNode }) {
  return <div className={styles.Layout}>{children}</div>;
}

function checkHasSignedLatest(
  metadataDocumentUri: string | undefined,
  relevantSignatureDocumentUri: string | undefined
) {
  if (metadataDocumentUri == null || relevantSignatureDocumentUri == null) {
    return undefined;
  }
  return (
    removeLanguageCodeAndDotHtml(metadataDocumentUri) ===
    removeLanguageCodeAndDotHtml(relevantSignatureDocumentUri)
  );
}

function removeLanguageCodeAndDotHtml(url: string) {
  return url.replace(/\.[a-zA-Z0-9-]+\.html$/, "");
}
