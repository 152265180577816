import { QueryLink } from "render/routes/QueryLink";
import { queries } from "render/routes/paths";
import { pickHighestSide } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricAnklePressure/highestSide";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useRiskMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric as MetricLayout, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

export function MetricAnklePressure() {
  const { data: left, isLoading: isLoadingLeft } = useRiskMetric(
    "cardio.ankle_pressure.left"
  );
  const { data: right, isLoading: isLoadingRight } = useRiskMetric(
    "cardio.ankle_pressure.right"
  );

  if (isLoadingLeft || isLoadingRight) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (!left || !right) {
    return null;
  }

  const { cur: currentLeftAnklePressure, prev: prevLeftAnklePressure } =
    getMetricHistory(left);
  const { cur: currentRightAnklePressure, prev: prevRightAnklePressure } =
    getMetricHistory(right);

  const { anklePressure: highestAnklePressure, side: highestSide } =
    pickHighestSide({
      left: currentLeftAnklePressure,
      right: currentRightAnklePressure,
    });
  const highestPrevAnklePressure =
    highestSide === "left" ? prevLeftAnklePressure : prevRightAnklePressure;

  const { riskLevel } = highestAnklePressure?.riskRanges ?? {};

  return (
    <QueryLink query={queries.metric} params={{ metric: ["ankle-pressure"] }}>
      <MetricLayout
        label={<Trans.MetricName />}
        metricRating={riskLevel}
        value={highestAnklePressure?.unit.mmHg}
        description={<Trans.MetricUnit />}
        previousValue={highestPrevAnklePressure?.unit.mmHg}
      />
    </QueryLink>
  );
}
