import styles from "./styles.module.sass";

interface SeparatorProps {
  variant?: "small" | "full";
  width?: string;
  margin?: string;
}

export function Separator({ width, margin, variant }: SeparatorProps) {
  return (
    <div
      className={styles.separator}
      style={{ margin, width }}
      data-variant={variant}
    />
  );
}
