import { RecordingURI } from "@cur8/uri";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLatestScanQuery } from "render/hooks/api/queries/useLatestScanQuery";
import * as THREE from "three";
import { PointCloudControl } from "./components/PointCloud";
import { Renderer } from "./components/Renderer";
import { usePointCloud } from "./hooks/usePointCloud";
import {
  BELLOW_HEAD,
  DEFAULT_CAMERA_VIEW,
  createScene,
  useCamera,
} from "./render";
import styles from "./styles.module.sass";

interface AvatarProps {
  height: number;
}

function isRecordingURI(source: unknown): source is RecordingURI {
  return source instanceof RecordingURI;
}

export function AvatarSection({ height }: AvatarProps) {
  const scale = height / 712;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canvas, setCanvas] = useState<HTMLCanvasElement>();
  const skinScan = useLatestScanQuery("skin/").data?.at(0);
  const uri = useMemo(() => {
    return skinScan?.sourceUris.filter(isRecordingURI)[0];
  }, [skinScan]);
  const downSampleRatio = useMemo(() => {
    if (scale < 0.5) {
      return "1:8";
    }
    return "1:6";
  }, [scale]);
  const worldPCDResult = usePointCloud("front", uri, downSampleRatio);
  const points = worldPCDResult?.data;

  const memberHeight = useMemo(() => {
    if (!points) {
      return;
    }
    points.geometry.computeBoundingBox();

    const box = points.geometry.boundingBox;

    if (box) {
      return box.max.z;
    }

    return undefined;
  }, [points]);

  const camera = useCamera();

  useEffect(() => {
    if (!memberHeight) {
      return;
    }
    const computedView = DEFAULT_CAMERA_VIEW.clone();
    computedView.pos.z = memberHeight - BELLOW_HEAD;
    computedView.focus.set(0, 0, memberHeight - BELLOW_HEAD);
    camera.moveTo(computedView);
  }, [memberHeight, camera]);

  const scene = useMemo(() => createScene(), []);

  const avatar = useMemo(() => {
    const result = new THREE.Group();

    return result;
  }, []);

  useEffect(() => {
    scene.add(avatar);

    return () => {
      scene.remove(avatar);
    };
  }, [avatar, scene]);

  useEffect(() => {
    setCanvas(canvasRef.current ?? undefined);
  }, []);

  return (
    <div className={styles.Avatar} style={{ height }}>
      {canvas && (
        <>
          <Renderer canvas={canvas} camera={camera} scene={scene} />
          <PointCloudControl canvas={canvas} podium={avatar} points={points} />
        </>
      )}

      <canvas height={1620} width={1080} ref={canvasRef} />
    </div>
  );
}
