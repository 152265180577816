import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: <>Something went wrong</>,
  sv_SE: <>Något gick fel</>,
});

const Paragraph1 = localize({
  en_GB: <>We were not able to set up your Passkey.</>,
  sv_SE: <>Vi kunde inte skapa din Passkey just nu.</>,
});

const TryAgain = localize({
  en_GB: <>Try again</>,
  sv_SE: <>Försök igen</>,
});

const Skip = localize({
  en_GB: <>Setup up later</>,
  sv_SE: <>Lägg till senare</>,
});

export const Trans = {
  Paragraph1,
  Skip,
  Title,
  TryAgain,
};
