import { localize } from "render/context/InternationalizationContext";

const Greeting = localize({
  en_GB: (
    <>
      Hi there,
      <br /> we need you consent to store and process your data
    </>
  ),
  sv_SE: (
    <>
      Hej,
      <br /> vi behöver ditt godkännande för att hantera din data
    </>
  ),
});

const GiveConsentAction = localize({
  en_GB: <>Give consent</>,
  sv_SE: <>Ge ditt samtycke</>,
});

export const Trans = { GiveConsentAction, Greeting };
