import { localize } from "render/context/InternationalizationContext";

const Title = {
  NotReady: localize({
    en_GB: (
      <>
        Complete <br /> your profile
      </>
    ),
    sv_SE: (
      <>
        Slutför <br /> din profil
      </>
    ),
  }),
  Ready: localize({
    en_GB: "You're all set",
    sv_SE: "Du är redo",
  }),
};

const Description = {
  NotReady: localize({
    en_GB:
      "Complete these steps before your scan to ensure a seamless check-in process at the clinic.",
    sv_SE:
      "Slutför dessa steg innan din scan för en smidig incheckning påkliniken.",
  }),
  Ready: localize({
    en_GB: "You're ready for your scan.",
    sv_SE: "Nu är du redo för din scan.",
  }),
};

const CTA = {
  Done: localize({
    en_GB: "Close",
    sv_SE: "Stäng",
  }),
  ToDo: localize({
    en_GB: "Next step",
    sv_SE: "Nästa steg",
  }),
};

export const Trans = {
  CTA,
  Description,
  Title,
};
