import type { DateTime } from "luxon";

export function asc<T>(mapFn?: (t: T) => number | DateTime | undefined) {
  return sortBy(mapFn, 1);
}

export function desc<T>(mapFn?: (t: T) => number | DateTime | undefined) {
  return sortBy(mapFn, -1);
}

function sortBy<T>(
  mapFn: (t: T) => number | DateTime | undefined = identity as typeof mapFn,
  reverse: 1 | -1
) {
  const map = toNumber(mapFn);
  return function compare(a: T, b: T) {
    return (map(a) - map(b)) * reverse;
  };
}

function toNumber<T, R extends number | DateTime | undefined>(
  mapFn: (value: T) => R
) {
  return (value: T) => +(mapFn(value) ?? 0);
}

function identity<T>(t: T) {
  return t;
}
