import { APITypesV1, APITypesV2 } from "@cur8/api-client";
import { ViewStack } from "@pomle/react-viewstack";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { useFetchConciergeSessionQuery } from "render/hooks/api/queries/concierge/useFetchConciergeSessionQuery";
import { useJoinConciergeSessionQuery } from "render/hooks/api/queries/concierge/useJoinConciergeSessionQuery";
import { useLatestConsentsQuery } from "render/hooks/api/queries/usePatientConsentsQuery";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useIsQuestionnaireCompleteForThisVisitQuery } from "render/hooks/api/queries/useQuestionnaireQuery";
import { useVisitQuery } from "render/hooks/api/queries/useVisitQuery";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { CheckInFlow } from "render/views/concierge/ConciergeCheckInView/components/ConciergeCheckInSessionView/components/CheckInFlowView";
import { CheckInFailView } from "./components/CheckInFailView";
import { Loader, LoaderStatus } from "./components/Loader";
import { VisitValidity, getVisitValidity } from "./lib/getVisitValidity";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface ConciergeCheckInSessionViewProps {
  sessionId: string;
}

enum ViewState {
  JoinSessionFail = "join-session-fail",
  QueryQuestionnaireFail = "query-questionnaire-fail",
  QueryConsentsFail = "query-consents-fail",
  QueryVisitFail = "query-visit-fail",
  NoVisitFound = "no-visit-found",
  Fetching = "fetching",
  CheckInEarly = "check-in-early",
  CheckInLate = "check-in-late",
  CheckInTimeOut = "check-in-timeout",
  Success = "Success",
}

const TIME_OUT_TIME_SECONDS = 30;

interface CheckInFlowContainerProps {
  onStateChange: (viewState: ViewState, status: LoaderStatus) => void;
  sessionId: string;
}

function isDuringVisitSelfSignConsent(consent: APITypesV2.ConsentMetadata) {
  return (
    consent.displayStages?.includes(
      APITypesV2.ConsentDisplayStage.DuringVisit
    ) && consent.signatureType === APITypesV2.ConsentSignatureType.Self
  );
}

function CheckInFlowContainer({
  onStateChange,
  sessionId,
}: CheckInFlowContainerProps) {
  const patient = usePatientQuery();
  const joinSession = useJoinConciergeSessionQuery({ sessionId });

  const fetchConciergeSessionQuery = useFetchConciergeSessionQuery(sessionId, {
    enabled: joinSession.data != null,
    placeholderData: (prev) => prev,
    refetchInterval: (query) => {
      return query.state.data?.conciergeState ? false : 1000;
    },
  });

  const visitId = fetchConciergeSessionQuery.data?.conciergeState;

  const visitQuery = useVisitQuery(visitId, {
    placeholderData: (prev) => prev,
    refetchInterval: (query) => {
      return query.state.data?.status !== APITypesV1.VisitStatus.Scheduled
        ? false
        : 1000;
    },
  });

  const duringVisitSelfSignConsents = useMemo(() => {
    return visitQuery.data?.consents?.filter(isDuringVisitSelfSignConsent);
  }, [visitQuery.data]);

  const patientConsents = useLatestConsentsQuery({
    enabled:
      duringVisitSelfSignConsents && duringVisitSelfSignConsents.length > 0,
  });

  const isQuestionnaireCompletedForThisVisit =
    useIsQuestionnaireCompleteForThisVisitQuery(visitQuery.data?.visitId);

  const sessionStart = useMemo(() => {
    return joinSession.data?.sessionId ? DateTime.now() : undefined;
  }, [joinSession.data]);

  const triggerChangeTo = useMemo(() => {
    const memo = new Map<string, () => void>();
    return (newState: ViewState, newLoader: LoaderStatus) => {
      const key = `${newState}_${newLoader}`;
      let memoCallback = memo.get(key);
      if (memoCallback) {
        return memoCallback;
      }
      memoCallback = () => {
        onStateChange(newState, newLoader);
      };
      memo.set(key, memoCallback);

      return memoCallback;
    };
  }, [onStateChange]);

  //------------------------FETCH_ERROR_HANDLING_BLOCK_START------------------------
  if (joinSession.isError) {
    return (
      <CheckInFailView
        onActive={triggerChangeTo(ViewState.JoinSessionFail, LoaderStatus.Fail)}
        Header={<Trans.FailToConnect.Header />}
        Text={<Trans.FailToConnect.Description />}
      />
    );
  }

  if (visitQuery.isError) {
    return (
      <CheckInFailView
        onActive={triggerChangeTo(ViewState.QueryVisitFail, LoaderStatus.Fail)}
        Header={<Trans.FailToConnect.Header />}
        Text={<Trans.FailToConnect.Description />}
      />
    );
  }

  if (patientConsents.isError) {
    return (
      <CheckInFailView
        onActive={triggerChangeTo(
          ViewState.QueryConsentsFail,
          LoaderStatus.Fail
        )}
        Header={<Trans.FailToConnect.Header />}
        Text={<Trans.FailToConnect.Description />}
      />
    );
  }

  if (isQuestionnaireCompletedForThisVisit.isError)
    return (
      <CheckInFailView
        onActive={triggerChangeTo(
          ViewState.QueryQuestionnaireFail,
          LoaderStatus.Fail
        )}
        Header={<Trans.FailToConnect.Header />}
        Text={<Trans.FailToConnect.Description />}
      />
    );

  //------------------------FETCH_ERROR_HANDLING_BLOCK_END------------------------

  if (
    patientConsents.data == null ||
    visitQuery.data == null ||
    patient.data == null ||
    isQuestionnaireCompletedForThisVisit.data == null
  ) {
    return null;
  }

  // check if there is a non checked in early or late arrival
  if (visitQuery.data.status === APITypesV1.VisitStatus.Scheduled) {
    const visitValidity = getVisitValidity(visitQuery.data);
    if (visitValidity === VisitValidity.EarlyArrival) {
      return (
        <CheckInFailView
          onActive={triggerChangeTo(ViewState.CheckInEarly, LoaderStatus.Fail)}
          Header={<Trans.EarlyArrival.Header />}
          Text={<Trans.EarlyArrival.Description />}
        />
      );
    }

    if (visitValidity === VisitValidity.LateArrival) {
      return (
        <CheckInFailView
          onActive={triggerChangeTo(ViewState.CheckInLate, LoaderStatus.Fail)}
          Header={<Trans.LateArrival.Header />}
          Text={<Trans.LateArrival.Description />}
        />
      );
    }

    if (
      sessionStart &&
      Math.abs(sessionStart.diffNow("second").seconds) > TIME_OUT_TIME_SECONDS
    ) {
      return (
        <CheckInFailView
          onActive={triggerChangeTo(
            ViewState.CheckInTimeOut,
            LoaderStatus.Fail
          )}
          Header={<Trans.UnableToCheckIn.Header />}
          Text={<Trans.UnableToCheckIn.Description />}
        />
      );
    }
    // has slot today but no slot checked in
    return null;
  }

  return (
    <CheckInFlow
      onActive={triggerChangeTo(ViewState.Success, LoaderStatus.Complete)}
      patient={patient.data}
      duringVisitSelfSignConsents={duringVisitSelfSignConsents}
      patientConsents={patientConsents.data}
      isQuestionnaireComplete={isQuestionnaireCompletedForThisVisit.data}
      visit={visitQuery.data}
    />
  );
}

export function ConciergeCheckInSessionView({
  sessionId,
}: ConciergeCheckInSessionViewProps) {
  const [loaderActive, setLoaderActive] = useState(true);
  const appInsights = useAppInsights();
  const [status, setStatus] = useState(LoaderStatus.Loading);
  const [viewState, setViewState] = useState<ViewState>(ViewState.Fetching);

  useEffect(() => {
    appInsights.trackEvent({
      name: "concierge-check-in",
      properties: { checkInState: viewState },
    });
  }, [viewState, appInsights]);

  const viewControls = {
    onStateChange: useCallback(
      (viewState: ViewState, loaderStatus: LoaderStatus) => {
        setViewState(viewState);
        setStatus(loaderStatus);
      },
      []
    ),
  };

  const loaderControls = {
    hide: useCallback(() => {
      setLoaderActive(false);
    }, []),
  };

  return (
    <FullScreenPageLayout>
      <div className={styles.ConciergeCheckInView} data-status={status}>
        <ViewStack>
          <section data-active={loaderActive}>
            <div className={styles.body}>
              <div className={styles.content}>
                <Loader status={status} onDone={loaderControls.hide} />
              </div>
            </div>
          </section>
          <section data-active={!loaderActive}>
            <CheckInFlowContainer
              onStateChange={viewControls.onStateChange}
              sessionId={sessionId}
            />
          </section>
        </ViewStack>
      </div>
    </FullScreenPageLayout>
  );
}
