import { useQueryParams } from "@pomle/react-router-paths";
import { queries } from "render/routes/paths";
import { Benchmark } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Benchmark/Benchmark";
import { MetricDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Details";
import { useGripStrengthValues } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricGripStrength/useGripStrengthValues";
import { useRiskMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { defaultSide } from "../../lib/defaultSide";
import { Trans } from "./trans";

export function MetricGripStrengthDetails() {
  const [params, setParams] = useQueryParams(queries.metric);
  const { data: left } = useRiskMetric("body.grip_strength.left");
  const { data: right } = useRiskMetric("body.grip_strength.right");

  const side =
    params.side[0] ?? defaultSide({ left, right }, (m) => m?.kilograms);
  const setSide = (side: "left" | "right") => setParams({ side: [side] });
  const numOfScansLeft = left?.length || 1;
  const numOfScansRight = right?.length || 1;
  const numOfScans = side === "left" ? numOfScansLeft : numOfScansRight;

  const scanNum = params.scan[0] ?? numOfScans;
  const setScanNum = (num: number) =>
    setParams({
      scan: [num],
    });
  const values = useGripStrengthValues({ scanNum, side });

  return (
    <MetricDetails
      label={<Trans.MetricName />}
      values={values}
      scanNum={scanNum}
      setScanNum={setScanNum}
      side={side}
      setSide={setSide}
      numOfScans={numOfScans}
      FAQText={Trans.FAQ}
      benchmark={
        values?.value != null && (
          <Benchmark
            measure={measures[side]}
            measurement={values.value}
            unit={values.subLabel}
          />
        )
      }
    />
  );
}

const measures = {
  left: "gripStrengthLeft",
  right: "gripStrengthRight",
} as const;
