import { Typography } from "@cur8/maneki";
import { ReactComponent as NekoLogo } from "./neko_logo.svg";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function NoDoctorCredentials() {
  return (
    <div className={styles.NoDoctorCredentials}>
      <div className={styles.image}>
        <NekoLogo />
      </div>
      <Typography variant="body-s" color="subtle">
        <Trans.Subtitle />
      </Typography>
    </div>
  );
}
