import { localize } from "render/context/InternationalizationContext";

const Header = localize({
  en_GB: "Book a Neko Body Scan",
  sv_SE: "Boka en Neko Body Scan",
});

const City = {
  London: localize({
    en_GB: "LONDON",
    sv_SE: "LONDON",
  }),
  Stockholm: localize({
    en_GB: "STOCKHOLM",
    sv_SE: "STOCKHOLM",
  }),
};

const LocationDescription = {
  MultiSite: localize<{ count: number }>({
    en_GB: ({ count }) => <>[{count} centres]</>,
    sv_SE: ({ count }) => <>[{count} center]</>,
  }),
  SingleSite: localize<{ siteName: string }>({
    en_GB: ({ siteName }) => <>[{siteName}]</>,
    sv_SE: ({ siteName }) => <>[{siteName}]</>,
  }),
};

const AllLocationsFilter = localize({
  en_GB: "Any Health centre",
  sv_SE: "Alla Hälsocenter",
});

export const Trans = {
  AllLocationsFilter,
  City,
  Header,
  LocationDescription,
};
