import { useHistory } from "@pomle/react-router-paths";
import { PropsWithChildren } from "react";
import { RouterProvider } from "react-aria-components";

export function AriaRouterProvider(props: PropsWithChildren) {
  const history = useHistory();

  return (
    <RouterProvider navigate={history.push}>{props.children}</RouterProvider>
  );
}
