import { DoctorCredentials } from "render/ui/presentation/doctor/DoctorCredentials";
import { FreeText } from "render/ui/presentation/FreeText";
import { NoDoctorCredentials } from "render/ui/presentation/doctor/NoDoctorCredentials";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface DoctorsNoteProps {
  doctorURI: string | null | undefined;
  note: string;
}

export function DoctorsNote({ doctorURI, note }: DoctorsNoteProps) {
  return (
    <div className={styles.DoctorsNote}>
      <div className={styles.header}>{<Trans.Title />}</div>
      <div className={styles.summary}>
        <FreeText text={note} />
        {doctorURI ? (
          <DoctorCredentials doctorURI={doctorURI} />
        ) : (
          <NoDoctorCredentials />
        )}
      </div>
    </div>
  );
}
