import { Patient } from "@cur8/rich-entity";
import { localize } from "render/context/InternationalizationContext";

const Greeting = localize<{ patient: Patient }>({
  en_GB: ({ patient }) => {
    const name = patient.name?.displayName
      ? ` ${patient.name.displayName}`
      : "";
    return <>Hi{name}, your health report is ready to review.</>;
  },
  sv_SE: ({ patient }) => {
    const name = patient.name?.displayName
      ? ` ${patient.name.displayName}`
      : "";
    return <>Hej{name}, din hälsorapport är klar.</>;
  },
});

const HealthReport = localize({
  en_GB: <>Health Report</>,
  sv_SE: <>Hälsorapport</>,
});

const ScanBooked = localize({
  en_GB: <>Scan Booked</>,
  sv_SE: <>Scan bokad</>,
});

const Resever = {
  Description: localize({
    en_GB: <>Follow Up Visit </>,
    sv_SE: <>Uppföljningsbesök</>,
  }),
  Title: localize({
    en_GB: <>Book</>,
    sv_SE: <>Boka</>,
  }),
};

export const Trans = { Greeting, HealthReport, Resever, ScanBooked };
