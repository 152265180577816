import { parseMarkdown } from "lib/markdown";
import styles from "./styles.module.sass";

interface FreeTextProps {
  text: string;
}

export function FreeText({ text }: FreeTextProps) {
  return <div className={styles.FreeText}>{parseMarkdown(text)}</div>;
}
