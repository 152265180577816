import { createCodec } from "@pomle/paths";

export function set<T extends string>(values: readonly T[]) {
  const set = new Set<string>(values);

  return createCodec<T | undefined>(
    (source) => source ?? "",
    (source) => (set.has(source) ? (source as T) : undefined)
  );
}
