import { Typography } from "@cur8/maneki";
import { downloadFile } from "lib/file/downloadFile";
import { useState } from "react";
import { Skeleton } from "render/ui/presentation/Skeleton";
import { Spinner } from "render/ui/presentation/Spinner";
import { Button } from "render/ui/trigger/Button";
import styles from "./styles.module.sass";
import * as Trans from "./Trans";

type DownloadProps = {
  getFile: (() => Promise<File>) | undefined;
  uri?: string;
};

export function Download({ getFile, uri }: DownloadProps) {
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState(false);

  if (uri) {
    return (
      <a href={uri} target="_blank" rel="noopener noreferrer">
        <DownloadButton />
      </a>
    );
  }

  if (!getFile) {
    return (
      <div className={styles.downloadButtonSkeleton}>
        <Skeleton />
      </div>
    );
  }

  if (downloading) {
    return (
      <>
        <hr className={styles.divider} />
        <div className={styles.content}>
          <div className={styles.row}>
            <Spinner />
            <Typography variant="label-m">
              <Trans.StartingDownload />
            </Typography>
          </div>
          <Typography variant="body-m" color="subtle">
            <Trans.PleaseWait />
          </Typography>
        </div>
      </>
    );
  }

  const download = async () => {
    setDownloading(true);
    try {
      downloadFile(await getFile());
    } catch {
      setError(true);
    }
    setDownloading(false);
  };

  if (error) {
    return (
      <>
        <hr className={styles.divider} />
        <div className={styles.content}>
          <Typography variant="label-m">
            <Trans.DownloadFailed />
          </Typography>
          <Typography variant="body-m" color="subtle">
            <Trans.SomethingWrong />
          </Typography>
        </div>
        <Button
          variant="active"
          onClick={() => {
            setError(false);
            download();
          }}
        >
          <Typography variant="label-m">
            <Trans.TryAgain />
          </Typography>
        </Button>
      </>
    );
  }

  return <DownloadButton onClick={download} />;
}

type DownloadButtonProps = Pick<JSX.IntrinsicElements["button"], "onClick">;

function DownloadButton({ onClick }: DownloadButtonProps) {
  return (
    <Button variant="active" onClick={onClick}>
      <Typography variant="label-m">
        <Trans.Download />
      </Typography>
    </Button>
  );
}
