import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Heart rate</>,
  sv_SE: <>Hjärtrytm</>,
});

const MetricLabel = localize({
  en_GB: <>bpm</>,
  sv_SE: <>bpm</>,
});

const FAQ = {
  Aim: localize({
    en_GB: (
      <>
        You should aim to keep your resting heart rate below 70, as the risk of
        developing heart problems increases if your resting heart rate is
        persistently higher than this. Remember this is just your resting heart
        rate, it is perfectly normal for your heart rate to go higher than this
        when you exercise. You can lower your resting heart rate by doing
        regular cardiovascular exercise. You should aim to do 150 minutes of
        moderate exercise per week, or 75 minutes of intense exercise.
      </>
    ),
    sv_SE: (
      <>
        Du bör sikta på att hålla din vilopuls under 70, eftersom risken för att
        utveckla hjärtproblem ökar om din vilopuls ständigt är högre. Kom ihåg
        att detta bara är din vilopuls, det är helt normalt att din puls stiger
        när du tränar. Du kan sänka din vilopuls genom att träna regelbundet. Du
        bör sikta på att göra 150 minuters måttlig träning per vecka eller 75
        minuters intensiv träning.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        A high resting heart rate could be a sign that the fitness of your heart
        and blood vessels is in need of improvement. Cardiovascular exercise is
        a great way to improve the fitness of your heart and blood vessels, you
        can read more about this here. There are other factors that can increase
        your resting heart rate such as caffeine, and high stress levels. You
        should measure your resting heart rate when you are feeling calm, and
        before your morning coffee. It can also be a sign of a medical problem
        like an overactive thyroid, or an abnormal heart rhythm. If your resting
        heart rate is persistently high then Neko may advise you to discuss it
        with your doctor.
      </>
    ),
    sv_SE: (
      <>
        En hög vilopuls kan vara ett tecken på att ditt hjärtas och blodkärls
        kondition behöver förbättras. Det kan också vara ett tecken på ett
        medicinskt problem som en överaktiv sköldkörtel eller en onormal
        hjärtrytm. Konditionsträning är ett utmärkt sätt att förbättra ditt
        hjärta och dina blodkärl. Det finns andra faktorer som kan öka din
        vilopuls som koffein och höga stressnivåer. Du bör mäta din vilopuls när
        du känner dig lugn och innan morgonkaffet. Om din vilopuls är ihållande
        hög kan Neko råda dig att diskutera det med din läkare.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        A low resting heart rate can be a sign that your heart and blood vessels
        are in excellent health. Professional athletes can have a resting heart
        rate of 40-50 beats per minute. In people with average levels of fitness
        its unusual for it to be below 60 beats per minute. Medical causes of a
        low heart rate include having an underactive thyroid (which slows your
        metabolism down), or problems with the part of the heart that controls
        its rhythm. If your pulse is unexpectedly low and your Neko scan has not
        identified a cause, then you should discuss it with your doctor.
      </>
    ),
    sv_SE: (
      <>
        En låg vilopuls kan vara ett tecken på att ditt hjärta och dina blodkärl
        mår bra. Professionella idrottare kan ha en vilopuls på 40-50 slag per
        minut. Hos personer med genomsnittliga konditionsnivåer är det ovanligt
        att den ligger under 60 slag per minut. Medicinska orsaker till låg
        hjärtfrekvens inkluderar att ha en underaktiv sköldkörtel (vilket saktar
        ner din ämnesomsättning) eller problem med den del av hjärtat som styr
        dess rytm. Om din puls är oväntat låg och din Neko-skanning inte har
        identifierat någon orsak bör du diskutera det med din läkare.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        You can use your resting heart rate to understand whether your
        cardiovascular fitness is being improved by your exercise program. A
        gradual downward trend can be a sign that your cardiovascular fitness is
        improving. If you see it climbing over time, then it could be a sign
        that you need to hit the gym more regularly and do more cardiovascular
        exercise. It can also be a sign of a medical problem developing, an
        underactive thyroid can make your rate slow down, and an overactive
        thyroid can speed it up. If the trend shows that your pulse is steadily
        changing over time, and there is no explanation for it, then you should
        discuss it with your doctor.
      </>
    ),
    sv_SE: (
      <>
        Du kan använda din vilopuls för att förstå om din kardiovaskulära
        kondition förbättras av ditt träningsprogram. En gradvis nedåtgående
        trend kan vara ett tecken på att din kondition förbättras. Om du ser den
        klättra över tiden kan det vara ett tecken på att du behöver gå till
        gymmet mer regelbundet och träna mer konditionsträning. Det kan också
        vara ett tecken på att ett medicinskt problem utvecklas. Om trenden
        visar att din puls stadigt förändras över tiden, och det inte finns
        någon förklaring till det, bör du diskutera det med din läkare.
      </>
    ),
  }),
  Why: localize({
    en_GB: (
      <>
        Your resting heart rate is tightly linked to the health of your heart
        and blood vessels. Your resting heart rate is one factor amongst many
        that have a link with life expectancy. A lower resting heart rate is
        generally linked with having a longer life expectancy. Your heart rate
        can also be a clue whether there are other illnesses, such as thyroid
        problems are developing.
      </>
    ),
    sv_SE: (
      <>
        Din vilopuls är nära kopplad till hjärthälsa och dina blodkärl. Din
        vilopuls är en faktor bland många som har ett samband med förväntad
        livslängd. En lägre vilopuls är i allmänhet kopplad till en längre
        förväntad livslängd.
      </>
    ),
  }),
};

export const Trans = { FAQ, MetricLabel, MetricName };
