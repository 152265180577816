import { localize } from "render/context/InternationalizationContext";

export const Trans = {
  Button: localize({
    en_GB: "OK, continue",
    sv_SE: "OK, fortsätt",
  }),
  ContentOne: localize({
    en_GB: <>Next, let’s talk about your family and background.</>,
    sv_SE: (
      <>Nu ska vi prata om din bakgrund och sjukdomshistoria i familjen.</>
    ),
  }),
  ContentTwo: localize({
    en_GB: (
      <>
        Knowing your background will help us better understand your overall
        health and tailor preventative measures.
      </>
    ),
    sv_SE: (
      <>
        Genom att förstå din bakgrund så kan vi få en bättre överblick av din
        generella hälsa, vilket hjälper oss att anpassa preventiva åtgärder för
        dig.
      </>
    ),
  }),
  Title: localize({
    en_GB: "Thanks for sharing!",
    sv_SE: "Tack för att du delar med dig",
  }),
};
