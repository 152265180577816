import { getRiskWeight, RiskLevel } from "@cur8/measurements";
import { desc } from "lib/sort";
import { QueryLink } from "render/routes/QueryLink";
import { queries } from "render/routes/paths";
import { pickHighestSide } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricEyePressure/highestSide";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useRiskMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

export function MetricEyePressure() {
  const { data: left, isLoading: isLoadingLeft } = useRiskMetric(
    "risk_assessment.eye_pressure.left"
  );
  const { data: right, isLoading: isLoadingRight } = useRiskMetric(
    "risk_assessment.eye_pressure.right"
  );
  const { data: difference, isLoading: isLoadingDifference } = useRiskMetric(
    "risk_assessment.eye_pressure.difference"
  );

  if (isLoadingLeft || isLoadingRight || isLoadingDifference) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (!left || !right || !difference) {
    return null;
  }

  const { cur: latestLeftEyePressure, prev: prevLeftEyePressure } =
    getMetricHistory(left);
  const { cur: latestRightEyePressure, prev: prevRightEyePressure } =
    getMetricHistory(right);
  const { cur: latestDifference } = getMetricHistory(difference);

  const { eyePressure: highestEyePressure, side: highestSide } =
    pickHighestSide({
      left: latestLeftEyePressure,
      right: latestRightEyePressure,
    });
  const previousEyePressure =
    highestSide === "left" ? prevLeftEyePressure : prevRightEyePressure;

  const [metricRating = RiskLevel.Unknown] = [
    latestLeftEyePressure,
    latestRightEyePressure,
    latestDifference,
  ]
    .filter(isDefined)
    .map((s) => s.riskRanges.riskLevel)
    .toSorted(desc(getRiskWeight));

  return (
    <QueryLink query={queries.metric} params={{ metric: ["eye-pressure"] }}>
      <Metric
        metricRating={metricRating}
        value={highestEyePressure?.unit.mmHg}
        label={<Trans.MetricName />}
        description={<Trans.MetricLabel />}
        previousValue={previousEyePressure?.unit.mmHg}
      />
    </QueryLink>
  );
}

function isDefined<T>(value: T | undefined): value is T {
  return Boolean(value);
}
