import { APITypesV1, APITypesV2 } from "@cur8/api-client";
import { Typography } from "@cur8/maneki";
import { Patient, Slot, Visit } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { paths } from "render/routes/paths";
import { FadeIn } from "render/views/HomeView/components/_shared/FadeIn/FadeIn";
import { LinkTo } from "render/views/HomeView/components/_shared/LinkTo/LinkTo";
import { homeViewTestIds } from "render/views/HomeView/testIds";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface VisitSummaryReadyStageProps {
  patient: Patient;
  bookingTokens: APITypesV2.BookingTokenV2[];
  attendedVisit: Visit;
  upcomingVisitSlot: Slot | undefined;
}

export function VisitSummaryReadyStage({
  patient,
  attendedVisit,
  upcomingVisitSlot,
  bookingTokens,
}: VisitSummaryReadyStageProps) {
  const nav = {
    appointment: useNav(paths.appointment),
    booking: useNav(paths.booking),
    payment: useNav(paths.checkout),
    report: useNav(paths.appointmentSummary),
  };

  const time = useMemo(() => {
    if (!upcomingVisitSlot) {
      return undefined;
    }

    const format = "HH:mm";
    const timeZoneId = upcomingVisitSlot.room?.site?.timeZoneId;
    const start = upcomingVisitSlot.startTime.setZone(timeZoneId);
    const end = upcomingVisitSlot.endTime.setZone(timeZoneId);

    return `${start.toFormat(format)} - ${end.toFormat(format)}`;
  }, [upcomingVisitSlot]);
  const date = useMemo(() => {
    if (!upcomingVisitSlot) {
      return undefined;
    }

    const timeZoneId = upcomingVisitSlot.room?.site?.timeZoneId;
    const start = upcomingVisitSlot.startTime.setZone(timeZoneId);
    const isDifferentYear = start.year !== DateTime.now().year;

    return start.toFormat(`d MMMM${isDifferentYear ? " yyyy" : ""}`);
  }, [upcomingVisitSlot]);

  const canBook = useMemo(() => {
    return patient.flags.includes(APITypesV1.PatientFlag.CanBook);
  }, [patient.flags]);

  return (
    <div>
      <FadeIn offset={112}>
        <Typography variant="display-s">
          <Trans.Greeting patient={patient} />
        </Typography>
      </FadeIn>
      <div className={styles.cta}>
        <FadeIn offset={176}>
          <LinkTo
            variant="square"
            href={nav.report.to({})}
            head={<Trans.HealthReport />}
            description={attendedVisit.startTime
              .setZone(attendedVisit.site.timeZoneId)
              .toFormat("d MMMM yyyy")}
            data-testid={homeViewTestIds.healthReportButton}
          />
        </FadeIn>

        {upcomingVisitSlot && (
          <FadeIn offset={176}>
            <LinkTo
              variant="square"
              href={nav.appointment.to({ slotId: upcomingVisitSlot.slotId })}
              head={<Trans.ScanBooked />}
              description={`${time} • ${date}`}
            />
          </FadeIn>
        )}
        {canBook && !upcomingVisitSlot && (
          <FadeIn offset={176}>
            <LinkTo
              variant="square"
              href={
                bookingTokens.length === 0
                  ? nav.payment.to({})
                  : nav.booking.to({})
              }
              head={<Trans.Resever.Title />}
              description={<Trans.Resever.Description />}
            />
          </FadeIn>
        )}
      </div>
    </div>
  );
}
