import { APITypesV1 } from "@cur8/api-client";
import { useNav } from "@pomle/react-router-paths";
import { mapConsents } from "lib/consents/mapConsents";
import { useCallback, useMemo } from "react";
import { useIdentityVerificationStatus } from "render/hooks/api/queries/useIdentityVerificationStatus";
import { useLatestConsentsQuery } from "render/hooks/api/queries/usePatientConsentsQuery";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useIsQuestionnaireCompleteForThisVisitQuery } from "render/hooks/api/queries/useQuestionnaireQuery";
import { usePreVisitConsentStudiesForVisitId } from "render/hooks/api/queries/useVisitQuery";
import { paths, queries } from "render/routes/paths";

export type VisitPrerequisiteStepId =
  | "idv"
  | "questionnaire"
  | "studies"
  | "passkey";

export interface VisitPrerequisiteStep {
  id: VisitPrerequisiteStepId;
  goTo: (slotId: string) => void;
  completed: boolean;
  disabled: boolean;
}

export function useVisitPrerequisites({
  origin = "appointment",
  visitId,
}: {
  origin?: "appointment" | "prereq";
  visitId: string;
}) {
  const patientQuery = usePatientQuery();
  const identityVerificationStatusQuery = useIdentityVerificationStatus();

  const preVisitConsentStudiesQuery =
    usePreVisitConsentStudiesForVisitId(visitId);

  const questionnaireQuery =
    useIsQuestionnaireCompleteForThisVisitQuery(visitId);

  const patientConsentsQuery = useLatestConsentsQuery();

  const mappedConsents = mapConsents({
    previouslySignedConsents: patientConsentsQuery.data,
    requiredConsents: preVisitConsentStudiesQuery.data,
  });

  const unsignedStudyConsentKeys = useMemo(() => {
    return mappedConsents
      ?.filter(
        (consent) =>
          consent.relevantSignature == null ||
          consent.relevantSignature?.expired ||
          consent.relevantSignature?.revoked ||
          consent.consentMetadata.askConsentEveryTime
      )
      .map((consent) => consent.consentMetadata.consentId);
  }, [mappedConsents]);

  const hasLoaded = useMemo(() => {
    let result =
      !!patientQuery.isFetched &&
      !!identityVerificationStatusQuery.isFetched &&
      !!questionnaireQuery.isFetched &&
      !!patientConsentsQuery.isFetched &&
      !!preVisitConsentStudiesQuery.isFetched;

    return result;
  }, [
    patientQuery.isFetched,
    identityVerificationStatusQuery.isFetched,
    questionnaireQuery.isFetched,
    patientConsentsQuery.isFetched,
    preVisitConsentStudiesQuery.isFetched,
  ]);

  const hasIdentityVerified = useMemo(() => {
    if (patientQuery.data == null) {
      return false;
    }

    return patientQuery.data.hasVerifiedIdentity;
  }, [patientQuery.data]);

  const hasIdentityVerificationInProgress =
    identityVerificationStatusQuery.data?.identityVerificationStatus ===
    APITypesV1.IdentityVerificationStatus.Submitted;

  const hasPasskey = useMemo(() => {
    if (patientQuery.data == null) {
      return false;
    }

    return patientQuery.data.hasPasskey;
  }, [patientQuery.data]);

  const hasStudies = useMemo(() => {
    if (patientConsentsQuery.data == null) {
      return false;
    }

    return unsignedStudyConsentKeys?.length === 0;
  }, [patientConsentsQuery.data, unsignedStudyConsentKeys?.length]);

  const needsIdentityVerified = patientQuery.data?.preferredCountry === "GB";
  const needsPasskey = patientQuery.data?.preferredCountry === "GB";
  const needsStudies =
    preVisitConsentStudiesQuery.data &&
    preVisitConsentStudiesQuery.data.length > 0;

  const hasCompletedOnboarding = questionnaireQuery.data === true;

  const nav = {
    addPassKey: useNav(
      paths.appointmentPrereq.addPasskey,
      queries.appointmentPrereq
    ),
    identityVerification: useNav(
      paths.appointmentPrereq.identityVerification,
      queries.appointmentPrereq
    ),
    onboarding: useNav(paths.appointmentPrereq.onboarding, queries.onboarding),
  };

  const goToIdentityVerification = useCallback(
    (slotId: string) => {
      nav.identityVerification.go(
        { slotId },
        { origin: origin ? [origin] : undefined }
      );
    },
    [nav.identityVerification, origin]
  );

  const goToPassKeySetup = useCallback(
    (slotId: string) => {
      nav.addPassKey.go({ slotId }, { origin: origin ? [origin] : [] });
    },
    [nav.addPassKey, origin]
  );

  const goToOnboarding = useCallback(
    (slotId: string) => {
      nav.onboarding.go(
        { slotId },
        {
          initialStep: ["onboarding"],
          origin: origin ? [origin] : [],
        }
      );
    },
    [nav.onboarding, origin]
  );

  const goToStudies = useCallback(
    (slotId: string) => {
      nav.onboarding.go(
        { slotId },
        { initialStep: ["studies"], origin: origin ? [origin] : [] }
      );
    },
    [nav.onboarding, origin]
  );

  const steps = useMemo(() => {
    const result: VisitPrerequisiteStep[] = [];

    if (needsStudies) {
      result.push({
        completed: hasStudies,
        disabled: false,
        goTo: goToStudies,
        id: "studies",
      });
    }

    result.push({
      completed: hasCompletedOnboarding,
      disabled: false,
      goTo: goToOnboarding,
      id: "questionnaire",
    });

    if (needsPasskey) {
      result.push({
        completed: hasPasskey,
        disabled: false,
        goTo: goToPassKeySetup,
        id: "passkey",
      });
    }

    if (needsIdentityVerified) {
      result.push({
        completed: hasIdentityVerified || hasIdentityVerificationInProgress,
        disabled: false,
        goTo: goToIdentityVerification,
        id: "idv",
      });
    }

    return result;
  }, [
    goToIdentityVerification,
    goToOnboarding,
    goToPassKeySetup,
    goToStudies,
    hasCompletedOnboarding,
    hasIdentityVerificationInProgress,
    hasIdentityVerified,
    hasPasskey,
    hasStudies,
    needsIdentityVerified,
    needsPasskey,
    needsStudies,
  ]);

  const getNextStep = useCallback(
    ({ after }: { after?: VisitPrerequisiteStepId } = {}) => {
      let index = -1;
      if (after) {
        index = steps.findIndex((step) => step.id === after);
      }
      const nextStep = steps
        .filter((_, stepIndex) => {
          return stepIndex > index;
        })
        .find((step) => step.completed === false);
      const result = nextStep?.goTo;

      return result;
    },
    [steps]
  );

  const isReady = useMemo(() => getNextStep() == null, [getNextStep]);

  return {
    getNextStep,

    goToIdentityVerification,

    goToOnboarding,
    goToPassKeySetup,
    goToStudies,

    hasCompletedOnboarding,
    hasIdentityVerificationInProgress,
    hasIdentityVerified,
    hasLoaded,
    hasPasskey,

    hasStudies,
    isReady,
    needsIdentityVerified,
    needsPasskey,

    needsStudies,

    steps,
  };
}
