import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Hs-CRP</>,
  sv_SE: <>Hs-CRP</>,
});

const MetricUnit = localize({
  en_GB: <>mg/L</>,
  sv_SE: <>mg/L</>,
});

const FAQ = {
  Aim: localize({
    en_GB: (
      <>
        You should aim to keep your level of inflammation as low as possible.
        Ideally your average Hs-CRP level should be below 1 mg/L for optimal
        health. Hs-CRP varies quite widely over time, so it’s best to look at
        the average of two to three readings, rather than a single reading.
      </>
    ),
    sv_SE: (
      <>
        Du bör sträva efter att hålla din inflammationsnivå så låg som möjligt.
        Helst bör din genomsnittliga Hs-CRP-nivå vara under 1 mg/L för optimal
        hälsa. Hs-CRP varierar ganska mycket över tiden, så det är bäst att
        titta på genomsnittet av två till tre avläsningar, snarare än en enda
        avläsning.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        If your Hs-CRP is higher than 2 mg/L it slightly increases the risk of
        developing cardiovascular disease over your lifetime. If your Hs-CRP is
        greater than 3mg/L it increases the risk even further.
      </>
    ),
    sv_SE: (
      <>
        Om ditt Hs-CRP-värde är högre än 2 mg/L ökar detta risken för att
        utveckla hjärt-kärlsjukdom under din livstid. Om ditt Hs-CRP är högre än
        3 mg/L ökar detta risken ytterligare.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        The lower your Hs-CRP result is, the better the outlook for your future
        health. Because of this we don’t flag Hs-CRP results as being too low.
      </>
    ),
    sv_SE: (
      <>
        Ju lägre ditt Hs-CRP-resultat är, desto bättre är utsikterna för din
        framtida hälsa. På grund av detta flaggar vi inte Hs-CRP-resultat som
        för låga.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        Because inflammation can change quickly in response to injury and
        infection, we recommend looking at the average level over time, rather
        than a single reading. If you see the trend in your Hs-CRP results
        decreasing over time then this means that you are decreasing the amount
        of inflammation in your body, which will improve your long-term health.
        This can be accomplished through exercise, caloric restriction, a diet
        rich in leafy greens and fruit, sufficient sleep, weight loss and smoke
        cessation.
      </>
    ),
    sv_SE: (
      <>
        Eftersom inflammation kan förändras snabbt som svar på skada och
        infektion rekommenderar vi att titta på den genomsnittliga nivån över
        tid, snarare än en enda avläsning. Om du ser att trenden i dina
        Hs-CRP-resultat minskar över tiden betyder det att du minskar mängden
        inflammation i din kropp, vilket kommer att förbättra din långsiktiga
        hälsa. Detta kan åstadkommas genom träning, kaloribegränsning, en diet
        rik på bladgrönt och frukt, tillräckligt med sömn, viktminskning och
        rökavvänjning.
      </>
    ),
  }),
  Why: localize({
    en_GB: (
      <>
        C-reactive protein is made by your body whenever there is inflammation.
        The ‘Hs’ stands for high sensitivity. This means the test we use can
        measure down to very low levels. Having slightly raised inflammation for
        long periods of time damages the blood vessels of the body and can
        increase the likelihood of developing problems like heart disease and
        chronic kidney disease. We measure Hs-CRP because it can be used to work
        out whether inflammation is adding extra risk of heart disease to the
        risk already caused by cholesterol and blood pressure.
      </>
    ),
    sv_SE: (
      <>
        C-reaktivt protein (CRP) frigörs i din kropp från levern som en del av
        den inflammatoriska reaktionen. "HS" står för högkänslig och det betyder
        att testet vi använder kan uppmäta även låga nivåer. Att ha en förhöjd
        inflammation under långa perioder skadar kroppens blodkärl och kan öka
        sannolikheten för att utveckla problem som hjärtkärlsjukdomar och
        kroniska njursjukdomar. Detta prov har därför visat sig vara en bra
        markör för allmän hjärtkärlhälsa.
      </>
    ),
  }),
};

export const Trans = { FAQ, MetricName, MetricUnit };
