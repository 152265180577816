import { Path, PathCodec, codecs, createPath, createQuery } from "@pomle/paths";
import * as localCodecs from "./codecs";

const root = createPath("", {});
const patientHome = root;
const assignment = root.append("assignment/:assignmentId", {
  assignmentId: codecs.string,
});

const profile = root.append("profile", {});
const profileCredentials = profile.append("credentials", {});
const profileCredentialsEditName = profileCredentials.append("edit/name", {});
const profileCredentialsEditPhone = profileCredentials.append("edit/phone", {});
const profileCredentialsEditEmail = profileCredentials.append("edit/email", {});
const profileCredentialsEditLanguage = profileCredentials.append(
  "edit/language",
  {}
);
const profileCredentialsEditUnits = profileCredentials.append("edit/units", {});

const profileTokens = profile.append("invitations", {});

const dataAndPrivacy = profile.append("data-and-privacy", {});
const dataAndPrivacyConsents = dataAndPrivacy.append("consents/:consentId", {
  consentId: codecs.string,
});
const dataAndPrivacyExportData = dataAndPrivacy.append("export-data", {});

const consent = root.append("consent", {});

const onboarding = root.append("onboarding", {});
const report = root.append("report", {});
const appointmentSummary = root.append("summary", {});
const appointmentSummaryData = appointmentSummary.append("data", {});
const appointmentSummaryHistory = appointmentSummary.append("history", {});
const appointmentSummaryHistoryItem = appointmentSummaryHistory.append(
  ":messageId",
  { messageId: codecs.string }
);

const booking = root.append("booking", {});
const shareBooking = booking.append("/share/:slotId/:visitId", {
  slotId: codecs.string,
  visitId: codecs.string,
});
const appointment = root.append("appointment/:slotId", {
  slotId: codecs.string,
});

const checkout = root.append("payment", {});
const checkoutCard = checkout.append("card-payment", {});
const checkoutSuccess = root.append("payment-complete", {});
const checkoutFail = root.append("payment-fail", {});
const claimBookingToken = root.append("claim/:segment", {
  segment: codecs.string,
});
const memberInviteCryptic = root.append("i/c63c5e2a0637", {});
const partners = root.append("partners", {});
const atriumSignIn = partners.append("atrium/sign-in", {});

const invite = root.append("invite", {});
const followUpInvite = invite.append("follow-up/:inviteId", {
  inviteId: codecs.string,
});

function createVisitPrereqPaths<T extends PathCodec>(origin: Path<T>) {
  const root = origin.append("prereq", {});

  const identityVerification = root.append("idv", {});

  const addPasskey = root.append("passkey", {});
  const addPasskeyResult = root.append("passkey-result", {});

  const onboarding = root.append("onboarding", {});

  return {
    addPasskey,
    addPasskeyResult,
    identityVerification,
    onboarding,
    root,
  };
}

function createAuthPaths<T extends PathCodec>(origin: Path<T>) {
  const root = origin.append("auth", {});
  const redirect = root.append("redirect", {});

  return {
    redirect,
  };
}

const concierge = {
  checkIn: root.append("concierge/check-in/:sessionId", {
    sessionId: codecs.string,
  }),
};

export const paths = {
  appointment,
  appointmentPrereq: createVisitPrereqPaths(appointment),
  appointmentSummary,
  appointmentSummaryData,
  appointmentSummaryHistory,
  appointmentSummaryHistoryItem,
  assignment,
  atriumSignIn,
  auth: createAuthPaths(root),
  booking,
  checkout,
  checkoutCard,
  checkoutFail,
  checkoutSuccess,
  claimBookingToken,
  concierge,
  consent,
  dataAndPrivacy,
  dataAndPrivacyConsents,
  dataAndPrivacyExportData,
  followUpInvite,
  memberInviteCryptic,
  onboarding,
  patientHome,
  profile,
  profileCredentials,
  profileCredentialsEditEmail,
  profileCredentialsEditLanguage,
  profileCredentialsEditName,
  profileCredentialsEditPhone,
  profileCredentialsEditUnits,
  profileTokens,
  report,
  root,
  shareBooking,
};

export const queries = {
  appointment: createQuery({
    calendarVisible: codecs.boolean,
  }),
  appointmentPrereq: createQuery({
    origin: codecs.oneOf(["appointment", "prereq"] as const),
  }),
  assignments: createQuery({
    end: codecs.string,
    start: codecs.string,
  }),
  booking: createQuery({
    day: codecs.string,
    slotId: codecs.string,
  }),
  checkIn: createQuery({
    state: codecs.string,
  }),
  checkout: createQuery({
    orderId: codecs.string,
  }),
  contact: createQuery({
    status: codecs.string,
  }),
  metric: createQuery({
    metric: localCodecs.set([
      "abi",
      "ankle-pressure",
      "basophil",
      "blood-oxygen",
      "blood-pressure",
      "bmi",
      "cholesterol",
      "eosinophil",
      "eye-pressure",
      "glucose",
      "grip-strength",
      "haemoglobin",
      "hba1c",
      "hdl",
      "heart-rate",
      "hs-crp",
      "ldl",
      "lymphocyte",
      "neutrophil",
      "non-hdl",
      "skin",
      "triglycerides",
      "weight",
      "whiteblood-cell",
    ] as const),
    scan: codecs.number,
    side: codecs.oneOf(["left", "right"] as const),
  }),
  onboarding: createQuery({
    initialStep: codecs.oneOf([
      "onboarding",
      "studies",
      "studies-consent",
    ] as const),
    origin: codecs.oneOf(["appointment", "prereq"] as const),
  }),
  payment: createQuery({
    code: codecs.string,
  }),
};
