import { useQueryParams } from "@pomle/react-router-paths";
import { Sticky } from "@pomle/react-viewstack";
import { FunctionComponent } from "react";
import { queries } from "render/routes/paths";
import { Backdrop } from "render/ui/layout/Backdrop";
import { MetricABIDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricABI/Details";
import { MetricAnklePressureDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricAnklePressure/Details";
import { MetricBMIDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBMI/Details";
import { MetricBasophilsDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBasophils/Details";
import { MetricBloodOxygenDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodOxygen/Details";
import { MetricBloodPressureDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodPressure";
import { MetricHDLDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodworkHdl/Details";
import { MetricLDLDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodworkLdl/Details";
import { MetricNonHDLDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodworkNonHdl/Details";
import { MetricHsCRPDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricCRP/Details";
import { MetricCholesterolDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricCholesterol/Details";
import { MetricEosinophilsDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricEosinophils/Details";
import { MetricEyePressureDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricEyePressure/Details";
import { MetricGlucoseDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricGlucose/Details";
import { MetricGripStrengthDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricGripStrength/Details";
import { MetricHbA1cDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricHBA1C/Details";
import { MetricHaemoglobinDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricHaemoglobin/Details";
import { MetricHeartRateDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricHeartRate/Details";
import { MetricLymphocytesDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricLymphocytes/Details";
import { MetricNeutrophilsDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricNeutrophils/Details";
import { MetricSkinDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricSkinLesions";
import { MetricTriglyceridesDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricTriglycerides/Details";
import { MetricWeightDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricWeight/Details";
import { MetricWhiteBloodCellsDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricWhiteBloodCells/Details";

export function MetricDetailsPanel() {
  const [params, setParams] = useQueryParams(queries.metric);
  const metricName = params.metric.at(0);
  const Content = metricName && metrics[metricName];

  return (
    <Backdrop
      active={!!Content}
      kind="transparent"
      size="half"
      onOutsideClick={() => setParams({ metric: [], scan: [], side: [] })}
    >
      <Sticky>{!!Content && <Content />}</Sticky>
    </Backdrop>
  );
}

type MetricPaths = NonNullable<
  NonNullable<Parameters<typeof queries.metric.build>[0]["metric"]>[number]
>;

const metrics: Record<MetricPaths, FunctionComponent> = {
  abi: MetricABIDetails,
  "ankle-pressure": MetricAnklePressureDetails,
  basophil: MetricBasophilsDetails,
  "blood-oxygen": MetricBloodOxygenDetails,
  "blood-pressure": MetricBloodPressureDetails,
  bmi: MetricBMIDetails,
  cholesterol: MetricCholesterolDetails,
  eosinophil: MetricEosinophilsDetails,
  "eye-pressure": MetricEyePressureDetails,
  glucose: MetricGlucoseDetails,
  "grip-strength": MetricGripStrengthDetails,
  haemoglobin: MetricHaemoglobinDetails,
  hba1c: MetricHbA1cDetails,
  hdl: MetricHDLDetails,
  "heart-rate": MetricHeartRateDetails,
  "hs-crp": MetricHsCRPDetails,
  ldl: MetricLDLDetails,
  lymphocyte: MetricLymphocytesDetails,
  neutrophil: MetricNeutrophilsDetails,
  "non-hdl": MetricNonHDLDetails,
  skin: MetricSkinDetails,
  triglycerides: MetricTriglyceridesDetails,
  weight: MetricWeightDetails,
  "whiteblood-cell": MetricWhiteBloodCellsDetails,
};
