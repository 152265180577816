import { localize } from "render/context/InternationalizationContext";

export const Title = localize({
  en_GB: <>Add to calendar</>,
  sv_SE: <>Lägg till i kalender</>,
});

export const Cancel = localize({
  en_GB: <>Cancel</>,
  sv_SE: <>Stäng</>,
});
