import { localize } from "render/context/InternationalizationContext";

const Header = localize({
  en_GB: "Before we begin...",
  sv_SE: "Innan vi börjar...",
});

const Description = localize({
  en_GB:
    "We need your permission to deliver the most optimal Neko health experience.",
  sv_SE:
    "Vi behöver ditt godkännande för att leverera den mest optimala Neko-upplevelsen.",
});

const Continue = localize({
  en_GB: "Read more",
  sv_SE: "Läs mer",
});

export const Trans = {
  Continue,
  Description,
  Header,
};
