import { Typography } from "@cur8/maneki";
import { PathLink } from "@pomle/react-router-paths";

export function parseHyperlinks(line: string) {
  // splits regular text and hyperlinks.
  // text is always on even indexes, links are always on odd indexes.
  // e.g.:
  // 'foo [text](link) bar' => ['foo', '[text](link)', 'bar']
  // '[text](link)' => ['', '[text](link)', '']
  // 'just text' => ['just text']
  const parts = line.split(hyperlinkRegex);

  // line does not contain `[text](link)`
  if (parts.length < 2) {
    return line;
  }

  return parts
    .map((part, index) => {
      // when even, `part` is not `[text](link)`
      if (index % 2 === 0) {
        return part;
      }

      const [text, href] = part.slice(1, -1).split("](");
      return (
        <PathLink key={part} to={href}>
          <Typography variant="link-m">{text}</Typography>
        </PathLink>
      );
    })
    .filter(Boolean); // remove empty strings, just to clean up
}

const hyperlinkRegex = /(\[[^[\]]+\]\([^()]+\))/;
