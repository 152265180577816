import { useMutation, useQueryClient } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useSnackbar } from "notistack";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { queryKey as patientConsentsQueryKey } from "render/hooks/api/queries/usePatientConsentsQuery";
import { queryKey as validPatientConsentsQueryKey } from "render/hooks/api/queries/useValidPatientConsentsQuery";
import * as Trans from "./trans";

type GiveConsentPayload = {
  documentUri: string | undefined;
  consentMetadataId: string | undefined;
};

function mutationFunction(
  apiClient: APIClient,
  patientId: string,
  payload: GiveConsentPayload
) {
  const { documentUri, consentMetadataId } = payload;
  if (documentUri == null) {
    return Promise.reject(
      new Error("No documentUri provided for the give consent mutation")
    );
  }
  return apiClient.consent.saveNewConsent(patientId, {
    consentMetadataId,
    consentSignature: {
      $type: "login",
    },
    documentUri,
  }).result;
}

export function useGiveConsentMutation() {
  const queryClient = useQueryClient();
  const { patientId } = useSession();
  const apiClient = useAPIClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (payload: GiveConsentPayload) =>
      mutationFunction(apiClient, patientId, payload),
    onError() {
      enqueueSnackbar(<Trans.ConsentError />, { variant: "error" });
    },
    async onSuccess() {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: patientConsentsQueryKey(patientId),
        }),
        queryClient.invalidateQueries({
          queryKey: validPatientConsentsQueryKey(patientId),
        }),
      ]);
    },
  });
}
