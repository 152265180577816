import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Total cholesterol</>,
  sv_SE: <>Totalt kolesterol</>,
});

const MetricUnit = localize({
  en_GB: <>mmol/L</>,
  sv_SE: <>mmol/L</>,
});

const FAQ = {
  Aim: localize({
    en_GB: (
      <>
        If you’ve had an illness like a stroke or heart attack, then your doctor
        will have given you a lower target. You should look at your HDL and
        non-HDL cholesterol as well to understand the impact of cholesterol on
        your health.
      </>
    ),
    sv_SE: (
      <>
        Om du har haft en sjukdom som en stroke eller hjärtinfarkt kommer din
        läkare att ha gett dig ett lägre mål. Du bör också titta på ditt HDL-
        och non-HDL-kolesterol för att förstå effekterna av kolesterol på din
        hälsa.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        Having higher than average cholesterol levels increases the likelihood
        that you are going to develop cardiovascular problems like angina, heart
        attack, or a stroke. In most people, the best way to work out whether
        high cholesterol is concerning is to look at the levels of unhealthy
        non-HDL cholesterol and healthy HDL cholesterol.
      </>
    ),
    sv_SE: (
      <>
        Att ha högre än genomsnittliga kolesterolnivåer ökar risken för att du
        ska utveckla hjärt- och kärlproblem som kärlkramp, hjärtinfarkt eller
        stroke. Hos de flesta människor är det bästa sättet att avgöra om högt
        kolesterol är oroande genom att titta på nivåerna av ohälsosamt
        non-HDL-kolesterol och hälsosamt HDL-kolesterol.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        Low cholesterol is less common and is usually healthy. However, people
        with extremely low levels are more likely to have other health problems.
        This is partly because some diseases cause very low cholesterol, and
        partly because some cholesterol is needed by the body to build cells and
        hormones.
      </>
    ),
    sv_SE: (
      <>
        Lågt kolesterol är mindre vanligt och är vanligtvis hälsosamt. Men
        människor med extremt låga nivåer löper större risk för andra
        hälsoproblem. Detta beror delvis på att vissa sjukdomar orsakar mycket
        lågt kolesterol, och delvis på att en viss mängd kolesterol behövs av
        kroppen för att bygga celler och hormoner.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        You can use the trend in your cholesterol level to monitor the effects
        of lifestyle changes such as a healthy diet on your health. For most
        people, the trend in healthy HDL and unhealthy non-HDL cholesterol will
        give the most detailed view.
      </>
    ),
    sv_SE: (
      <>
        Du kan använda trenden i din kolesterolnivå för att övervaka effekterna
        av livsstilsförändringar såsom en hälsosam kost på din hälsa. För de
        flesta människor ger trenden i hälsosamt HDL- och ohälsosamt
        non-HDL-kolesterol den mest detaljerade bilden.
      </>
    ),
  }),
  Why: localize({
    en_GB: (
      <>
        Cholesterol is used by our bodies to manufacture hormones and some of
        the key structures within the body’s cells. It is an important building
        block for our bodies, but having too much cholesterol, or an imbalance
        in the healthy and unhealthy cholesterol can lead to problems.
      </>
    ),
    sv_SE: (
      <>
        Kolesterol används av våra kroppar för att tillverka hormoner och några
        av de viktigaste strukturerna inom kroppens celler. Det är en viktig
        byggsten för våra kroppar, men att ha för mycket kolesterol eller en
        obalans i det friska och ohälsosamma kolesterolet kan leda till problem.
      </>
    ),
  }),
};

export const Trans = { FAQ, MetricName, MetricUnit };
