import type { MetricName } from "@cur8/measurements";
import { queries } from "render/routes/paths";

export function getMetricLink(link: string) {
  const [metricName, side] = link.split(/\.(?=left|right)/) as MetricAndSide;
  const metric = metricLinkMap[metricName];
  return metric ? path(metric, side) : link;
}

type MetricLink = WithoutSide<MetricName> | "skin.markings";
type MetricAndSide = [MetricLink, Side?];
type Side = "left" | "right";
type WithoutSide<M> = M extends `${infer T}.${Side}` ? T : M;
type MetricPath = NonNullable<
  Parameters<typeof queries.metric.build>[0]["metric"]
>[number];

const metricLinkMap: { [M in MetricLink]?: MetricPath } = {
  "bloodwork.cholesterol": "cholesterol",
  "bloodwork.crp": "hs-crp",
  "bloodwork.glucose": "glucose",
  "bloodwork.haemoglobin": "haemoglobin",
  "bloodwork.hba1c": "hba1c",
  "bloodwork.hdl": "hdl",
  "bloodwork.ldl": "ldl",
  "bloodwork.non_hdl": "non-hdl",
  "bloodwork.triglycerides": "triglycerides",
  "bloodwork.white_blood_cells.basophils": "basophil",
  "bloodwork.white_blood_cells.eosinophils": "eosinophil",
  "bloodwork.white_blood_cells.lymphocytes": "lymphocyte",
  "bloodwork.white_blood_cells.neutrophils": "neutrophil",
  "bloodwork.white_blood_cells.total": "whiteblood-cell",
  "body.bmi": "bmi",
  "body.grip_strength": "grip-strength",
  "body.weight": "weight",
  "cardio.abi": "abi",
  "cardio.ankle_pressure": "ankle-pressure",
  "cardio.brachial_pressure": "blood-pressure",
  "cardio.ecg.heart_rate": "heart-rate",
  /* Retain for backwards compatibility */
  "cardio.heart_rate": "heart-rate",
  "cardio.oxygen_saturation": "blood-oxygen",
  "risk_assessment.eye_pressure": "eye-pressure",
  "skin.markings": "skin",
};

function path(metric: MetricPath, side?: Side) {
  return searchParams({ metric, side });
}

function searchParams(obj: Record<string, string | undefined>) {
  return (
    "?" +
    Object.entries(obj)
      .filter(([_, value]) => value)
      .map((pair) => pair.join("="))
      .join("&")
  );
}
