import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useRiskMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

type useEyePressureValuesType = {
  scanNum?: number;
  side: "left" | "right";
};

export function useEyePressureValues({
  scanNum,
  side,
}: useEyePressureValuesType) {
  const metricName =
    side === "left"
      ? "risk_assessment.eye_pressure.left"
      : "risk_assessment.eye_pressure.right";
  const { data } = useRiskMetric(metricName);

  if (!data) {
    return;
  }

  const { cur, prev } = getMetricHistory(data, scanNum);
  const { riskIntervals, riskLevel } = cur?.riskRanges ?? {};

  return {
    metricRating: riskLevel,
    previousValue: prev?.unit.mmHg,
    ranges: toChartRanges(riskIntervals),
    scanDate: cur?.measurement.timestampStart,
    subLabel: <Trans.MetricLabel />,
    value: cur?.unit.mmHg,
  };
}
