import { APITypesV1 } from "@cur8/api-client";
import { fromAPI } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

const queryKey = (patientId: string) => ["appointment-summaries", patientId];
const queryFn = (apiClient: APIClient, patientId: string) => async () => {
  return apiClient.immutableVisitSummary
    .querySummary(
      { patientId },
      { isLatestVersion: true, order: APITypesV1.SortOrder.Desc }
    )
    .result.then((r) => r.items.map(fromAPI.toImmutableVisitSummary));
};

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export function useAppointmentSummariesQuery<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    ...options,
    queryFn: queryFn(apiClient, patientId),
    queryKey: queryKey(patientId),
  });
}
