import { Info } from "./Info";
import { Trans } from "./trans";

export function TipsSection() {
  return (
    <Info title={<Trans.BeforeYourScan />}>
      <Trans.CheckList.Caffeine />
      <Trans.CheckList.Eating />
      <Trans.CheckList.Exercises />
      <Trans.CheckList.Skin />
    </Info>
  );
}
