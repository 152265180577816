import { localize } from "render/context/InternationalizationContext";

const Share = localize({
  en_GB: <>Share</>,
  sv_SE: <>Dela</>,
});

const Continue = localize({
  en_GB: <>Continue</>,
  sv_SE: <>Fortsätt</>,
});

const ScanBookedShareableAsset = localize({
  en_GB: "Scan booked shareable asset",
  sv_SE: "Skanna bokad delningsbar tillgång",
});

export const Trans = {
  Continue,
  ScanBookedShareableAsset,
  Share,
};
