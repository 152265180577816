import { Typography } from "@cur8/maneki";
import { ReactComponent as CheckIconSmall } from "assets/icons/16x16/16x16_checkmark.svg";
import { ReactComponent as ChevronIconSmall } from "assets/icons/16x16/16x16_chevron-right.svg";
import React from "react";
import styles from "./styles.module.sass";

interface StepButtonProps extends React.ButtonHTMLAttributes<unknown> {
  completed: boolean;
}

export function StepButton({
  completed,
  children,
  disabled,
  ...props
}: StepButtonProps) {
  return (
    <button
      className={styles.StepButton}
      disabled={disabled}
      data-completed={completed}
      data-can-click={props.onClick != null}
      {...props}
    >
      {completed && (
        <div className={styles.iconContainer}>
          <CheckIconSmall />
        </div>
      )}
      <div className={styles.todoText}>
        <Typography color={completed ? "subtle" : "default"} variant="label-m">
          {children}
        </Typography>
      </div>
      {props.onClick && <ChevronIconSmall className={styles.chevron} />}
    </button>
  );
}
