import { Visit } from "@cur8/rich-entity";

export enum VisitValidity {
  NoAppointment = "no-appointment",
  EarlyArrival = "early-arrival",
  LateArrival = "late-arrival",
}

const GracePeriodMinutes = 20;

export function getVisitValidity(visit?: Visit) {
  if (!visit) {
    return VisitValidity.NoAppointment;
  }
  if (visit.startTime.diffNow("minutes").minutes > GracePeriodMinutes) {
    return VisitValidity.EarlyArrival;
  }
  if (visit.startTime.diffNow("minutes").minutes < -GracePeriodMinutes) {
    return VisitValidity.LateArrival;
  }
  return null;
}
