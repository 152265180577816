// Used to slow down all animation durations
const timeMultiplier = 1;

export const Timings: Record<string, number> = {
  // Continue button animation
  buttonContinueDelaySec: 0.6 * timeMultiplier,

  buttonContinueDurationSec: 0.5 * timeMultiplier,

  // Share button animation
  buttonShareDelaySec: 0.5 * timeMultiplier,

  buttonShareDurationSec: 0.5 * timeMultiplier,

  cardFooterDelaySec: 0.15 * timeMultiplier,

  // Yellow footer animation
  cardFooterDurationSec: 0.25 * timeMultiplier,

  cardHeaderDelaySec: 0.5 * timeMultiplier,

  // Body Scan Complete header animation
  cardHeaderDurationSec: 0.25 * timeMultiplier,

  // Logo color transition from dark to light
  cardLogoDurationSec: 1 * timeMultiplier,

  // Card duration to trigger sizing, padding, unblurify, etc. to ensure everything happens at the same time
  cardTransitionDurationSec: 0.6 * timeMultiplier,

  // Duration for how long the text should be stationary for before animating out
  introTextIdleDurationSec: 4.5 * timeMultiplier,

  // Delay between each line in seconds
  introTextLineDelaySec: 0.05 * timeMultiplier,

  // Intro text line in animation duration
  introTextLineInDurationSec: 0.8 * timeMultiplier,

  // Intro text line out animation duration
  introTextLineOutDurationSec: 0.6 * timeMultiplier,
};

export const Transforms: Record<string, number> = {
  // Base translate is added to all lines
  introTextLineBaseTranslateY: 100,

  // Incremental translate is multiplied by the line number to create a staggered effect for desktop
  introTextLineInDesktopIncrementTranslateY: 15,

  // Incremental translate is multiplied by the line number to create a staggered effect for mobile
  introTextLineInMobileIncrementTranslateY: 10,

  introTextLineOutDesktopIncrementTranslateY: 20,
  introTextLineOutMobileIncrementTranslateY: 20,
};
