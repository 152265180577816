import { PaymentElement } from "@stripe/react-stripe-js";
import { Stripe, StripeElement } from "@stripe/stripe-js";

interface PaymentFormProps {
  onReady?: (element: StripeElement) => void;
  className?: string;
  stripe: Stripe | null;
}

export function PaymentForm({ onReady, className, stripe }: PaymentFormProps) {
  if (!stripe) {
    return null;
  }

  return (
    <PaymentElement
      className={className}
      onReady={(e) => onReady?.(e)}
      options={{
        wallets: {
          applePay: "never",
          googlePay: "never",
        },
      }}
    />
  );
}
