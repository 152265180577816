import { APITypesV1 } from "@cur8/api-client";
import { fromAPI } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { QueryOptions } from "typings/query";

const queryKey = (patientId: string) => ["attended-visits", patientId] as const;

async function queryFn(apiClient: APIClient, patientId: string) {
  return apiClient.visit
    .getPatientVisits(
      { patientId },
      {
        exams: [APITypesV1.MedicalExam.NekoBodyScan],
        order: APITypesV1.SortOrder.Desc,
        pageSize: 1,
        status: [
          APITypesV1.VisitStatus.Ongoing,
          APITypesV1.VisitStatus.Completed,
        ],
      }
    )
    .result.then((r) => r.items.map(fromAPI.toVisit).at(0) ?? null);
}

type Body = Awaited<ReturnType<typeof queryFn>>;
type Key = ReturnType<typeof queryKey>;

export function useLatestAttendedVisitQuery<T = Body>(
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    ...options,
    queryFn: () => queryFn(apiClient, patientId),
    queryKey: queryKey(patientId),
  });
}
