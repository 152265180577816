import { fromAPI } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

const queryKey = (patientId: string) => ["patient", patientId, "gdpr"];
const queryFn = (apiClient: APIClient, patientId: string) => async () => {
  const exportData = await apiClient.gdprExport.fetchLatestGdprExport({
    patientId,
  }).result;

  return fromAPI.toGdprExport(exportData);
};

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

export type GdprExport = ReturnType<(typeof fromAPI)["toGdprExport"]>;

export function useGdprExportQuery<T = Body>(
  options?: QueryOptions<Body, Key, T>
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    ...options,
    queryFn: queryFn(apiClient, patientId),
    queryKey: queryKey(patientId),
  });
}

useGdprExportQuery.queryKey = queryKey;
useGdprExportQuery.queryFn = queryFn;
