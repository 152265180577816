import { classNames } from "@pomle/classnames";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { OpaqueLayer } from "render/ui/presentation/OpaqueLayer";
import styles from "./styles.module.sass";

export function Modal({
  isActive,
  onOutsideClick,
  children,
}: PropsWithChildren<{
  isActive: boolean;
  onOutsideClick: () => void;
}>) {
  const ref = useRef<HTMLDivElement>(null);
  const [isMounted, setIsMounted] = useState(isActive);

  useEffect(() => {
    if (isActive) {
      setIsMounted(true);
    }
  }, [isActive]);
  const onTransitionEnd = useCallback(() => {
    if (isActive) {
      return;
    }
    setIsMounted(false);
  }, [isActive]);
  const isSameTarget = useCallback(
    (t1: EventTarget | undefined, t2: EventTarget | null) => t1 === t2,
    []
  );
  const onBackgroundClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!isSameTarget(e.target, ref.current)) {
        return;
      }
      onOutsideClick();
    },
    [onOutsideClick, isSameTarget]
  );

  return (
    <OpaqueLayer isActive={isActive} variant="blur">
      <div ref={ref} onClick={onBackgroundClick} className={styles.modal}>
        <div
          onTransitionEnd={onTransitionEnd}
          className={classNames(styles.modalContent, {
            [styles.active]: isActive,
            [styles.hidden]: !isActive,
          })}
        >
          {isMounted && children}
        </div>
      </div>
    </OpaqueLayer>
  );
}
