import { Typography } from "@cur8/maneki";
import { Slot } from "@cur8/rich-entity";
import { ReactComponent as Squircle } from "assets/squircle.svg";
import { PropsWithChildren } from "react";
import {
  VisitPrerequisiteStep,
  VisitPrerequisiteStepId,
  useVisitPrerequisites,
} from "render/hooks/api/useVisitPrerequisites";
import { useTracking } from "render/hooks/useTracking";
import { appointmentEvents } from "../../tracking";
import { CompleteProfileButton } from "../CompleteProfileButton/";
import { StepButton } from "./components/StepButton/StepButton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export interface StepsSectionProps {
  slot: Slot;
  visitId: string;
}

const stepToLabel = {
  idv: "IdentityVerification",
  passkey: "Passkey",
  questionnaire: "CompleteOnboarding",
  studies: "RenewStudies",
} satisfies Record<VisitPrerequisiteStepId, keyof typeof Trans.TodoList.Todo>;

function stepText(
  step: VisitPrerequisiteStep
): (() => JSX.Element) | undefined {
  const transLabel = stepToLabel[step.id];
  return Trans.TodoList.Todo[transLabel];
}

export function StepsSection({ slot, visitId }: StepsSectionProps) {
  const { isReady, steps } = useVisitPrerequisites({
    origin: "appointment",
    visitId,
  });

  const todo = steps.filter((step) => !step.completed).length;

  return (
    <div className={styles.StepsSection}>
      <div>
        {todo > 0 && (
          <Label>
            <Trans.Tasks count={todo} />
          </Label>
        )}
        <Typography variant="title-m">
          {isReady ? (
            <Trans.Title.Ready.Header />
          ) : (
            <Trans.Title.NotReady.Header />
          )}
        </Typography>
        {!isReady && (
          <>
            <div className={styles.headerSpacer} />
            <Typography variant="body-s">
              <Trans.Title.NotReady.SubHeader />
            </Typography>
          </>
        )}
      </div>

      <StepsTodoList steps={steps} slot={slot} />

      {!isReady && (
        <div className={styles.profileButton}>
          <CompleteProfileButton slot={slot} visitId={visitId} />
        </div>
      )}
    </div>
  );
}

type ListProps = {
  steps: VisitPrerequisiteStep[];
  slot: Slot;
};

export function StepsTodoList(props: ListProps) {
  const { trackEvent } = useTracking();

  return (
    <ul className={styles.todoList}>
      {props.steps.map((step) => {
        const Text = stepText(step);
        if (!Text) {
          return null;
        }
        return (
          <li key={step.id} className={styles.todoListItem}>
            <StepButton
              disabled={step.disabled}
              completed={step.completed}
              onClick={() => {
                trackEvent(appointmentEvents.completeProfileStepClick(step.id));
                step.goTo(props.slot.slotId);
              }}
            >
              <Text />
            </StepButton>
          </li>
        );
      })}
    </ul>
  );
}

function Label(props: PropsWithChildren) {
  return (
    <span className={styles.label}>
      <Squircle className={styles.labelMarker} />
      <Typography variant="eyebrow-s">{props.children}</Typography>
    </span>
  );
}
