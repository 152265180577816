import { DateTime } from "luxon";

export function DateOfBirth({
  date,
  locale,
}: {
  date: DateTime;
  locale?: string;
}) {
  return (
    <>
      {date.toLocaleString(
        { ...DateTime.DATE_SHORT, day: "2-digit", month: "2-digit" },
        { locale }
      )}
    </>
  );
}
