import { Button, Typography } from "@cur8/maneki";
import { Slot } from "@cur8/rich-entity";
import { ReactComponent as ArrowIcon } from "assets/icons/24x24/24x24_arrow-right.svg";
import { useVisitPrerequisites } from "render/hooks/api/useVisitPrerequisites";
import { useTracking } from "render/hooks/useTracking";
import { appointmentEvents } from "../../tracking";
import { Trans } from "../../trans";
import styles from "./styles.module.sass";

interface CompleteProfileButtonProps {
  slot: Slot;
  visitId: string;
}

export function CompleteProfileButton({
  slot,
  visitId,
}: CompleteProfileButtonProps) {
  const { trackEvent } = useTracking();
  const { isReady, getNextStep, hasLoaded } = useVisitPrerequisites({
    origin: "appointment",
    visitId,
  });

  if (!hasLoaded || isReady) {
    return null;
  }

  return (
    <span className={styles.buttonWrapper}>
      <Button
        onPress={() => {
          trackEvent(appointmentEvents.completeProfileClick());
          getNextStep()?.(slot.slotId);
        }}
        icon={<ArrowIcon />}
      >
        <Typography variant="label-m">
          <Trans.CompleteProfileButton.Title />
        </Typography>
      </Button>
    </span>
  );
}
