import { useMutation } from "@tanstack/react-query";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";

export function useMarkAsSeenMutation() {
  const { patientId } = useSession();
  const api = useAPIClient().immutableVisitSummary;

  return useMutation({
    mutationFn({ notificationId, visitId }: Variables) {
      if (!notificationId) {
        return api.markSummaryAsSeen({ patientId, visitId }).result;
      }

      return api.markFollowUpMessageAsSeen({
        notificationId,
        patientId,
        visitId,
      }).result;
    },
  });
}

type Variables = {
  notificationId: string | undefined;
  visitId: string;
};
