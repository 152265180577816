import { Fragment, ReactNode } from "react";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { QueryLink } from "render/routes/QueryLink";
import { queries } from "render/routes/paths";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useRiskMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { MetricLoading, WeightMetric } from "../../layouts/Metric";
import { changeLabel, weightValue } from "./lib";
import { Trans } from "./trans";

export function MetricWeight() {
  const patientQuery = usePatientQuery();
  const weightMetricQuery = useRiskMetric("body.weight");

  if (!patientQuery.data || weightMetricQuery.isPending) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (!weightMetricQuery.data) {
    return null;
  }

  const { cur: current, prev: previous } = getMetricHistory(
    weightMetricQuery.data
  );

  const diff =
    current && previous
      ? current.unit.kilograms - previous.unit.kilograms
      : undefined;

  let note: undefined | ReactNode[];
  if (diff != null) {
    note = [
      changeLabel({ diff: { kilograms: diff } }),
      weightValue({
        patient: patientQuery.data,
        value: { kilograms: diff },
      }).map(([weightValue, weightUnit], index) => (
        <Fragment key={index}>
          {
            // For multi-unit values, such as "12 st 2 lbs",
            // we need to insert spacing between each value + unit pair
            index > 0 && <>&nbsp;</>
          }
          {weightValue}&nbsp;{weightUnit}
        </Fragment>
      )),
    ];
  }

  return (
    <QueryLink query={queries.metric} params={{ metric: ["weight"] }}>
      <WeightMetric
        label={<Trans.MetricName />}
        description={weightValue({
          patient: patientQuery.data,
          value: current?.unit,
        })}
        note={note}
      />
    </QueryLink>
  );
}
