import { localize } from "render/context/InternationalizationContext";

const WeekdayFirstLetter = {
  Friday: localize({
    en_GB: <>F</>,
    sv_SE: <>F</>,
  }),
  Monday: localize({
    en_GB: <>M</>,
    sv_SE: <>M</>,
  }),
  Saturday: localize({
    en_GB: <>S</>,
    sv_SE: <>L</>,
  }),
  Sunday: localize({
    en_GB: <>S</>,
    sv_SE: <>S</>,
  }),
  Thursday: localize({
    en_GB: <>T</>,
    sv_SE: <>T</>,
  }),
  Tuesday: localize({
    en_GB: <>T</>,
    sv_SE: <>T</>,
  }),
  Wednesday: localize({
    en_GB: <>W</>,
    sv_SE: <>O</>,
  }),
};

export const Trans = { WeekdayFirstLetter };
