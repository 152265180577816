import { VisitPrerequisiteStepId } from "render/hooks/api/useVisitPrerequisites";
import { Action, Event } from "render/hooks/useTracking";

const category = "AppointmentView";

export const appointmentEvents = {
  completeProfileClick: (): Event => {
    return {
      action: Action.Click,
      category,
      label: "complete_profile",
    };
  },

  completeProfileStepClick: (step: VisitPrerequisiteStepId): Event => {
    return {
      action: Action.Click,
      category,
      label: "complete_profile_click",
      value: step,
    };
  },
};
