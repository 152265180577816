import { useNav, useQueryParams } from "@pomle/react-router-paths";
import { ReactComponent as QuestionIcon } from "assets/icons/24x24/24x24_chat.svg";
import { ReactComponent as CrossIcon } from "assets/icons/24x24/24x24_close.svg";
import { splitSlotId } from "lib/visit/splitSlotId";
import { useRef } from "react";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { useSlotQuery } from "render/hooks/api/queries/useSlotQuery";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { paths, queries } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { BottomSheet } from "render/ui/presentation/BottomSheet";
import { IconButton } from "render/ui/trigger/IconButton";
import { AddToCalendar } from "render/views/appointment/AppointmentView/components/AppointmentInfoSection/components/AddToCalendar";
import { AppointmentInfoSection } from "./components/AppointmentInfoSection";

interface AppointmentViewProps {
  slotId: string;
}

export function AppointmentView({ slotId }: AppointmentViewProps) {
  const { visitId } = splitSlotId(slotId);
  const { data: slot } = useSlotQuery({ slotId });
  const { data: patient } = usePatientQuery();
  const nav = {
    appointment: useNav(paths.appointment),
    root: useNav(paths.root),
  };
  const contactUsPopup = useContactUsPopup();
  const scrollingElement = useRef<HTMLDivElement>(null);
  const [{ calendarVisible }] = useQueryParams(queries.appointment);

  return (
    <>
      <FullScreenPageLayout background="porcelain" ref={scrollingElement}>
        <div>
          <LogoHeader
            sticky
            leftElement={
              <IconButton onClick={nav.root.on({})} icon={<CrossIcon />} />
            }
            rightElement={
              <IconButton
                icon={<QuestionIcon display="block" />}
                onClick={contactUsPopup.emit}
              />
            }
          />
          {slot && patient && (
            <AppointmentInfoSection
              slot={slot}
              patient={patient}
              visitId={visitId}
              scrollingElement={scrollingElement}
            />
          )}
        </div>
      </FullScreenPageLayout>
      <BottomSheet
        isActive={calendarVisible.at(0) ?? false}
        onOutsideClick={nav.appointment.on({ slotId })}
      >
        <AddToCalendar slotId={slotId} />
      </BottomSheet>
    </>
  );
}
