import { QueryLink } from "render/routes/QueryLink";
import { queries } from "render/routes/paths";
import { useBMIValues } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBMI/useBMIValues";
import { Metric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

export function MetricBMI() {
  const values = useBMIValues({ scanNum: undefined });

  if (values === "loading") {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (values == null) {
    return null;
  }

  const { value, previousValue, metricRating } = values;

  return (
    <QueryLink query={queries.metric} params={{ metric: ["bmi"] }}>
      <Metric
        label={<Trans.MetricName />}
        value={value}
        metricRating={metricRating}
        description={<Trans.MetricUnit />}
        previousValue={previousValue}
      />
    </QueryLink>
  );
}
