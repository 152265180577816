import { MultiChoiceQuestion } from "@cur8/questionnaire";
import { Trans } from "./trans";

export enum HealthConditionsEnum {
  Athritis = "Arthritis",
  KidneyDisease = "Kidney disease",
  PeripheralVascularDisease = "Peripheral Vascular Disease",
  RaynaudsSyndrome = "Raynaud’s Syndrome",
}

export const HealthConditions = new MultiChoiceQuestion(
  <Trans.HealthConditions.Question />,
  [
    [
      {
        description: <Trans.HealthConditions.ArthritisDescription />,
        label: <Trans.HealthConditions.Arthritis />,
      },
      HealthConditionsEnum.Athritis,
    ],
    [
      {
        description: <Trans.HealthConditions.KidneyDiseaseDescription />,
        label: <Trans.HealthConditions.KidneyDisease />,
      },
      HealthConditionsEnum.KidneyDisease,
    ],
    [
      {
        description: (
          <Trans.HealthConditions.PeripheralVascularDiseaseDescription />
        ),
        label: <Trans.HealthConditions.PeripheralVascularDisease />,
      },
      HealthConditionsEnum.PeripheralVascularDisease,
    ],
    [
      {
        description: <Trans.HealthConditions.RaynaudsSyndromeDescription />,
        label: <Trans.HealthConditions.RaynaudsSyndrome />,
      },
      HealthConditionsEnum.RaynaudsSyndrome,
    ],
  ]
);
