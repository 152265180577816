import { useMediaQuery } from "render/hooks/useMediaQuery";
import { type Breakpoint, breakpoints } from "render/ui/style/breakpoints";

/**
 * Returns `true` when the viewport is the specified size or larger,
 * or `false` when it's smaller.
 */
export function useIsViewportAtLeast(size: Breakpoint) {
  return useMediaQuery(`(min-width: ${breakpoints[size]})`);
}
