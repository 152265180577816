import { Link, Typography } from "@cur8/maneki";
import { Slot } from "@cur8/rich-entity";
import { Address } from "@cur8/rich-entity/dist/types/Address";
import { PathLink, useNav } from "@pomle/react-router-paths";
import { ReactComponent as CloseIcon } from "assets/icons/24x24/24x24_close.svg";
import { DateTime } from "luxon";
import { useSlotQuery } from "render/hooks/api/queries/useSlotQuery";
import { paths } from "render/routes/paths";
import styles from "./AddToCalendar.module.sass";
import * as Trans from "./trans";

type AddToCalendarProps = {
  slotId: string;
};

export function AddToCalendar(props: AddToCalendarProps) {
  const { data: slot } = useSlotQuery({ slotId: props.slotId });
  const nav = {
    appointment: useNav(paths.appointment),
  };
  if (!slot) {
    return null;
  }

  const cancelTo = nav.appointment.to({ slotId: props.slotId });
  const links = generateLinks(slotToEvent(slot, cancelTo));

  return (
    <div className={styles.AddToCalendar}>
      <div className={styles.header}>
        <Typography variant="title-m">
          <Trans.Title />
        </Typography>
        <PathLink to={cancelTo} className={styles.hideMobile}>
          <CloseIcon />
        </PathLink>
      </div>
      <div className={styles.buttons}>
        {links.map(({ key, name, props }) => (
          <Link {...props} key={key} target="_blank" variant="secondary">
            <Typography variant="label-m" color="default">
              {name}
            </Typography>
          </Link>
        ))}
      </div>
      <span className={styles.hideDesktop}>
        <Link href={cancelTo} size="large" variant="tertiary">
          <Trans.Cancel />
        </Link>
      </span>
    </div>
  );
}

function formatAddress(address: Address | undefined) {
  if (!address) {
    return "";
  }
  return `${address.street}, ${address.city}`;
}

type Event = {
  address: string;
  description: string;
  end: DateTime;
  start: DateTime;
  timeZone: string;
  title: string;
  url: string;
};

function slotToEvent(slot: Slot, visitPath: string): Event {
  return {
    address: formatAddress(slot.room?.site?.address),
    description: "",
    end: slot.endTime,
    start: slot.startTime.minus({ minutes: 10 }),
    timeZone: slot.room?.site?.timeZoneId ?? slot.startTime.zoneName,
    title: "Neko Body Scan",
    url: `${window.location.origin}${visitPath}`,
  };
}

function formatTime(date: DateTime) {
  return date.toUTC().toISO({ format: "basic", suppressMilliseconds: true });
}

function generateLinks(event: Event) {
  return [
    {
      key: "Apple",
      name: "Apple",
      props: ics(event),
    },
    {
      key: "Google",
      name: "Google",
      props: google(event),
    },
    {
      key: "Microsoft 365",
      name: (
        <span className={styles.outlook}>
          <span>Outlook</span>
          <span>(Work or school account)</span>
        </span>
      ),
      props: outlook(event, "office"),
    },
    {
      key: "Outlook.com",
      name: (
        <span className={styles.outlook}>
          <span>Outlook or Hotmail</span>
          <span>(Personal account)</span>
        </span>
      ),
      props: outlook(event, "live"),
    },
  ];
}

function google(event: Event) {
  const startTime = formatTime(event.start);
  const endTime = formatTime(event.end);

  // https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/google.md
  const params = new URLSearchParams({
    action: "TEMPLATE",
    ctz: event.timeZone,
    dates: `${startTime}/${endTime}`,
    details: event.description,
    location: event.address,
    text: event.title,
  });

  const href = `https://www.google.com/calendar/render?${params}`;

  return { href: href };
}

function outlook(event: Event, type: "live" | "office") {
  const startTime = event.start.toUTC().toISO();
  const endTime = event.end.toUTC().toISO();

  // https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/outlook-web.md
  const params = new URLSearchParams({
    body: event.description,
    enddt: endTime,
    location: event.address,
    path: "/calendar/action/compose",
    rru: "addevent",
    startdt: startTime,
    subject: event.title,
  });

  const href = `https://outlook.${type}.com/calendar/deeplink/compose?${params}`;

  return { href };
}

function ics(event: Event) {
  const startTime = formatTime(event.start);
  const endTime = formatTime(event.end);

  const href = encodeURI(
    `data:text/calendar;charset=utf-8,BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
URL:${event.url}
DTSTART:${startTime}
DTEND:${endTime}
SUMMARY:${event.title}
DESCRIPTION:${event.description}
LOCATION:${event.address}
END:VEVENT
END:VCALENDAR`
  );
  return { download: "event.ics", href };
}
