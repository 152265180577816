import { Typography } from "@cur8/maneki";
import { useDoctorProfileQuery } from "render/hooks/api/queries/useDoctorProfileQuery";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface DoctorCredentialsProps {
  doctorURI: string;
}

export function DoctorCredentials({ doctorURI }: DoctorCredentialsProps) {
  const doctorQuery = useDoctorProfileQuery({ doctorURI });

  const name = doctorQuery.data?.doctor.displayName ?? "";

  return (
    <div className={styles.DoctorCredentials}>
      <DoctorImage name={name} photo={doctorQuery.data?.photo} />
      <div>
        <Typography variant="label-s">
          <Trans.Name name={name} />
        </Typography>
        <Typography variant="body-s" color="subtle">
          <Trans.Subtitle />
        </Typography>
      </div>
    </div>
  );
}

type DoctorImageProps = {
  name: string;
  photo: Blob | undefined;
};

function DoctorImage({ name, photo }: DoctorImageProps) {
  if (!photo) {
    return <div className={styles.image}>{getInitials(name)}</div>;
  }

  return (
    <img
      className={styles.image}
      alt={`Dr ${name}`}
      src={URL.createObjectURL(photo)}
    />
  );
}

function getInitials(name: string) {
  return name
    .trim()
    .split(" ")
    .filter((_, i, a) => !i || i === a.length - 1)
    .map(([f]) => f)
    .join("");
}
