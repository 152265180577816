import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>BMI</>,
  sv_SE: <>BMI</>,
});

const MetricUnit = localize({
  en_GB: <>kg/m2</>,
  sv_SE: <>kg/m2</>,
});

const FAQ = {
  Aim: localize({
    en_GB: (
      <>
        Aiming for a healthy weight range is ideal, but this varies depending on
        height, age, gender, and body composition. Generally, maintaining a BMI
        within the 18.5 to 24.9 range is considered healthy, but you also need
        to consider individual factors such as your build and overall health.
        Lifestyle choices focusing on balanced nutrition and regular physical
        activity are key, rather than fixating on a specific number on the
        scale.
      </>
    ),
    sv_SE: (
      <>
        Att sikta på ett hälsosamt viktintervall är idealiskt, men detta
        varierar beroende på längd, ålder, kön och kroppssammansättning.
        Generellt sett anses ett BMI inom intervallet 18,5 till 24,9 vara
        hälsosamt, men du måste också ta hänsyn till individuella faktorer som
        din kroppsbyggnad och din allmänna hälsa. Livsstilsval med fokus på
        balanserad kost och regelbunden fysisk aktivitet är nyckeln, snarare än
        att fixera vid ett specifikt nummer på skalan.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        You can get an idea of whether you are within your ideal weight range by
        looking at your body mass index (BMI). If your BMI is 25 or more then
        this suggests you may be overweight, and if it is 30 or more then this
        suggests you may be obese. BMI is not perfect at working out whether
        your weight is in the ideal range. People who have a lot of muscle can
        have a high BMI and be perfectly healthy, because the extra weight is
        muscle, not fat.
      </>
    ),
    sv_SE: (
      <>
        Du kan få en uppfattning om du ligger inom ditt idealviktsintervall
        genom att titta på ditt kroppsmasseindex (BMI). Om ditt BMI är 25 eller
        mer så tyder det på att du kan vara överviktig, och om det är 30 eller
        mer så tyder det på obesitas. BMI är inte perfekt för att räkna ut om
        din vikt är i det ideala intervallet. Människor som har mycket muskler
        kan ha ett högt BMI och ha en hälsosam kroppskomposition, eftersom den
        extra vikten består av muskler och inte fett.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        A low weight might suggest that you are not eating enough food to supply
        your body with nutrients, or that your bowels are not absorbing
        nutrients efficiently. It can also be a sign of an underlying medical
        condition, especially if the weight loss is rapid or unexplained. Being
        underweight can increase the risk of issues like bone loss, immune
        system deficiencies, and fertility problems. It's essential to identify
        the cause of low weight and address any underlying health issues.
      </>
    ),
    sv_SE: (
      <>
        En låg vikt kan tyda på att du inte äter tillräckligt med mat för att
        förse din kropp med näringsämnen, eller att dina tarmar inte absorberar
        näringsämnen effektivt. Det kan också vara ett tecken på ett
        underliggande medicinskt tillstånd, särskilt om viktminskningen är snabb
        eller oförklarlig. Att vara underviktig ökar även risken för benskörhet,
        immunförsvarsbrister och fertilitetsproblem. Det är viktigt att
        identifiera orsaken till låg vikt och åtgärda eventuella underliggande
        hälsoproblem.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        Tracking weight trends is important. Gradual weight gain might indicate
        a need for lifestyle changes, such as eating a healthier diet or
        increasing your physical activity. Conversely, unexpected weight loss
        might warrant an appointment with your doctor to rule out underlying
        health issues. It's the pattern and context of weight change that often
        provides more insight than a single measurement.
      </>
    ),
    sv_SE: (
      <>
        Det är viktigt att spåra vikttrender. Gradvis viktökning kan tyda på ett
        behov av livsstilsförändringar, som att äta en hälsosammare kost eller
        öka din fysiska aktivitet. Oväntad viktminskning kan motivera ett möte
        med din läkare för att utesluta underliggande hälsoproblem. Det är
        mönstret och sammanhanget för viktförändringar som ofta ger mer insikt
        än en enskild mätning.
      </>
    ),
  }),
  Why: localize({
    en_GB: (
      <>
        Your weight helps in understanding your nutritional status and can be a
        key indicator of overall health. Regularly monitoring weight helps to
        detect changes that might be signs of health issues, it's also useful in
        managing conditions like obesity, heart disease, and diabetes.
      </>
    ),
    sv_SE: (
      <>
        Din vikt hjälper till att förstå din näringsstatus och kan vara en
        nyckelindikator för din allmänna hälsa. Regelbunden övervakning av
        vikten hjälper till att upptäcka förändringar som kan vara tecken på
        hälsoproblem. Det är också användbart för att hantera tillstånd som
        fetma, hjärtsjukdomar och diabetes.
      </>
    ),
  }),
};

export const Trans = { FAQ, MetricName, MetricUnit };
