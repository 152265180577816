import { useQueryParams } from "@pomle/react-router-paths";
import { queries } from "render/routes/paths";
import { MetricDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Details";
import { useABIValues } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricABI/useABIValues";
import { useRiskMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { defaultSide } from "../../lib/defaultSide";
import { Trans } from "./trans";

export function MetricABIDetails() {
  const [params, setParams] = useQueryParams(queries.metric);
  const { data: left } = useRiskMetric("cardio.abi.left");
  const { data: right } = useRiskMetric("cardio.abi.right");

  const side = params.side[0] ?? defaultSide({ left, right }, (m) => m?.ratio);
  const numOfScansLeft = left?.length || 1;
  const numOfScansRight = right?.length || 1;
  const numOfScans = side === "left" ? numOfScansLeft : numOfScansRight;

  const scanNum = params.scan[0] || numOfScans;
  const setScanNum = (num: number) =>
    setParams({
      scan: [num],
    });
  const setSide = (side: "left" | "right") =>
    setParams({
      side: [side],
    });
  const values = useABIValues({ scanNum, side });

  return (
    <MetricDetails
      label={<Trans.MetricName />}
      values={values}
      scanNum={scanNum}
      setScanNum={setScanNum}
      side={side}
      setSide={setSide}
      numOfScans={numOfScans}
      FAQText={Trans.FAQ}
    />
  );
}
