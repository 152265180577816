import {
  ApplicationInsights,
  DistributedTracingModes,
} from "@microsoft/applicationinsights-web";
import type { Config } from "auth/config";

export async function getInsights(config: Config) {
  const appInsights = new ApplicationInsights({
    config: {
      autoTrackPageVisitTime: false,

      connectionString: config.appInsights.connectionString,

      // we need to prevent adding traceparent header field to external requests
      disableAjaxTracking: true,
      disableFetchTracking: false,
      distributedTracingMode: DistributedTracingModes.W3C,
      enableAutoRouteTracking: false,
      enableCorsCorrelation: true,
      enableUnhandledPromiseRejectionTracking: true,
    },
  });
  appInsights.loadAppInsights();
  return appInsights;
}
