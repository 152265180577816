import { decodeToken, isTokenV1 } from "@cur8/frango";
import { useEffect, useMemo } from "react";
import {
  SeverityLevel,
  useAppInsights,
} from "render/context/AppInsightsContext";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { ConciergeCheckInSessionView } from "./components/ConciergeCheckInSessionView";
import { CheckInFailView } from "./components/ConciergeCheckInSessionView/components/CheckInFailView";
import { Trans } from "./trans";

interface ConciergeCheckInViewProps {
  sessionId: string;
}

function ConciergeCheckContent({
  token,
}: {
  token: ReturnType<typeof decodeToken> | null;
}) {
  const { trackException } = useAppInsights();

  useEffect(() => {
    if (!token || isTokenV1(token)) {
      return;
    }
    trackException({
      exception: new Error("Invalid Token"),
      severityLevel: SeverityLevel.Warning,
    });
  }, [trackException, token]);

  if (!token) {
    return (
      <CheckInFailView
        Header={<Trans.TokenInvalid.Header />}
        Text={<Trans.TokenInvalid.Description />}
      />
    );
  }
  if (isTokenV1(token)) {
    return <ConciergeCheckInSessionView sessionId={token.sessionId} />;
  }

  return (
    <CheckInFailView
      Header={<Trans.SessionVersionNotSupported.Header />}
      Text={<Trans.SessionVersionNotSupported.Description />}
    />
  );
}

export function ConciergeCheckInView({ sessionId }: ConciergeCheckInViewProps) {
  const appInsights = useAppInsights();

  const token = useMemo(() => {
    try {
      return decodeToken(sessionId);
    } catch (err) {
      const error =
        err instanceof Error
          ? err
          : new Error("unknown error decoding concierge session token");
      appInsights.trackException({
        error,
        exception: error,
        severityLevel: SeverityLevel.Error,
      });

      return null;
    }
  }, [sessionId, appInsights]);

  return (
    <FullScreenPageLayout>
      <ConciergeCheckContent token={token} />
    </FullScreenPageLayout>
  );
}
