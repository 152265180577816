import { Sticky } from "@pomle/react-viewstack";
import { type ReactNode } from "react";
import { FocusScope } from "react-aria";
import { createPortal } from "react-dom";
import { OpaqueLayer } from "render/ui/presentation/OpaqueLayer";
import styles from "./styles.module.sass";

type Props = {
  children?: ReactNode;
  isActive: boolean | undefined;
  onOutsideClick: () => void;
};

export function BottomSheet({ children, isActive, onOutsideClick }: Props) {
  return createPortal(
    <OpaqueLayer
      isActive={!!isActive}
      variant="dark"
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          onOutsideClick();
        }
      }}
    >
      <div className={styles.bottomSheet} data-active={isActive}>
        <Sticky>
          {isActive && (
            <FocusScope autoFocus contain>
              {/* capture focus on invisible element so there is no initial focus ring */}
              <button aria-hidden className={styles.focusItem} />
              {children}
            </FocusScope>
          )}
        </Sticky>
      </div>
    </OpaqueLayer>,
    document.body
  );
}
