import { FLAGS, Flags } from "lib/flags";

export function FollowUpInviteView() {
  const hasFollowUpBookingFlag = FLAGS.has(Flags.FollowUpBooking);

  if (!hasFollowUpBookingFlag) {
    return null;
  }

  return (
    <div>
      <h1>FollowUpInviteView</h1>
    </div>
  );
}
