import { localeToISO639Code } from "lib/i18n/localeToISO639Code";
import { Cardiovascular } from "lib/questionnaire/question-flows/onboarding/questions/cardiovascular";
import { Pregnant } from "lib/questionnaire/question-flows/onboarding/questions/pregnant";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { useLocale } from "render/context/InternationalizationContext";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function PregnantStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(Pregnant);
  const label = TransQuestions.Pregnant.Question();
  const { englishLabel } = TransQuestions.Pregnant.Question;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={Pregnant}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(Pregnant);
      }}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.PregnantExplanation />,
        openText: <Trans.General.WhatDoesThisMean />,
      }}
    >
      <ChoiceInput
        question={Pregnant}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(Pregnant, {
            answer,
            questionAnswer: {
              answer: {
                $type: "stringenum",
                value: Cardiovascular.getValue(answer) || "",
              },
              englishLabel,
              label,
              languageCode,
              questionId: "pregnant",
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[]) {
  steps.push(PregnantStep);
}
