import { Typography } from "@cur8/maneki";
import { useSession } from "render/context/MSALContext/MSALContext";
import { useFileQuery } from "render/hooks/api/queries/useFileQuery";
import { Button } from "render/ui/trigger/Button";
import { Card } from "render/ui/trigger/Card";
import { CopyToClipboard } from "render/views/ProfileView/components/DataAndPrivacySection/components/CopyToClipboard";
import { Download } from "./Download";
import { FileSizeBadge } from "./FileSizeBadge";
import styles from "./styles.module.sass";
import * as Trans from "./Trans";

type CompletedProps = {
  downloadUri?: string;
  onCreate?: () => void;
  path: string;
  requestedOn: string;
};

export function Completed({
  downloadUri,
  onCreate,
  path,
  requestedOn,
}: CompletedProps) {
  const { patientId } = useSession();
  const { data } = useFileQuery(path);

  return (
    <>
      <Card>
        <div className={styles.header}>
          <Typography variant="title-m">
            <Trans.DataRequest />
          </Typography>
          <FileSizeBadge size={data?.size} />
        </div>
        <div className={styles.content}>
          <Typography variant="eyebrow-m" color="subtle">
            <Trans.DecryptionPassword />
          </Typography>
          <CopyToClipboard>{patientId}</CopyToClipboard>
          <Typography variant="body-m" color="subtle">
            <Trans.Decrypt />
          </Typography>
        </div>
        <Typography variant="eyebrow-m" color="subtle">
          <Trans.RequestedOn date={requestedOn} />
        </Typography>
        <Download getFile={data?.getFile} uri={downloadUri} />
      </Card>
      <Button variant="outlined" onClick={onCreate}>
        <Typography variant="label-m">
          <Trans.CreateNewFiles />
        </Typography>
      </Button>
    </>
  );
}
