import { localize } from "render/context/InternationalizationContext";

const Tasks = localize<{ count: number }>({
  en_GB: ({ count }) => {
    if (count === 1) {
      return <>1 task</>;
    }
    return <>{count} tasks</>;
  },
  sv_SE: ({ count }) => {
    if (count === 1) {
      return <>1 påminnelse</>;
    }
    return <>{count} påminnelser</>;
  },
});

const Title = {
  NotReady: {
    Header: localize({
      en_GB: <>How to prepare</>,
      sv_SE: <>Inför ditt besök</>,
    }),
    SubHeader: localize({
      en_GB: (
        <>
          Complete these steps before your scan to ensure a seamless check-in
          process at the clinic.
        </>
      ),
      sv_SE: (
        <>
          Slutför dessa steg innan din scan för en smidig incheckning på
          kliniken.
        </>
      ),
    }),
  },
  Ready: {
    Header: localize({
      en_GB: <>You're all set</>,
      sv_SE: <>Du är redo</>,
    }),
  },
};

const TodoList = {
  Todo: {
    CompleteOnboarding: localize({
      en_GB: <>Answer health questions</>,
      sv_SE: <>Fyll i din hälsoprofil </>,
    }),
    IdentityVerification: localize({
      en_GB: <>Verify your identity</>,
      sv_SE: <>Verifiera din identitet</>,
    }),
    Passkey: localize({
      en_GB: <>Setup secure Passkey</>,
      sv_SE: <>Lägg till Passkey</>,
    }),
    RenewStudies: localize({
      en_GB: <>Agree to clinical studies</>,
      sv_SE: <>Granska samtycken</>,
    }),
  },
};

export const Trans = { Tasks, Title, TodoList };
