import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: "Language",
  sv_SE: "Önskat språk",
});
const Subtitle = localize({
  en_GB: "Select your preferred language for our Medical Team to use with you.",
  sv_SE:
    "Välj det språk som du föredrar att vårt medicinska team kommunicerar med dig på.",
});

const Actions = {
  Save: localize({
    en_GB: "Save",
    sv_SE: "Spara",
  }),
  Saving: localize({
    en_GB: "Saving...",
    sv_SE: "Sparar...",
  }),
};

export const Trans = {
  Actions,
  Subtitle,
  Title,
};
