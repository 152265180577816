import { QueryLink } from "render/routes/QueryLink";
import { queries } from "render/routes/paths";
import { useBloodPressureValues } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodPressure/useBloodPressureValues";
import { getHighestSide } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getHighestSide";
import { Metric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

export function MetricBloodPressure() {
  const left = useBloodPressureValues({ side: "left" });
  const right = useBloodPressureValues({ side: "right" });

  if (left === "loading" || right === "loading") {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  const highestSide = getHighestSide({
    left: left.value,
    right: right.value,
  });
  const highestValue = highestSide === "left" ? left : right;

  return (
    <QueryLink query={queries.metric} params={{ metric: ["blood-pressure"] }}>
      <Metric
        label={<Trans.MetricName />}
        value={highestValue?.value}
        description={highestValue?.subLabel}
        metricRating={highestValue?.metricRating}
        previousValue={highestValue?.previousValue}
      />
    </QueryLink>
  );
}
