import { useEffect, useState } from "react";

export function useTimeElapsed(miliseconds: number) {
  const [elapsed, setElapsed] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setElapsed(true), miliseconds);
    return () => {
      clearTimeout(timeout);
      setElapsed(false);
    };
  }, [miliseconds]);

  return elapsed;
}
