import { AggregatedBloodPressureData } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { DateTime, Interval } from "luxon";
import { useCallback, useEffect, useMemo, useRef } from "react";
import styles from "./styles.module.sass";

interface DayScrollProps {
  measurements: AggregatedBloodPressureData[] | undefined;
  selected: DateTime;
  interval: Interval;
  onSelect: (day: DateTime) => void;
  mode: "dark" | "light";
}

export function DayScroll({
  selected,
  interval,
  onSelect,
  mode,
  measurements,
}: DayScrollProps) {
  const measurementsTable = useMemo(() => {
    const result = new Map<string, AggregatedBloodPressureData>();

    measurements?.forEach((m) => {
      if (!m.measuredAt) {
        return;
      }
      result.set(m.measuredAt.startOf("day").toISODate(), m);
    });

    return result;
  }, [measurements]);
  const hasData = useCallback(
    (targetISO: string) => measurementsTable.has(targetISO),
    [measurementsTable]
  );
  const selectedDay = useMemo(() => selected.toISODate(), [selected]);
  const initialDay = useRef(selectedDay);
  const days = useMemo(
    () => interval.splitBy({ day: 1 }).map(({ start }) => start),
    [interval]
  );
  const items = useRef<Map<string, HTMLButtonElement | null>>(new Map());
  const containerRef = useRef<HTMLDivElement>(null);
  const isCurrentlySelected = useCallback(
    (isoDate: string) => isoDate === selectedDay,
    [selectedDay]
  );
  const selectDay = useCallback(
    (day: DateTime) => () => onSelect(day),
    [onSelect]
  );
  const calculateScroll = useCallback(
    (
      container: { width: number },
      target: { width: number; offsetLeft: number }
    ) => {
      const center = (container.width - target.width) / 2;

      return (center - target.offsetLeft) * -1;
    },
    []
  );

  const scrollTo = useCallback(
    (
      elem: HTMLButtonElement | undefined | null,
      behavior: "auto" | "smooth" = "auto"
    ) => {
      if (elem == null) {
        return;
      }

      if (!containerRef.current) {
        return;
      }
      containerRef.current.scrollTo({
        behavior,
        left: calculateScroll(
          { width: containerRef.current.clientWidth },
          { offsetLeft: elem.offsetLeft, width: elem.clientWidth }
        ),
      });
    },
    [calculateScroll]
  );

  useEffect(
    () => scrollTo(items.current.get(initialDay.current), "auto"),
    [scrollTo]
  );

  useEffect(
    () => scrollTo(items.current.get(selectedDay), "smooth"),
    [selectedDay, scrollTo]
  );

  return (
    <div
      ref={containerRef}
      className={classNames(styles.DayScroll, {
        [styles.dark]: mode === "dark",
        [styles.light]: mode === "light",
      })}
    >
      {days.map((day) => {
        const date = day.toISODate();
        const disabled = !hasData(date);

        return (
          <button
            ref={(elm) => items.current.set(date, elm)}
            onClick={selectDay(day)}
            className={classNames({
              [styles.selected]: isCurrentlySelected(date),
              [styles.available]: !isCurrentlySelected(date),
            })}
            disabled={disabled}
            key={date}
          >
            {day.weekdayShort.slice(0, 1)}
          </button>
        );
      })}
    </div>
  );
}
