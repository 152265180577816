import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: <>Summary</>,
  sv_SE: <>Summering</>,
});

const LastUpdateOn = localize<{ date: JSX.Element }>({
  en_GB: ({ date }) => <>Last update on {date}</>,
  sv_SE: ({ date }) => <>Senast uppdaterad {date}</>,
});

const FollowUpBeingPrepared = localize<{ onClick: () => void }>({
  en_GB: ({ onClick }) => (
    <>
      Your doctor is preparing your visit summary, we will send you a text
      message as soon as it's done. In the meantime you can view your scan data{" "}
      <button onClick={onClick}>here.</button>
    </>
  ),
  sv_SE: ({ onClick }) => (
    <>
      Din läkare förbereder din besökssammanfattning, vi skickar ett sms till
      dig så fort det är klart. Under tiden kan du se dina scan data{" "}
      <button onClick={onClick}>här.</button>
    </>
  ),
});

const ScanData = localize({
  en_GB: "Scan data",
  sv_SE: "Scan data",
});

const ReportHistory = localize({
  en_GB: "Report history",
  sv_SE: "Tidigare summeringar",
});

export const Trans = {
  FollowUpBeingPrepared,
  LastUpdateOn,
  ReportHistory,
  ScanData,
  Title,
};
