import { APITypesV1 } from "@cur8/api-client";
import { Button, Typography } from "@cur8/maneki";
import { useState } from "react";
import { useSummaryFeedbackMutation } from "render/hooks/mutations/useSummaryFeedbackMutation";
import styles from "./styles.module.sass";
import * as Trans from "./trans";

export type FeedbackFormProps = {
  kind: "positive" | "negative" | undefined;
  visitId: string;
  onSkip?: () => void;
  onSubmit: () => void;
};

export function FeedbackForm({
  kind,
  onSkip,
  onSubmit,
  visitId,
}: FeedbackFormProps) {
  const { mutate } = useSummaryFeedbackMutation();
  const [canSubmit, setCanSubmit] = useState(false);

  function checkForm(form: HTMLFormElement) {
    if (form) {
      const formData = new FormData(form);
      setCanSubmit(formData.has("presetFeedback"));
    }
  }

  return (
    <form
      className={styles.form}
      ref={(form) => form && checkForm(form)}
      onChange={(event) => checkForm(event.currentTarget)}
      onSubmit={(event) => {
        event.preventDefault();
        const f = new FormData(event.currentTarget);
        const memberFeedback = {
          additionalFeedback: f.get("additionalFeedback") as string,
          presetFeedback: f.getAll(
            "presetFeedback"
          ) as APITypesV1.PresetFeedback[],
          valuable: kind === "positive",
        };
        mutate({ memberFeedback, visitId }, { onSuccess: () => onSubmit() });
      }}
    >
      <Typography as="h3" variant="title-m">
        <Trans.Why />
      </Typography>
      <div className={styles.tags}>
        {categories[`${kind}`].map(([category, Category]) => (
          // TODO: replace with maneki Filter Tag later
          <label key={category} className={styles.tag}>
            <input name="presetFeedback" type="checkbox" value={category} />
            <Typography variant="eyebrow-m">
              <Category />
            </Typography>
          </label>
        ))}
      </div>
      {/* TODO: replace with maneki Textarea later */}
      <label className={styles.textarea}>
        <Typography variant="eyebrow-m">
          <Trans.AdditionalFeedback />
        </Typography>
        <textarea name="additionalFeedback" rows={2} />
      </label>
      <Button state={!canSubmit ? "disabled" : undefined} type="submit">
        <Trans.Submit />
      </Button>
      {onSkip && (
        <Button data-self-center variant="tertiary" onPress={() => onSkip()}>
          <Trans.Skip />
        </Button>
      )}
    </form>
  );
}

const categories: Record<
  `${FeedbackFormProps["kind"]}`,
  [category: APITypesV1.PresetFeedback, Component: () => string][]
> = {
  negative: [
    [APITypesV1.PresetFeedback.TooLong, Trans.TooLong],
    [APITypesV1.PresetFeedback.HardToUnderstand, Trans.HardToUnderstand],
    [APITypesV1.PresetFeedback.MissingInfo, Trans.MissingInfo],
    [APITypesV1.PresetFeedback.Generic, Trans.Generic],
    [APITypesV1.PresetFeedback.Other, Trans.Other],
  ],
  positive: [
    [APITypesV1.PresetFeedback.Knowledgeable, Trans.Knowledgeable],
    [APITypesV1.PresetFeedback.Personal, Trans.Personal],
    [APITypesV1.PresetFeedback.EasyToUnderstand, Trans.EasyToUnderstand],
    [APITypesV1.PresetFeedback.Other, Trans.Other],
  ],
  undefined: [],
};
