import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useRiskMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

export function useNumOfBMIScans() {
  const { data } = useRiskMetric("body.weight");

  return data?.length || 1;
}

export function useBMIValues({ scanNum }: { scanNum: number | undefined }) {
  const { data: bmi, isLoading } = useRiskMetric("body.bmi");

  if (isLoading) {
    return "loading";
  }

  if (!bmi) {
    return null;
  }

  const { cur: curBmi, prev: prevBmi } = getMetricHistory(bmi, scanNum);

  const value = curBmi && Math.round(curBmi.unit["kg/m2"]);
  const previousValue = prevBmi && Math.round(prevBmi.unit["kg/m2"]);
  const { riskLevel } = curBmi?.riskRanges ?? {};

  return {
    metricRating: riskLevel,
    previousValue,
    ranges: undefined,
    scanDate: curBmi?.measurement.timestampStart,
    subLabel: <Trans.MetricUnit />,
    value,
  };
}
