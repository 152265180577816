import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>LDL</>,
  sv_SE: <>LDL</>,
});

const MetricUnit = localize({
  en_GB: <>mmol/L</>,
  sv_SE: <>mmol/L</>,
});

const FAQ = {
  Aim: localize({
    en_GB: (
      <>
        The target LDL level depends on your overall risk of heart disease. It
        is best if you aim to keep your LDL cholesterol as low as possible.
        Those with a higher risk of cardiovascular disease will need to aim for
        lower levels under the guidance of their doctor. Keeping LDL levels in
        check helps to maintain your heart health by preventing arterial plaque
        build-up.
      </>
    ),
    sv_SE: (
      <>
        Målnivån för LDL beror på din totala risk för hjärtsjukdom. Det är bäst
        om du strävar efter att hålla ditt LDL-kolesterol så lågt som möjligt.
        De som har en högre risk för hjärt-kärlsjukdom behöver sikta på lägre
        nivåer. Att hålla LDL-nivåerna under kontroll hjälper till att öka ditt
        skydd mot hjärt-kärlsjukdom och begränsa åderförkalkning.
      </>
    ),
  }),
  High: localize({
    en_GB: (
      <>
        Elevated levels of LDL cholesterol are a warning sign of an increased
        risk of cardiovascular diseases, including angina, heart attacks and
        strokes. High LDL levels can lead to the accumulation of cholesterol in
        your arteries, causing them to narrow and potentially block blood flow
        to the heart and brain.
      </>
    ),
    sv_SE: (
      <>
        Förhöjda nivåer av LDL-kolesterol är en varningssignal för en ökad risk
        för hjärt-kärlsjukdomar, inklusive kärlkramp, hjärtinfarkt och stroke.
        Höga LDL-nivåer kan leda till ansamling av kolesterol i dina artärer,
        vilket gör att de smalnar och potentiellt blockerar blodflödet till
        hjärtat och hjärnan.
      </>
    ),
  }),
  Low: localize({
    en_GB: (
      <>
        Low LDL cholesterol levels are generally beneficial and are associated
        with a lower risk of heart disease and stroke. It means there's less
        cholesterol available to deposit in the arteries and cause blockages.
      </>
    ),
    sv_SE: (
      <>
        Låga nivåer av LDL-kolesterol är generellt fördelaktiga och är
        förknippade med en lägre risk för hjärtsjukdomar och stroke. Det innebär
        att det finns mindre kolesterol tillgängligt att fastna i artärerna och
        orsaka förträngningar.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        Monitoring LDL levels over time can provide insights into the
        effectiveness of lifestyle changes or medications to reduce cholesterol
        levels. A decreasing trend in LDL levels usually indicates reduced risk
        for heart disease and stroke.
      </>
    ),
    sv_SE: (
      <>
        Att följa LDL-nivåer över tid kan ge insikter om effektiviteten av
        livsstilsförändringar eller läkemedel för att minska kolesterolnivåerna.
        En minskande trend i LDL-nivåer indikerar vanligtvis minskad risk för
        hjärtsjukdomar och stroke.
      </>
    ),
  }),
  Why: localize({
    en_GB: (
      <>
        LDL cholesterol, often called unhealthy cholesterol, is responsible for
        transporting cholesterol to cells throughout the body. It delivers
        cholesterol to tissues with high cholesterol requirements, such as the
        organs which make hormones like cortisol, oestradiol and testosterone.
        However, when levels are too high, it can lead to the build-up of
        plaques in your arteries, increasing the risk of heart disease and
        stroke. LDL cholesterol, along with other forms of unhealthy cholesterol
        such as IDL and VLDL, are known as non-HDL cholesterol.
      </>
    ),
    sv_SE: (
      <>
        LDL-kolesterol, ofta kallat ohälsosamt kolesterol, är ansvarigt för att
        transportera kolesterol till celler över hela kroppen. Det levererar
        kolesterol till vävnader med höga krav på kolesterol, såsom organen som
        producerar hormoner som kortisol, östradiol och testosteron. När
        nivåerna är för höga kan det leda till uppbyggnaden av plack i dina
        artärer, vilket ökar risken för hjärtsjukdomar och stroke.
        LDL-kolesterol, tillsammans med andra former av ohälsosamt kolesterol
        såsom IDL och VLDL, kallas non-HDL-kolesterol.
      </>
    ),
  }),
};

export const Trans = { FAQ, MetricName, MetricUnit };
