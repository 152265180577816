import { Action, Event } from "render/hooks/useTracking";

const category = "ShareBookingView";

export const shareBookingEvent = {
  continueClick: (): Event => {
    return {
      action: Action.Click,
      category,
      label: "continue_button",
    };
  },
  shareClick: (): Event => {
    return {
      action: Action.Click,
      category,
      label: "share_button",
    };
  },
};
