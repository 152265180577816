import { useEffect, useState } from "react";
import { useMarkAsSeenMutation } from "render/hooks/mutations/useMarkAsSeenMutation";
import { useTimeElapsed } from "render/hooks/useTimeElapsed";
import { ObserveIntersection } from "render/ui/trigger/ObserveIntersection";

const timeUntilSeen = 5000;

type MarkAsSeenProps = {
  notificationId: string | undefined;
  visitId: string;
};

export function MarkAsSeen({ notificationId, visitId }: MarkAsSeenProps) {
  const [seen, setSeen] = useState(false);
  const enoughTime = useTimeElapsed(timeUntilSeen);
  const { mutate: markAsSeen } = useMarkAsSeenMutation();

  useEffect(() => {
    if (enoughTime && seen) {
      markAsSeen({ notificationId, visitId });
    }
  }, [enoughTime, markAsSeen, notificationId, seen, visitId]);

  return <ObserveIntersection onIntersection={() => setSeen(true)} />;
}
