import { Button, Icon24Checkmark, Typography } from "@cur8/maneki";
import { useState } from "react";
import { useIsViewportAtLeast } from "render/hooks/useIsViewportAtLeast";
import { BottomSheet } from "render/ui/presentation/BottomSheet";
import { FeedbackForm, type FeedbackFormProps } from "./FeedbackForm";
import styles from "./styles.module.sass";
import * as trans from "./trans";

export type FeedbackProps = {
  visitId: string;
};

export function Feedback({ visitId }: FeedbackProps) {
  const [done, setDone] = useState(false);
  const [kind, setKind] = useState<FeedbackFormProps["kind"]>();
  const isBigScreen = useIsViewportAtLeast("md");

  if (done) {
    return (
      // TODO: replace with maneki Card later
      <div className={styles.card} data-variant="done">
        <Icon24Checkmark />
        <Typography variant="label-s">
          <trans.Thanks />
        </Typography>
      </div>
    );
  }

  if (isBigScreen && kind) {
    return (
      // TODO: replace with maneki Card later
      <div className={styles.card}>
        <FeedbackForm
          kind={kind}
          visitId={visitId}
          onSubmit={() => setDone(true)}
        />
      </div>
    );
  }

  return (
    <>
      {/* TODO: replace with maneki Card later */}
      <div className={styles.card}>
        <Typography as="h3" variant="label-m">
          <trans.Question />
        </Typography>
        <div className={styles.buttons}>
          <Button
            size="small"
            variant="secondary"
            onPress={() => setKind("positive")}
          >
            <trans.Yes />
          </Button>
          <Button
            size="small"
            variant="secondary"
            onPress={() => setKind("negative")}
          >
            <trans.No />
          </Button>
        </div>
      </div>
      {!isBigScreen && (
        <BottomSheet
          isActive={!!kind}
          onOutsideClick={() => setKind(undefined)}
        >
          <FeedbackForm
            kind={kind}
            visitId={visitId}
            onSkip={() => setKind(undefined)}
            onSubmit={() => setDone(true)}
          />
        </BottomSheet>
      )}
    </>
  );
}
