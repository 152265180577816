import { localize } from "render/context/InternationalizationContext";

const CheckList = {
  Caffeine: localize({
    en_GB: <>Avoid caffeine 2 hours in advance.</>,
    sv_SE: <>Undvik koffein 2 timmar före din scan.</>,
  }),
  Eating: localize({
    en_GB: <>You can eat, but avoid large portions.</>,
    sv_SE: <>Du kan äta, men undvik stora måltider.</>,
  }),
  Exercises: localize({
    en_GB: <>You can exercise as usual.</>,
    sv_SE: <>Du kan träna som vanligt.</>,
  }),
  Skin: localize({
    en_GB: <>For the skin scan, avoid wearing textured or baggy underwear.</>,
    sv_SE: <>Undvik mönstrade eller löst sittande underkläder vid din scan.</>,
  }),
};

const BeforeYourScan = localize({
  en_GB: <>The day of your scan</>,
  sv_SE: <>Samma dag som din scan</>,
});

const NeedHelp = localize({
  en_GB: <>If you need help</>,
  sv_SE: <>Behöver du hjälp?</>,
});

const HelpItems = {
  EMail: localize<{ mailLink: JSX.Element }>({
    en_GB: ({ mailLink }) => <>Email us at {mailLink}</>,
    sv_SE: ({ mailLink }) => <>Maila oss på {mailLink}</>,
  }),
  FAQ: localize({
    en_GB: (
      <>
        Check our{" "}
        <a
          href="https://www.nekohealth.com/faq-se"
          target="_blank"
          rel="noreferrer"
        >
          FAQs
        </a>
      </>
    ),
    sv_SE: (
      <>
        Läs våra{" "}
        <a
          href="https://www.nekohealth.com/faq-se"
          target="_blank"
          rel="noreferrer"
        >
          vanliga frågor
        </a>
      </>
    ),
  }),
};

export const Trans = { BeforeYourScan, CheckList, HelpItems, NeedHelp };
