import { localize } from "render/context/InternationalizationContext";

const Book = localize({
  en_GB: "Book",
  sv_SE: "Boka",
});

const Booking = localize({
  en_GB: "Booking...",
  sv_SE: "Bokar...",
});

const SlotIsTaken = localize({
  en_GB: "Slot taken",
  sv_SE: "Tid upptagen",
});

const Success = localize({
  en_GB: "Success!",
  sv_SE: "Lyckades!",
});

export const Trans = {
  Book,
  Booking,
  Error: {
    SlotIsTaken,
  },
  Success,
};
