import { mount, PathRoute } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import { CardPayment } from "../CardPayment/CardPayment";
import { CodePayment } from "../CodePayment/CodePayment";
import { CheckoutContext } from "../_CheckoutContext/CheckoutContext";

const Code = {
  component: CodePayment,
  path: paths.checkout,
};
const Card = {
  component: CardPayment,
  path: paths.checkoutCard,
};

export function CheckoutRouter() {
  return (
    <CheckoutContext>
      <PathRoute path={Code.path}>{mount(Code.component)}</PathRoute>
      <PathRoute path={Card.path}>{mount(Card.component)}</PathRoute>
    </CheckoutContext>
  );
}
