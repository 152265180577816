import { Button, Icon24ArrowRight, Typography } from "@cur8/maneki";
import { useIsViewportAtLeast } from "render/hooks/useIsViewportAtLeast";
import { DateReadable } from "render/ui/format/DateReadable";
import { PostScanShareViewEntrypoint } from "render/views/PostScanShareView/components/PostScanShareViewEntrypoint";
import { useSummaries } from "render/views/Report/AppointmentDataView/hooks/useSummaries";
import { Summary } from "../Summary";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

type SummariesTabProps = {
  onGoToHistory: () => void;
  onGoToScanData: () => void;
};

export function SummariesTab({
  onGoToHistory,
  onGoToScanData,
}: SummariesTabProps) {
  const isBigScreen = useIsViewportAtLeast("sm");
  const summaries = useSummaries();
  const latestSummary = summaries.at(0);

  return (
    <div className={styles.SummariesTab} data-testid="summaries-tab">
      <div className={styles.header}>
        <div>
          <Typography variant="display-s">
            <Trans.Title />
          </Typography>
          {latestSummary?.lastUpdatedAt && (
            <Typography variant="body-s" color="subtle">
              <Trans.LastUpdateOn
                date={<DateReadable date={latestSummary.lastUpdatedAt} />}
              />
            </Typography>
          )}
        </div>
        <PostScanShareViewEntrypoint />
      </div>
      <div className={styles.messages}>
        {latestSummary?.messages.map((message) => (
          <Summary
            key={message.id}
            date={message.date}
            doctorUri={message.doctorUri}
            hasFeedback={latestSummary.hasFeedback}
            notificationId={message.notificationId}
            seen={message.seen}
            text={message.text}
            title={message.title}
            visitId={latestSummary.visitId}
          />
        ))}
      </div>
      <div className={styles.buttons} data-large={isBigScreen}>
        <Button
          icon={isBigScreen ? undefined : <Icon24ArrowRight />}
          variant={isBigScreen ? "tertiary" : "primary"}
          onClick={onGoToScanData}
        >
          <Trans.ScanData />
        </Button>
        {
          // only show the button if there's more than one summary
          // because the "latest" will not show on that view
          summaries.length > 1 && (
            <Button
              data-self="center"
              variant="tertiary"
              onClick={onGoToHistory}
            >
              <Trans.ReportHistory />
            </Button>
          )
        }
      </div>
    </div>
  );
}
