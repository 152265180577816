import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: "Mobile number",
  sv_SE: "Telefonnummer",
});

const Link = localize({
  en_GB: "Edit number",
  sv_SE: "Byt mobilnummer",
});

export const Trans = {
  Link,
  Title,
};
