import { fromAPI } from "@cur8/rich-entity";
import { useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { ExtractBody, ExtractKey, QueryOptions } from "typings/query";

export function useFetchConciergeSessionQuery<T = Body>(
  sessionId: string,
  options: QueryOptions<Body, Key, T> = {}
) {
  const apiClient = useAPIClient();

  return useQuery({
    ...options,
    queryFn: queryFn(apiClient, sessionId),
    queryKey: queryKey(sessionId),
  });
}

type Body = ExtractBody<typeof queryFn>;
type Key = ExtractKey<typeof queryKey>;

function queryFn(apiClient: APIClient, sessionId: string) {
  return async () => {
    const sessionDTO = await apiClient.checkinSession.fetchSession({
      sessionId,
    }).result;

    return fromAPI.toCheckinSessionState(sessionDTO);
  };
}

function queryKey(sessionId: string) {
  return ["concierge-session", sessionId];
}
