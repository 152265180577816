import { Metric } from "lib/api/metrics";
import { getHighestSide } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getHighestSide";

interface GetBySideProps {
  left?: Metric<"risk_assessment.eye_pressure.left">;
  right?: Metric<"risk_assessment.eye_pressure.right">;
}

export function pickHighestSide({
  left: leftEyePressure,
  right: rightEyePressure,
}: GetBySideProps) {
  const highestEyePressureSide = getHighestSide({
    left: leftEyePressure?.unit.mmHg,
    right: rightEyePressure?.unit.mmHg,
  });

  return {
    eyePressure:
      highestEyePressureSide === "left" ? leftEyePressure : rightEyePressure,
    side: highestEyePressureSide,
  };
}
