import { localize } from "render/context/InternationalizationContext";

const MetricName = localize({
  en_GB: <>Weight</>,
  sv_SE: <>Vikt</>,
});

const Units = {
  Kilograms: localize({
    en_GB: "kg",
    sv_SE: "kg",
  }),
  Pounds: localize({
    en_GB: "lbs",
    sv_SE: "lbs",
  }),
  Stones: localize({
    en_GB: "st",
    sv_SE: "st",
  }),
};

const Increased = localize({
  en_GB: "Increased by",
  sv_SE: "En ökning med",
});
const Decreased = localize({
  en_GB: "Decreased by",
  sv_SE: "En minskning med",
});

const FAQ = {
  Aim: localize({
    en_GB: (
      <>
        Aiming for a healthy weight is ideal, but this varies depending on
        height, age, gender, and body composition. BMI (Body Mass Index) can
        help you understand if your weight range is considered healthy, but you
        also need to consider individual factors such as your build and overall
        health. Lifestyle choices focusing on balanced nutrition and regular
        physical activity are key, rather than fixating on a specific number on
        the scale.
      </>
    ),
    sv_SE: (
      <>
        Att sikta på ett hälsosamt viktintervall är idealiskt, men detta
        varierar beroende på längd, ålder, kön och kroppssammansättning. BMI
        (Body Mass Index) kan hjälpa dig att förstå om du ligger inom ett
        hälsosamt viktintervall, men du måste också ta hänsyn till individuella
        faktorer som din kroppsbyggnad och din allmänna hälsa. Livsstilsval med
        fokus på balanserad kost och regelbunden fysisk aktivitet är nyckeln,
        snarare än att fixera vid ett specifikt nummer på skalan.
      </>
    ),
  }),
  Trend: localize({
    en_GB: (
      <>
        Tracking weight trends is important. Gradual weight gain might indicate
        a need for lifestyle changes, such as eating a healthier diet or
        increasing your physical activity. Conversely, unexpected weight loss
        might warrant an appointment with your doctor to rule out underlying
        health issues. It's the pattern and context of weight change that often
        provides more insight than a single measurement.
      </>
    ),
    sv_SE: (
      <>
        Det är viktigt att spåra vikttrender. Gradvis viktökning kan tyda på ett
        behov av livsstilsförändringar, som att äta en hälsosammare kost eller
        öka din fysiska aktivitet. Oväntad viktminskning kan motivera ett möte
        med din läkare för att utesluta underliggande hälsoproblem. Det är
        mönstret och sammanhanget för viktförändringar som ofta ger mer insikt
        än en enskild mätning.
      </>
    ),
  }),
  Why: localize({
    en_GB: (
      <>
        Your weight helps in understanding your nutritional status and can be a
        key indicator of overall health. Regularly monitoring weight helps to
        detect changes that might be signs of health issues, it's also useful in
        managing conditions like obesity, heart disease, and diabetes.
      </>
    ),
    sv_SE: (
      <>
        Din vikt hjälper till att förstå din näringsstatus och kan vara en
        nyckelindikator för din allmänna hälsa. Regelbunden övervakning av
        vikten hjälper till att upptäcka förändringar som kan vara tecken på
        hälsoproblem. Det är också användbart för att hantera tillstånd som
        fetma, hjärtsjukdomar och diabetes.
      </>
    ),
  }),
};

export const Trans = { Decreased, FAQ, Increased, MetricName, Units };
