import { useQueryParams } from "@pomle/react-router-paths";
import { queries } from "render/routes/paths";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";
import { Benchmark } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Benchmark/Benchmark";
import { MetricDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Details";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useRiskMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

export function MetricLDLDetails() {
  const { data: ldl } = useRiskMetric("bloodwork.ldl");

  const [params, setParams] = useQueryParams(queries.metric);
  const numOfScans = ldl?.length || 1;
  const scanNum = params.scan[0] || numOfScans;
  const setScanNum = (num: number) => setParams({ scan: [num] });

  if (!ldl) {
    return null;
  }

  const { cur: current, prev: previous } = getMetricHistory(ldl, scanNum);

  const unit = "mmol/L";
  const { riskIntervals, riskLevel } = current?.riskRanges ?? {};

  const values = {
    metricRating: riskLevel,
    previousValue: previous?.unit[unit],
    ranges: toChartRanges(riskIntervals),
    // https://dev.azure.com/cur8/Engineering/_workitems/edit/26138
    rangesCapHigh: 8.5,
    rangesCapLow: 1.5,
    scanDate: current?.measurement.timestampStart,
    subLabel: unit,
    value: current?.unit[unit],
  };

  return (
    <MetricDetails
      label={<Trans.MetricName />}
      values={values}
      scanNum={scanNum}
      setScanNum={setScanNum}
      numOfScans={numOfScans}
      FAQText={Trans.FAQ}
      benchmark={
        values.value != null && (
          <Benchmark
            measure="ldl"
            measurement={values.value}
            unit={values.subLabel}
          />
        )
      }
    />
  );
}
