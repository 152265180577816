import { APITypesV2 } from "@cur8/api-client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { paths } from "render/routes/paths";
import { useMutation } from "@tanstack/react-query";

type DeepRequired<T> = Required<{
  [P in keyof T]: Required<DeepRequired<T[P]>>;
}>;

export type Checkout = DeepRequired<APITypesV2.InitiateCheckoutResponse>;

export function useInitializeCheckoutMutation() {
  const { patientId } = useSession();
  const apiClient = useAPIClient().billingV2;

  return useMutation({
    mutationFn: async () => {
      return apiClient.initiateCheckout(patientId, {
        failedUrl: `${window.origin}${paths.checkoutFail.url({})}`,
        successUrl: `${window.origin}${paths.checkoutSuccess.url({})}`,
      }).result as Promise<Required<Checkout>>;
    },
  });
}
