import { Answers } from "@cur8/questionnaire";
import { localeToISO639Code } from "lib/i18n/localeToISO639Code";
import {
  DoYouDrinkAlcohol,
  DrinkCount,
} from "lib/questionnaire/question-flows/onboarding/questions/drinking";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { useLocale } from "render/context/InternationalizationContext";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function DrinkAlcoholStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(DoYouDrinkAlcohol);
  const label = TransQuestions.Drinking.DoYouDrinkAlcohol();
  const { englishLabel } = TransQuestions.Drinking.DoYouDrinkAlcohol;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={DoYouDrinkAlcohol}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(DoYouDrinkAlcohol);
      }}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.DrinkAlcoholExplanation />,
        openText: <Trans.General.WhyAreWeAsking />,
      }}
    >
      <ChoiceInput
        question={DoYouDrinkAlcohol}
        answer={answer}
        onAnswer={(answer) => {
          const fetchedAnswer = DoYouDrinkAlcohol.getValue(answer);
          if (fetchedAnswer != null) {
            onAnswer(DoYouDrinkAlcohol, {
              answer,
              questionAnswer: {
                answer: {
                  $type: "boolean",
                  value: fetchedAnswer,
                },
                englishLabel,
                label,
                languageCode,
                questionId: "alcohol",
              },
            });
            onNext();
          }
        }}
      />
    </QuestionView>
  );
}

function DrinkCountStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(DrinkCount);
  const label = TransQuestions.Drinking.Question();
  const { englishLabel } = TransQuestions.Drinking.Question;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={DrinkCount}
      onNext={() => {
        onResetAnswer(DrinkCount);
        onNext();
      }}
      onPrev={() => {
        onPrev();
        onResetAnswer(DrinkCount);
      }}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.DrinkCountExplanation />,
        openText: <Trans.Steps.DrinkingOpenText />,
      }}
    >
      <ChoiceInput
        question={DrinkCount}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(DrinkCount, {
            answer,
            questionAnswer: {
              answer: {
                $type: "stringenum",
                value: DrinkCount.getValue(answer) || "",
              },
              englishLabel,
              label,
              languageCode,
              questionId: "alcohol.weeklyDrinks",
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(DrinkAlcoholStep);

  if (answers.lookup(DoYouDrinkAlcohol) === true) {
    steps.push(DrinkCountStep);
  }
}
