import { APITypesV1 } from "@cur8/api-client";
import { Typography } from "@cur8/maneki";
import { copyToClipboard } from "lib/copyToClipboard";
import { Country } from "lib/country";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { useConfig } from "render/context/ConfigContext";
import { useTimeOut } from "render/hooks/useTimeOut";
import { useTracking } from "render/hooks/useTracking";
import { discounTokenEvent } from "../../../../tracking";
import { calcDayDiff } from "./lib/calcDayDiff";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface DiscountCodeAvailableProps {
  token: APITypesV1.DiscountToken;
}

export function DiscountCodeAvailable({ token }: DiscountCodeAvailableProps) {
  const {
    appConfig: { redirectServiceBaseUrl },
  } = useConfig();
  const { trackEvent } = useTracking();
  const [copied, setCopied] = useState<boolean>();
  const { enqueueSnackbar } = useSnackbar();
  const clipboardResetTimeout = useTimeOut(2000, () => setCopied(false));

  const createInviteUrl = useCallback(
    (code: string, country: string) => {
      const pathParts = [
        redirectServiceBaseUrl,
        country.toLowerCase(),
        // hack(ford): this is a bit dirty but we picked the default language
        //             of each country. We need to dictate which login method
        //             to use for now until we drop preferred country.
        country === Country.Sweden ? "sv" : "en",
        "refer",
        code,
      ];

      const result = pathParts.join("/");

      return result;
    },
    [redirectServiceBaseUrl]
  );

  const onClick = useCallback(() => {
    if (token.code == null) {
      return;
    }
    if (copied) {
      return;
    }
    trackEvent(discounTokenEvent.copyToClipboardClick());

    const url = createInviteUrl(token.code, token.country ?? "se");
    const didCopy = copyToClipboard(url);

    if (!didCopy) {
      return;
    }
    setCopied(true);
    clipboardResetTimeout.startTimeout();
    enqueueSnackbar(<Trans.CoppiedToClipboard />, {
      autoHideDuration: 2000,
      variant: "success",
    });
  }, [
    clipboardResetTimeout,
    createInviteUrl,
    copied,
    enqueueSnackbar,
    token.code,
    token.country,
    trackEvent,
  ]);

  const expiresInDays = useMemo(() => {
    if (token.validUntil == null) {
      return NaN;
    }
    return calcDayDiff(token.validUntil, DateTime.now().toISODate());
  }, [token.validUntil]);

  return (
    <>
      <div className={styles.title}>
        <Typography variant="eyebrow-m" color="subtle">
          <Trans.Title />
        </Typography>
        {expiresInDays === 0 && (
          <Typography variant="body-xs" color="warning">
            <Trans.Expires.Today />
          </Typography>
        )}
        {expiresInDays > 0 && (
          <Typography variant="body-xs" color="subtle">
            <Trans.Expires.In days={expiresInDays} />
          </Typography>
        )}
      </div>
      <div className={styles.row}>
        <Typography variant="label-m" color="default">
          {token.code}
        </Typography>
        <button className={styles.copyButton} onClick={onClick}>
          <Typography variant="label-s">
            {copied ? <Trans.Copied /> : <Trans.Copy />}
          </Typography>
        </button>
      </div>
    </>
  );
}
