import { localize } from "render/context/InternationalizationContext";

const Title = localize({
  en_GB: "Payment Details",
  sv_SE: "Betalningsinformation",
});

const SubTitle = localize({
  en_GB: "Neko Health accepts major debit and credit cards:",
  sv_SE: "Neko Health tar alla stora debit- och kreditkort:",
});

const CTA = localize({
  en_GB: "Pay now",
  sv_SE: "Betala nu",
});

const OrderOverview = localize({
  en_GB: "Order Overview",
  sv_SE: "Beställningsöversikt",
});

const Total = localize({
  en_GB: "Total:",
  sv_SE: "Summa:",
});

export const Trans = {
  CTA,
  OrderOverview,
  SubTitle,
  Title,
  Total,
};
