import { Answers } from "@cur8/questionnaire";
import { localeToISO639Code } from "lib/i18n/localeToISO639Code";
import {
  DietEnum,
  DietOptions,
  MoreDietOptions,
  OtherDietOption,
} from "lib/questionnaire/question-flows/onboarding/questions/diet";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { useLocale } from "render/context/InternationalizationContext";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { TextInput } from "render/ui/presentation/Questionnaire/components/TextInput";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function DietTakenStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(DietOptions);
  const label = TransQuestions.Diet.Question();
  const { englishLabel } = TransQuestions.Diet.Question;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={DietOptions}
      onNext={() => {
        onResetAnswer(DietOptions);
        onNext();
      }}
      onPrev={() => {
        onPrev();
        onResetAnswer(DietOptions);
      }}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.DietExplanation />,
        openText: <Trans.General.WhyAreWeAsking />,
      }}
    >
      <ChoiceInput
        question={DietOptions}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(DietOptions, {
            answer,
            questionAnswer: {
              answer: {
                $type: "stringenum",
                value: DietOptions.getValue(answer) || "",
              },
              englishLabel,
              label,
              languageCode,
              questionId: "diet",
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function MoreDietTakenStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(MoreDietOptions);
  const label = TransQuestions.Diet.Question();
  const { englishLabel } = TransQuestions.Diet.Question;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={MoreDietOptions}
      onNext={() => {
        onResetAnswer(DietOptions);
        onNext();
      }}
      onPrev={() => {
        onPrev();
        onResetAnswer(MoreDietOptions);
      }}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.DietExplanation />,
        openText: <Trans.General.WhyAreWeAsking />,
      }}
    >
      <ChoiceInput
        question={MoreDietOptions}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(MoreDietOptions, {
            answer,
            questionAnswer: {
              answer: {
                $type: "stringenum",
                value: MoreDietOptions.getValue(answer) || "",
              },
              englishLabel,
              label,
              languageCode,
              questionId: "diet",
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function OtherDietTakenStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(OtherDietOption);
  const label = TransQuestions.Diet.Question();
  const { englishLabel } = TransQuestions.Diet.Question;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={OtherDietOption}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(OtherDietOption);
      }}
      cta={<Trans.General.NextQuestion />}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.DietExplanation />,
        openText: <Trans.General.WhyAreWeAsking />,
      }}
    >
      <TextInput
        placeholder={Trans.General.WriteYourAnswer()}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(OtherDietOption, {
            answer,
            questionAnswer: {
              answer: {
                $type: "text",
                value: OtherDietOption.getValue(answer).trim(),
              },
              englishLabel,
              label,
              languageCode,
              questionId: "diet",
            },
          });
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(DietTakenStep);

  if (answers.lookup(DietOptions) === DietEnum.More) {
    steps.push(MoreDietTakenStep);
  }
  if (answers.lookup(MoreDietOptions) === DietEnum.Other) {
    steps.push(OtherDietTakenStep);
  }
}
