import { Answers } from "@cur8/questionnaire";
import { localeToISO639Code } from "lib/i18n/localeToISO639Code";
import {
  Diabetes,
  DiabetesDetails,
  DiabetesEnum,
} from "lib/questionnaire/question-flows/onboarding/questions/diabetes";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { useLocale } from "render/context/InternationalizationContext";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { TextInput } from "render/ui/presentation/Questionnaire/components/TextInput";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function DiabetesStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(Diabetes);
  const label = TransQuestions.Diabetes.Question();
  const { englishLabel } = TransQuestions.Diabetes.Question;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={Diabetes}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(Diabetes);
      }}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.DiabetesExplanation />,
        openText: <Trans.General.WhatDoesThisMean />,
      }}
    >
      <ChoiceInput
        question={Diabetes}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(Diabetes, {
            answer,
            questionAnswer: {
              answer: {
                $type: "stringenum",
                value: Diabetes.getValue(answer) || "",
              },
              englishLabel,
              label,
              languageCode,
              questionId: "diabetes",
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function DiabetesDetailsStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(DiabetesDetails);
  const label = TransQuestions.Diabetes.Details();
  const { englishLabel } = TransQuestions.Diabetes.Details;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={DiabetesDetails}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(DiabetesDetails);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.DiabetesDetailsExplanation />,
        openText: <Trans.General.WhyAreWeAsking />,
      }}
    >
      <TextInput
        placeholder={Trans.General.WriteYourAnswer()}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(DiabetesDetails, {
            answer,
            questionAnswer: {
              answer: {
                $type: "text",
                value: DiabetesDetails.getValue(answer).trim(),
              },
              englishLabel,
              label,
              languageCode,
              questionId: "diabetes.details",
            },
          });
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(DiabetesStep);

  if (answers.lookup(Diabetes) === DiabetesEnum.Yes) {
    steps.push(DiabetesDetailsStep);
  }
}
