import { createTimer } from "lib/timer";
import { useEffect, useMemo } from "react";
import * as THREE from "three";
import {
  createLookAtTween,
  createPositionTween,
  createZoomTween,
} from "./lib/tween";

THREE.Object3D.DEFAULT_UP.set(0, 0, 1);

export function createScene() {
  return new THREE.Scene();
}

export class CameraView {
  fov = 35;
  pos = new THREE.Vector3();
  focus = new THREE.Vector3();

  clone() {
    const next = new CameraView();
    next.fov = this.fov;
    next.pos = this.pos.clone();
    next.focus = this.focus.clone();
    return next;
  }
}

const FAST = {
  friction: 2,
  mass: 20,
  stiffness: 3,
};

const SLOW = {
  friction: 4,
  mass: 100,
  stiffness: 1,
};

const SPEED = {
  fast: FAST,
  slow: SLOW,
};

function createTweens(camera: THREE.PerspectiveCamera) {
  const pos = createPositionTween(camera, SLOW);
  const lookAt = createLookAtTween(camera, SLOW);
  const zoom = createZoomTween(camera, SLOW);

  return {
    lookAt,
    pos,
    zoom,
  };
}

export class DashboardCamera extends THREE.PerspectiveCamera {
  tweens = createTweens(this);

  constructor() {
    super(35, 1 / 1.5, 100, 20000);
  }

  setTo(view: CameraView) {
    this.position.copy(view.pos);
    this.lookAt(view.focus);

    this.tweens.pos.set(view.pos);
    this.tweens.lookAt.set(view.focus);
    this.tweens.zoom.set({ fov: view.fov });
  }

  moveTo(view: CameraView) {
    this.tweens.pos.to(view.pos);
    this.tweens.lookAt.to(view.focus);
    this.tweens.zoom.to({ fov: view.fov });
  }

  setSpeed(preset: keyof typeof SPEED) {
    const config = SPEED[preset];
    this.tweens.lookAt.reconfigure(config);
    this.tweens.pos.reconfigure(config);
    this.tweens.zoom.reconfigure(config);
  }

  update(deltaTime: number) {
    this.tweens.pos.update(deltaTime);
    this.tweens.lookAt.update(deltaTime);
    this.tweens.zoom.update(deltaTime);
  }
}

const AVERAGE_HUMAN_HEIGHT = 1750;
export const BELLOW_HEAD = 800;

export const DEFAULT_CAMERA_VIEW = new CameraView();
DEFAULT_CAMERA_VIEW.pos.set(0, -3200, AVERAGE_HUMAN_HEIGHT - BELLOW_HEAD);
DEFAULT_CAMERA_VIEW.focus.set(0, 0, AVERAGE_HUMAN_HEIGHT - BELLOW_HEAD);

export function useCamera() {
  const camera = useMemo(() => {
    const camera = new DashboardCamera();
    camera.setTo(DEFAULT_CAMERA_VIEW);
    return camera;
  }, []);

  useEffect(() => {
    return createTimer((deltaTime) => {
      const dt = deltaTime / 1000;
      camera.update(dt);
    });
  }, [camera]);

  return camera;
}
