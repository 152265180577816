import type { ChartRange } from "render/ui/presentation/RangeChart/types";

export function inRange(value: number | undefined) {
  if (value == null) {
    return () => false;
  }

  return (range: ChartRange, index: number, array: ChartRange[]) => {
    const from = index === 0 ? -Infinity : range.from;
    const to = index === array.length - 1 ? Infinity : range.to;

    if (range.fromIsInclusive && value === from) {
      return true;
    }
    if (range.toIsInclusive && value === to) {
      return true;
    }

    return value > from && value < to;
  };
}
