import { Action, Event } from "render/hooks/useTracking";

const category = "PostScanIntroView";

export const postScanShareEvent = {
  continueClick: (): Event => {
    return {
      action: Action.Click,
      category,
      label: "continue_button",
    };
  },
  openModalClick: (): Event => {
    return {
      action: Action.Click,
      category,
      label: "open_modal_button",
    };
  },
  pageView: (): Event => {
    return {
      action: Action.View,
      category,
    };
  },
  shareClick: (value: "nativeShare" | "download"): Event => {
    return {
      action: Action.Click,
      category,
      label: "share_button",
      value: value,
    };
  },
};
