import { Stripe, PaymentRequest } from "@stripe/stripe-js";
import { Cart } from "../hooks/useUpdateCartMutation/useUpdateCartMutation";

export function getPaymentRequest(stripe: Stripe, cart: Cart) {
  return stripe.paymentRequest({
    country: cart.country,
    currency: cart.currency.toLowerCase(),
    disableWallets: ["link"],
    displayItems: [
      {
        amount: cart.cartTotal * 100,
        label: "Neko Scan",
      },
    ],
    requestPayerEmail: true,
    requestPayerName: true,
    requestPayerPhone: true,
    total: {
      amount: cart.cartTotal * 100,
      label: "Total",
    },
  });
}

export function getWallet(paymentRequest: PaymentRequest) {
  return paymentRequest.canMakePayment();
}
