import { Typography } from "@cur8/maneki";
import { PathLink } from "@pomle/react-router-paths";
import { ReactComponent as ArrowRightIcon } from "assets/icons/24x24/24x24_arrow-right.svg";
import { pickAriaAttributes, pickDataAttributes } from "lib/react/utils";
import { AriaAttributes, ReactNode } from "react";
import styles from "./styles.module.sass";

type LinkToProps = {
  href: string;
  head: ReactNode;
  description?: ReactNode;
  variant: "wide" | "square";
} & AriaAttributes;

const VARIANTS: Record<LinkToProps["variant"], string> = {
  square: styles.variantSquare,
  wide: styles.variantWide,
};

// We use ...restProps to pass "data-" and "aria-" attributes
export function LinkTo({
  href,
  head,
  description,
  variant,
  ...restProps
}: LinkToProps) {
  const className = [styles.LinkTo, VARIANTS[variant] ?? ""].join(" ");
  const dataAttributes = pickDataAttributes(restProps);
  const ariaAttributes = pickAriaAttributes(restProps);

  return (
    <PathLink
      to={href}
      className={className}
      {...dataAttributes}
      {...ariaAttributes}
    >
      <div className={styles.text}>
        <Typography variant="label-m" color="default">
          {head}
        </Typography>
        {description ? (
          <Typography variant="body-s" color="subtle">
            {description}
          </Typography>
        ) : null}
      </div>
      <ArrowRightIcon className={styles.icon} />
    </PathLink>
  );
}
