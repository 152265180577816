import { Typography } from "@cur8/maneki";
import { ReactComponent as LogoIcon } from "assets/logo.svg";
import {
  ComponentProps,
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Timings } from "../../animations";
import BgImage from "./assets/background.png";
import styles from "./styles.module.sass";

interface ScanCompleteAssetProps extends ComponentProps<"div"> {
  name: string;
  date: string;
  scan: string;
  siteName: string;
  playAnimation?: boolean;
  onReady?: () => void;
  onLogoPositionChanged?: (rect: DOMRect) => void;
}

export function ScanCompleteAsset({
  name,
  date,
  scan,
  siteName,
  playAnimation,
  onReady,
  onLogoPositionChanged,
  style = {},
  ...rest
}: ScanCompleteAssetProps) {
  const animationPlayState = useMemo(
    () => (playAnimation ? "running" : "paused"),
    [playAnimation]
  );

  const cardRef = useRef<HTMLDivElement>(null);

  // note(ford): supporting animations from 100vh to auto with an aspect-ratio is not supported by all browsers,
  //             calculating it manually and passing it as a css variable
  const [cardFinalHeight, setCardFinalHeight] = useState<number | undefined>();

  useEffect(() => {
    if (!cardRef.current) {
      return;
    }

    const card = cardRef.current;

    const observer = new ResizeObserver(() => {
      setCardFinalHeight(card.clientWidth * 1.33);
    });

    observer.observe(card);

    return () => {
      observer.disconnect();
      setCardFinalHeight(undefined);
    };
  }, []);

  const logoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!onLogoPositionChanged) {
      return;
    }

    const logoElement = logoRef.current;

    const updatePosition = () => {
      if (logoElement) {
        const rect = logoElement.getBoundingClientRect();
        onLogoPositionChanged(rect);
      }
    };

    const resizeObserver = new ResizeObserver(updatePosition);
    const intersectionObserver = new IntersectionObserver(updatePosition);

    if (logoElement) {
      resizeObserver.observe(logoElement);
      intersectionObserver.observe(logoElement);
    }

    updatePosition();

    return () => {
      if (logoElement) {
        resizeObserver.unobserve(logoElement);
        intersectionObserver.unobserve(logoElement);
      }
    };
  }, [onLogoPositionChanged]);

  return (
    <div
      ref={cardRef}
      className={styles.card}
      style={
        {
          "--card-height": `${cardFinalHeight}px`,
          "--duration": `${Timings.cardTransitionDurationSec}s`,
          "--play-state": animationPlayState,
          ...style,
        } as React.CSSProperties
      }
      {...rest}
    >
      <div className={styles.content}>
        <img
          className={styles.bg}
          src={BgImage}
          alt="Background"
          onLoad={onReady}
        />

        <div className={styles.blur} />

        <div className={styles.body}>
          <div ref={logoRef} className={styles.logo}>
            <LogoIcon />
          </div>

          <div
            className={styles.header}
            style={
              {
                "--delay": `${Timings.cardHeaderDelaySec}s`,
                "--duration": `${Timings.cardHeaderDurationSec}s`,
                "--play-state": animationPlayState,
              } as CSSProperties
            }
          >
            <Typography variant="display-s">
              Body Scan,
              <br />
              complete.
            </Typography>
          </div>
        </div>
      </div>
      <div
        className={styles.cardFooter}
        style={
          {
            "--delay": `${Timings.cardFooterDelaySec}s`,
            "--duration": `${Timings.cardFooterDurationSec}s`,
            "--play-state": animationPlayState,
          } as CSSProperties
        }
      >
        <span>{name}</span>
        <span>{date}</span>
        <span>{scan}</span>
        <span>Neko {siteName}</span>
      </div>
    </div>
  );
}
