import { Answers } from "@cur8/questionnaire";
import { localeToISO639Code } from "lib/i18n/localeToISO639Code";
import {
  Cardiovascular,
  CardiovascularDetailsQuestion,
  CardiovascularEnum,
} from "lib/questionnaire/question-flows/onboarding/questions/cardiovascular";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { useLocale } from "render/context/InternationalizationContext";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { TextInput } from "render/ui/presentation/Questionnaire/components/TextInput";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function CardiovascularStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(Cardiovascular);
  const label = TransQuestions.Cardiovascular.Question();
  const { englishLabel } = TransQuestions.Cardiovascular.Question;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={Cardiovascular}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(Cardiovascular);
      }}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.CardioVascularExplanation />,
        openText: <Trans.General.WhatDoesThisMean />,
      }}
    >
      <ChoiceInput
        question={Cardiovascular}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(Cardiovascular, {
            answer,
            questionAnswer: {
              answer: {
                $type: "stringenum",
                value: Cardiovascular.getValue(answer) || "",
              },
              englishLabel,
              label,
              languageCode,
              questionId: "cardiovascular",
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function CardiovascularDetails({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(CardiovascularDetailsQuestion);
  const label = TransQuestions.Cardiovascular.Details();
  const { englishLabel } = TransQuestions.Cardiovascular.Details;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={CardiovascularDetailsQuestion}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(CardiovascularDetailsQuestion);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.CardiovascularDetailsExplanation />,
        openText: <Trans.General.WhyAreWeAsking />,
      }}
    >
      <TextInput
        placeholder={Trans.General.WriteYourAnswer()}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(CardiovascularDetailsQuestion, {
            answer,
            questionAnswer: {
              answer: {
                $type: "text",
                value: CardiovascularDetailsQuestion.getValue(answer).trim(),
              },
              englishLabel,
              label,
              languageCode,
              questionId: "cardiovascular.details",
            },
          });
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(CardiovascularStep);

  if (answers.lookup(Cardiovascular) === CardiovascularEnum.Yes) {
    steps.push(CardiovascularDetails);
  }
}
