import { Typography } from "@cur8/maneki";
import { Visit } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { useEarliestUpcomingVisitQuery } from "render/hooks/api/queries/useEarliestUpcomingVisitQuery";
import { useScanBookedAssetDetailsQuery } from "render/hooks/api/queries/useScanBookedAssetDetailsQuery";
import { useScanBookedAssetQuery } from "render/hooks/api/queries/useScanBookedAssetQuery";
import { useSlotQuery } from "render/hooks/api/queries/useSlotQuery";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { paths } from "render/routes/paths";
import { FullMonthDay } from "render/ui/format/FullMonthDay/FullMonthDay";
import { TimeHourMinute } from "render/ui/format/TimeHourMinute";
import { useBookSlotMutation } from "render/views/booking/_shared/components/BookingCalendar/hooks/useBookSlotMutation";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface BookingActionsProps {
  slotId: string;
  onSlotIsTaken: (slotId: string) => void;
  onBook?: () => void;
  onError?: () => void;
}

export function BookingActions({
  slotId,
  onSlotIsTaken,
  onBook,
  onError,
}: BookingActionsProps) {
  const queryClient = useQueryClient();
  const slotQuery = useSlotQuery(
    { slotId },
    {
      placeholderData: (prev) => prev,
      refetchInterval: (query) => {
        return query.state.data?.isFree ? 3000 : false;
      },
    }
  );
  const slot = slotQuery.data;

  useEffect(() => {
    if (!slot || slot.isFree) {
      return;
    }
    onSlotIsTaken(slot.slotId);
  }, [queryClient, slot, onSlotIsTaken]);

  const { refetch: refetchUpcomingVisitQuery } = useEarliestUpcomingVisitQuery({
    enabled: false,
  });

  const [isBooked, setIsBooked] = useState(false);
  const [createdVisit, setCreatedVisit] = useState<Visit>();

  // note(ford): Prefetch the shareable asset as soon as we have a created visit
  useScanBookedAssetDetailsQuery(createdVisit?.visitId, {
    enabled: !!createdVisit,
  });
  useScanBookedAssetQuery(createdVisit?.visitId, {
    enabled: !!createdVisit,
  });

  const bookSlotMutation = useBookSlotMutation();

  const appointment = useNav(paths.appointment);
  const shareBooking = useNav(paths.shareBooking);

  const appInsights = useAppInsights();

  useEffect(() => {
    if (!isBooked) {
      return;
    }
    if (!slot) {
      return;
    }
    const id = window.setTimeout(() => {
      if (!createdVisit) {
        appointment.go({ slotId: slot.slotId });
      } else {
        shareBooking.go({
          slotId: createdVisit.slotId,
          visitId: createdVisit.visitId,
        });
      }
    }, 1000);

    return () => {
      window.clearTimeout(id);
    };
  }, [isBooked, slot, createdVisit, appointment, shareBooking]);

  const handleBookSlot = useAsyncHandle(
    useCallback(async () => {
      if (!slot) {
        return;
      }

      onBook?.();
      const [result] = await Promise.all([
        bookSlotMutation(slot),
        new Promise((resolve) => {
          window.setTimeout(() => resolve(undefined), 3000);
        }),
      ]).catch((e) => {
        onError?.();
        throw e;
      });

      let visit = undefined;
      let count = 0;

      do {
        try {
          let iteration = count;
          let [upcomingVisit] = await Promise.all([
            refetchUpcomingVisitQuery(),
            new Promise((resolve) => {
              window.setTimeout(() => resolve(undefined), 500 * iteration);
            }),
          ]);

          if (upcomingVisit.data?.slotId === result.slotId) {
            visit = upcomingVisit.data;
          }
        } catch (e) {
          const exception =
            e instanceof Error
              ? e
              : new Error("Refetching upcoming visits failed in booking flow");
          appInsights.trackException({
            exception: exception,
            properties: {
              description: "Refetching upcoming visits failed in booking flow",
            },
          });
        } finally {
          count += 1;
        }
      } while (!visit && count < 5);

      if (!visit) {
        appInsights.trackException({
          exception: new Error("Visit not created after booking"),
          properties: {
            description: "Refetching upcoming visits failed in booking flow",
          },
        });
      } else {
        setCreatedVisit(visit);
      }

      setIsBooked(true);
    }, [
      slot,
      bookSlotMutation,
      refetchUpcomingVisitQuery,
      appInsights,
      onBook,
      onError,
    ])
  );

  if (!slot) {
    return undefined;
  }

  const isBooking = handleBookSlot.busy;
  const isOpen = slot.isFree;
  const disableButton = !isOpen || isBooking || isBooked;
  const timeZoneId = slot.room?.site?.timeZoneId;

  return (
    <div className={styles.BookingActions}>
      <div>
        <Typography variant="label-m">
          <TimeHourMinute date={slot.startTime} timeZoneId={timeZoneId} /> -{" "}
          <TimeHourMinute date={slot.endTime} timeZoneId={timeZoneId} />
        </Typography>
        <Typography variant="body-xs">
          <span className={styles.date}>
            <FullMonthDay date={slot.startTime} timeZoneId={timeZoneId} />
          </span>
        </Typography>
        <Typography variant="body-xs">{slot.room?.site?.siteName}</Typography>
      </div>

      <button
        disabled={disableButton}
        data-inprogress={isBooking}
        onClick={handleBookSlot.callback}
        className={styles.book}
      >
        <Typography
          variant="label-m"
          color={disableButton ? "default" : "inverted"}
        >
          {isBooked ? <Trans.Success /> : undefined}
          {isBooking ? <Trans.Booking /> : undefined}
          {!isBooking && isOpen ? <Trans.Book /> : undefined}
          {!isBooking && !isOpen && !isBooked ? (
            <Trans.Error.SlotIsTaken />
          ) : undefined}
        </Typography>
      </button>
    </div>
  );
}
