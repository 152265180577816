import { Typography } from "@cur8/maneki";
import { useNav, useRouter } from "@pomle/react-router-paths";
import { useEffect } from "react";
import { useEarliestUpcomingVisitQuery } from "render/hooks/api/queries/useEarliestUpcomingVisitQuery";
import { paths } from "render/routes/paths";
import { BurgerLayout } from "render/ui/layout/BurgerLayout";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { VideoBackground } from "render/views/_shared/VideoBackground";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function MemberInviteView() {
  const nav = {
    appointment: useNav(paths.appointment),
  };
  const { history } = useRouter();
  const upcomingPatientVisit = useEarliestUpcomingVisitQuery({
    refetchInterval: (data) => {
      return data == null ? 3000 : false;
    },
  });

  useEffect(() => {
    if (!upcomingPatientVisit.data) {
      return;
    }
    history.replace(
      nav.appointment.to({ slotId: upcomingPatientVisit.data.slotId })
    );
  }, [upcomingPatientVisit.data, history, nav.appointment]);

  return (
    <FullScreenPageLayout background="transparent">
      <BurgerLayout>
        <LogoHeader />
        <div className={styles.body}>
          <div className={styles.content}>
            <div className={styles.text}>
              <Typography style={{ textAlign: "center" }} variant="display-s">
                <Trans.Title />
              </Typography>
              <Typography
                style={{ textAlign: "center" }}
                variant="title-m"
                color="subtle"
              >
                <Trans.SubTitle />
              </Typography>
            </div>
          </div>
        </div>
        {null}
      </BurgerLayout>
      <VideoBackground />
    </FullScreenPageLayout>
  );
}
