import { APITypesV1 } from "@cur8/api-client";
import { useMutation } from "@tanstack/react-query";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";

export function useSummaryFeedbackMutation() {
  const { patientId } = useSession();
  const { immutableVisitSummary } = useAPIClient();

  return useMutation({
    mutationFn({ memberFeedback, visitId }: Variables) {
      return immutableVisitSummary.memberFeedback(
        { patientId, visitId },
        memberFeedback
      ).result;
    },
  });
}

type Variables = {
  memberFeedback: APITypesV1.MemberFeedback;
  visitId: string;
};
