import { APITypesV2 } from "@cur8/api-client";
import { Question } from "@cur8/questionnaire";
import { Visit } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { saveOnboarding } from "lib/api/onboarding";
import { AdditionalWorries } from "lib/questionnaire/question-flows/onboarding/questions/additionalWorries";
import {
  Allergies,
  AllergiesList,
  OtherAllergies,
} from "lib/questionnaire/question-flows/onboarding/questions/allergies";
import { BloodPressure } from "lib/questionnaire/question-flows/onboarding/questions/bloodPressure";
import {
  CardioConditions,
  CardioConditionsValvularDisease,
} from "lib/questionnaire/question-flows/onboarding/questions/cardioConditions";
import { CardioExerciseDuration } from "lib/questionnaire/question-flows/onboarding/questions/cardioExercise";
import {
  Cardiovascular,
  CardiovascularDetailsQuestion,
} from "lib/questionnaire/question-flows/onboarding/questions/cardiovascular";
import {
  Diabetes,
  DiabetesDetails,
} from "lib/questionnaire/question-flows/onboarding/questions/diabetes";
import {
  DietEnum,
  DietOptions,
  MoreDietOptions,
  OtherDietOption,
} from "lib/questionnaire/question-flows/onboarding/questions/diet";
import {
  DoYouDrinkAlcohol,
  DrinkCount,
} from "lib/questionnaire/question-flows/onboarding/questions/drinking";
import { HealthConditions } from "lib/questionnaire/question-flows/onboarding/questions/healthConditions";
import { HealthProblems } from "lib/questionnaire/question-flows/onboarding/questions/healthProblems";
import { FamilyHeritage } from "lib/questionnaire/question-flows/onboarding/questions/heritage";
import {
  MedicalHistoryDiabetes,
  MedicalHistoryDiabetesComplications,
  MedicalHistoryDiabetesType,
} from "lib/questionnaire/question-flows/onboarding/questions/medicalHistoryDiabetes";
import {
  Medications,
  MedicationsSomethingElse,
  MedicationsType,
} from "lib/questionnaire/question-flows/onboarding/questions/medication";
import { OtherHealthConditions } from "lib/questionnaire/question-flows/onboarding/questions/otherHealthConditions";
import { Pregnant } from "lib/questionnaire/question-flows/onboarding/questions/pregnant";
import {
  RelevantConditions,
  RelevantConditionsAutoimmuneDetails,
  RelevantConditionsCancerDetails,
  RelevantConditionsSomethingElseDetails,
} from "lib/questionnaire/question-flows/onboarding/questions/relevantConditions";
import {
  SkinCancer,
  SkinCancerMelanoma,
  SkinCancerMelanomaWho,
} from "lib/questionnaire/question-flows/onboarding/questions/skinCancer";
import {
  SkinConditions,
  SkinConditionsSkinCancerType,
  SkinConditionsType,
} from "lib/questionnaire/question-flows/onboarding/questions/skinConditions";
import {
  DailyCigaretteCount,
  IsSmoker,
  QuitSmokingRecency,
} from "lib/questionnaire/question-flows/onboarding/questions/smoking";
import {
  IsSnuser,
  QuitSnusingRecency,
  SnusHowOften,
} from "lib/questionnaire/question-flows/onboarding/questions/snus";
import { StrengthExerciseDuration } from "lib/questionnaire/question-flows/onboarding/questions/strengthExercise";
import { SunburnFrequency } from "lib/questionnaire/question-flows/onboarding/questions/sunburn";
import { v4 } from "uuid";

const ONBOARDING_V2_QUESTIONNAIRE_TYPE = "onboarding/2";

function saveDietAnswer(
  answersForQuestion: Map<
    Question<unknown, unknown>,
    APITypesV2.QuestionAnswer
  >,
  questionsAndAnswers: APITypesV2.QuestionAnswer[]
) {
  const diet = answersForQuestion.get(DietOptions);

  if (diet?.answer == null) {
    return;
  }

  if (diet.answer.value !== DietEnum.More) {
    questionsAndAnswers.push(diet);
    return;
  }

  const moreDietOptions = answersForQuestion.get(MoreDietOptions);

  if (moreDietOptions?.answer == null) {
    return;
  }

  if (moreDietOptions?.answer?.value !== DietEnum.Other) {
    questionsAndAnswers.push(moreDietOptions);
    return;
  }

  const customDiet = answersForQuestion.get(OtherDietOption);

  if (customDiet?.answer) {
    questionsAndAnswers.push(customDiet);
    return;
  }
}

export function createResponsePersister(api: APIClient, patientId: string) {
  function storeOnboardingNotes({
    answersAndQuestions: answersForQuestion,
    visitId,
  }: {
    answersAndQuestions: Map<
      Question<unknown, unknown>,
      APITypesV2.QuestionAnswer
    >;
    visitId: Visit["visitId"] | undefined;
  }) {
    const questionsAndAnswers: APITypesV2.QuestionnaireResponse["questionsAndAnswers"] =
      [];

    saveDietAnswer(answersForQuestion, questionsAndAnswers);

    const weeklyStrengthExerciseHours = answersForQuestion.get(
      StrengthExerciseDuration
    );
    if (weeklyStrengthExerciseHours?.answer) {
      questionsAndAnswers.push(weeklyStrengthExerciseHours);
    }

    const weeklyCardioExerciseHours = answersForQuestion.get(
      CardioExerciseDuration
    );
    if (weeklyCardioExerciseHours?.answer) {
      questionsAndAnswers.push(weeklyCardioExerciseHours);
    }

    const smoking = answersForQuestion.get(IsSmoker);
    if (smoking?.answer) {
      questionsAndAnswers.push(smoking);
    }

    const quitSmokingRecency = answersForQuestion.get(QuitSmokingRecency);
    if (quitSmokingRecency?.answer) {
      questionsAndAnswers.push(quitSmokingRecency);
    }

    const dailyCigarettesCount = answersForQuestion.get(DailyCigaretteCount);
    if (dailyCigarettesCount?.answer) {
      questionsAndAnswers.push(dailyCigarettesCount);
    }

    const snus = answersForQuestion.get(IsSnuser);
    if (snus?.answer) {
      questionsAndAnswers.push(snus);
    }

    const snusQuitRecency = answersForQuestion.get(QuitSnusingRecency);
    if (snusQuitRecency?.answer) {
      questionsAndAnswers.push(snusQuitRecency);
    }

    const snusFrequency = answersForQuestion.get(SnusHowOften);
    if (snusFrequency?.answer) {
      questionsAndAnswers.push(snusFrequency);
    }

    const alcoholAnswer = answersForQuestion.get(DoYouDrinkAlcohol);
    if (alcoholAnswer?.answer) {
      questionsAndAnswers.push(alcoholAnswer);
    }

    const weeklyDrinks = answersForQuestion.get(DrinkCount);
    if (weeklyDrinks) {
      questionsAndAnswers.push(weeklyDrinks);
    }

    const sunburn = answersForQuestion.get(SunburnFrequency);
    if (sunburn) {
      questionsAndAnswers.push(sunburn);
    }

    const cardiovascular = answersForQuestion.get(Cardiovascular);
    if (cardiovascular) {
      questionsAndAnswers.push(cardiovascular);
    }

    const cardiovascularDetails = answersForQuestion.get(
      CardiovascularDetailsQuestion
    );
    if (cardiovascularDetails) {
      questionsAndAnswers.push(cardiovascularDetails);
    }

    const heritage = answersForQuestion.get(FamilyHeritage);
    if (heritage) {
      questionsAndAnswers.push(heritage);
    }

    const skinCancer = answersForQuestion.get(SkinCancer);
    if (skinCancer) {
      questionsAndAnswers.push(skinCancer);
    }

    const skinCancerMelanoma = answersForQuestion.get(SkinCancerMelanoma);
    if (skinCancerMelanoma) {
      questionsAndAnswers.push(skinCancerMelanoma);
    }

    const skinCancerMelanomaWho = answersForQuestion.get(SkinCancerMelanomaWho);
    if (skinCancerMelanomaWho) {
      questionsAndAnswers.push(skinCancerMelanomaWho);
    }

    const diabetes = answersForQuestion.get(Diabetes);
    if (diabetes) {
      questionsAndAnswers.push(diabetes);
    }

    const diabetesDetails = answersForQuestion.get(DiabetesDetails);
    if (diabetesDetails) {
      questionsAndAnswers.push(diabetesDetails);
    }

    const relevantConditions = answersForQuestion.get(RelevantConditions);
    if (relevantConditions) {
      questionsAndAnswers.push(relevantConditions);
    }

    const relevantConditionsAutoimmuneDetails = answersForQuestion.get(
      RelevantConditionsAutoimmuneDetails
    );
    if (relevantConditionsAutoimmuneDetails) {
      questionsAndAnswers.push(relevantConditionsAutoimmuneDetails);
    }

    const relevantConditionsCancerDetails = answersForQuestion.get(
      RelevantConditionsCancerDetails
    );
    if (relevantConditionsCancerDetails) {
      questionsAndAnswers.push(relevantConditionsCancerDetails);
    }

    const relevantConditionsSomethingElseDetails = answersForQuestion.get(
      RelevantConditionsSomethingElseDetails
    );
    if (relevantConditionsSomethingElseDetails) {
      questionsAndAnswers.push(relevantConditionsSomethingElseDetails);
    }

    const pregnant = answersForQuestion.get(Pregnant);
    if (pregnant) {
      questionsAndAnswers.push(pregnant);
    }

    const allergies = answersForQuestion.get(Allergies);
    if (allergies) {
      questionsAndAnswers.push(allergies);
    }

    const allergiesList = answersForQuestion.get(AllergiesList);
    if (allergiesList) {
      questionsAndAnswers.push(allergiesList);
    }

    const allergiesOther = answersForQuestion.get(OtherAllergies);
    if (allergiesOther) {
      questionsAndAnswers.push(allergiesOther);
    }

    const medications = answersForQuestion.get(Medications);
    if (medications) {
      questionsAndAnswers.push(medications);
    }

    const medicationsType = answersForQuestion.get(MedicationsType);
    if (medicationsType) {
      questionsAndAnswers.push(medicationsType);
    }

    const medicationsSomethingElse = answersForQuestion.get(
      MedicationsSomethingElse
    );
    if (medicationsSomethingElse) {
      questionsAndAnswers.push(medicationsSomethingElse);
    }

    const skinConditions = answersForQuestion.get(SkinConditions);
    if (skinConditions) {
      questionsAndAnswers.push(skinConditions);
    }

    const skinConditionsType = answersForQuestion.get(SkinConditionsType);
    if (skinConditionsType) {
      questionsAndAnswers.push(skinConditionsType);
    }

    const skinConditionsSkinCancerType = answersForQuestion.get(
      SkinConditionsSkinCancerType
    );
    if (skinConditionsSkinCancerType) {
      questionsAndAnswers.push(skinConditionsSkinCancerType);
    }

    const medicalHistoryDiabetes = answersForQuestion.get(
      MedicalHistoryDiabetes
    );
    if (medicalHistoryDiabetes) {
      questionsAndAnswers.push(medicalHistoryDiabetes);
    }

    const medicalHistoryDiabetesType = answersForQuestion.get(
      MedicalHistoryDiabetesType
    );
    if (medicalHistoryDiabetesType) {
      questionsAndAnswers.push(medicalHistoryDiabetesType);
    }

    const medicalHistoryDiabetesComplications = answersForQuestion.get(
      MedicalHistoryDiabetesComplications
    );
    if (medicalHistoryDiabetesComplications) {
      questionsAndAnswers.push(medicalHistoryDiabetesComplications);
    }

    const bloodPressure = answersForQuestion.get(BloodPressure);
    if (bloodPressure) {
      questionsAndAnswers.push(bloodPressure);
    }

    const cardioConditions = answersForQuestion.get(CardioConditions);
    if (cardioConditions) {
      questionsAndAnswers.push(cardioConditions);
    }

    const cardioConditionsValvularDisease = answersForQuestion.get(
      CardioConditionsValvularDisease
    );
    if (cardioConditionsValvularDisease) {
      questionsAndAnswers.push(cardioConditionsValvularDisease);
    }

    const healthConditions = answersForQuestion.get(HealthConditions);
    if (healthConditions) {
      questionsAndAnswers.push(healthConditions);
    }

    const otherHealthConditions = answersForQuestion.get(OtherHealthConditions);
    if (otherHealthConditions) {
      questionsAndAnswers.push(otherHealthConditions);
    }

    const healthProblems = answersForQuestion.get(HealthProblems);
    if (healthProblems) {
      questionsAndAnswers.push(healthProblems);
    }

    const additionalWorries = answersForQuestion.get(AdditionalWorries);
    if (additionalWorries?.answer) {
      questionsAndAnswers.push(additionalWorries);
    }

    const questionnaireResponse: APITypesV2.QuestionnaireResponse = {
      patientId,
      questionnaireType: ONBOARDING_V2_QUESTIONNAIRE_TYPE,
      questionsAndAnswers,
      responseId: v4(),
      state: APITypesV2.QuestionnaireResponseState.Complete,
      visitId,
    };

    return saveOnboarding({
      apiClient: api,
      patientId,
      questionnaireResponse,
    });
  }

  return async function handleResponse({
    answersAndQuestions,
    visitId,
  }: {
    answersAndQuestions: Map<
      Question<unknown, unknown>,
      APITypesV2.QuestionAnswer
    >;
    // TODO: remove undefined once visitId is available in all questionnaire flows
    visitId: Visit["visitId"] | undefined;
  }) {
    return storeOnboardingNotes({ answersAndQuestions, visitId }).result;
  };
}
