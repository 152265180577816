import { useLatestScanQuery } from "render/hooks/api/queries/useLatestScanQuery";
import { QueryLink } from "render/routes/QueryLink";
import { queries } from "render/routes/paths";
import { useSkinLesionCountMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Metric, MetricLoading } from "../../layouts/Metric";
import { Trans } from "./trans";

export function MetricSkinLesions() {
  const latestSkinScanQuery = useLatestScanQuery("skin/");
  const skinLesionCountQuery = useSkinLesionCountMetric({
    scanId: latestSkinScanQuery.data?.at(0)?.id,
    scanVersion: latestSkinScanQuery.data?.at(0)?.version,
  });

  if (!skinLesionCountQuery.isFetched) {
    return <MetricLoading label={<Trans.MetricName />} />;
  }

  if (skinLesionCountQuery.data == null) {
    return null;
  }

  return (
    <QueryLink query={queries.metric} params={{ metric: ["skin"] }}>
      <Metric
        label={<Trans.MetricName />}
        metricRating={undefined}
        value={skinLesionCountQuery.data}
        previousValue={undefined}
      />
    </QueryLink>
  );
}
