import { PathRoute, useNav, useQueryParams } from "@pomle/react-router-paths";
import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { ReactComponent as QuestionIcon } from "assets/icons/24x24/24x24_chat.svg";
import { ReactComponent as ExIcon } from "assets/icons/24x24/24x24_close.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import { usePopup } from "render/context/PopupContext";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { useTracking } from "render/hooks/useTracking";
import { paths, queries } from "render/routes/paths";
import { ErrorBoundary } from "render/ui/format/ErrorBoundary";
import { Backdrop } from "render/ui/layout/Backdrop";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Nav } from "render/ui/layout/Nav";
import { IconButton } from "render/ui/trigger/IconButton";
import { ReportHistory } from "render/views/Report/AppointmentDataView/components/ReportHistory";
import { ReportHistoryItem } from "render/views/Report/AppointmentDataView/components/ReportHistoryItem";
import { reportViewTestIds } from "render/views/Report/AppointmentDataView/testIds";
import { MetricDetailsPanel } from "./MetricDetailsPanel";
import { AvatarSection } from "./components/AvatarSection";
import { ScanDataTab } from "./components/ScanDataTab";
import { SummariesTab } from "./components/SummariesTab/SummariesTab";
import styles from "./styles.module.sass";
import { reportEvent } from "./tracking";
import { Trans } from "./trans";

const MOBILE_BREAKPOINT = 980;
const MOBILE_OFFSET = 100;
const DESKTOP_OFFSET = 60 * 2;

export function ReportView() {
  const [params] = useQueryParams(queries.metric);
  const isHeaderActive = !params.metric.some(Boolean);

  const { trackEvent } = useTracking();
  const [transitioning, setTransitioning] = useState<boolean>(false);
  const [headerHeight, setHeaderHeight] = useState<number | undefined>(
    undefined
  );
  const headerRef = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const nav = {
    home: useNav(paths.root),
    summary: useNav(paths.appointmentSummary),
    summaryData: useNav(paths.appointmentSummaryData),
    summaryHistory: useNav(paths.appointmentSummaryHistory),
  };
  const popUpDialogContext = usePopup();
  const contactUsPopup = useContactUsPopup();

  const avatarMaxHeight = useMemo(() => {
    if (headerHeight == null) {
      return null;
    }
    const offSet =
      document.body.clientWidth > MOBILE_BREAKPOINT
        ? DESKTOP_OFFSET
        : MOBILE_OFFSET;

    return Math.min(document.body.clientHeight - headerHeight - offSet, 1000);
  }, [headerHeight]);

  useEffect(() => {
    if (!transitioning) {
      return;
    }

    const id = window.setTimeout(() => {
      setTransitioning(false);

      if (!container.current) {
        return;
      }
      if (!headerRef.current) {
        return;
      }

      if (document.body.clientWidth > MOBILE_BREAKPOINT) {
        container.current.scrollTo({ top: 0 });
        return;
      }

      const header = headerRef.current.clientHeight;
      const top = window.document.body.clientHeight - header - MOBILE_OFFSET;

      if (container.current.scrollTop < top) {
        return;
      }

      container.current.scrollTo({ top });
    }, 400);

    return () => window.clearTimeout(id);
  }, [transitioning]);

  useEffect(() => {
    if (!headerRef.current) {
      return;
    }
    setHeaderHeight(headerRef.current.clientHeight);
  }, []);

  return (
    <ViewStack>
      <FullScreenPageLayout
        ref={container}
        aria-hidden={!isHeaderActive}
        data-testid={reportViewTestIds.container}
      >
        <div className={styles.contentContainer}>
          <div
            ref={headerRef}
            className={styles.headerSticky}
            data-active={isHeaderActive}
            data-blured={popUpDialogContext.isActive}
          >
            <LogoHeader
              leftElement={
                <IconButton
                  onClick={nav.home.on({})}
                  icon={<ExIcon />}
                  ariaLabel={Trans.Icons.Close()}
                />
              }
              rightElement={
                <IconButton
                  icon={<QuestionIcon display="block" />}
                  onClick={() => contactUsPopup.emit()}
                  ariaLabel={Trans.Icons.ContactUs()}
                />
              }
            />
            <div className={styles.navTabsContainer}>
              <div className={styles.navTabs}>
                <Nav>
                  <Nav.Item
                    label={<Trans.Tabs.Summary />}
                    path={paths.appointmentSummary}
                    params={{}}
                    onClick={() => {
                      setTransitioning(true);
                      trackEvent(reportEvent.navigate("summary"));
                    }}
                  />
                  <Nav.Item
                    label={<Trans.Tabs.ScanData />}
                    path={paths.appointmentSummaryData}
                    params={{}}
                    onClick={() => {
                      setTransitioning(true);
                      trackEvent(reportEvent.navigate("biomarkers"));
                    }}
                  />
                </Nav>
              </div>
            </div>
          </div>
          <div className={styles.flex}>
            <div className={styles.content}>
              {avatarMaxHeight && (
                <ErrorBoundary
                  fallback={() => <div style={{ height: avatarMaxHeight }} />}
                >
                  <AvatarSection height={avatarMaxHeight} />
                </ErrorBoundary>
              )}
              <div
                className={styles.tabContentContainer}
                data-active={isHeaderActive}
              >
                <PathRoute path={paths.appointmentSummary}>
                  {(match) => (
                    <div
                      data-transitioning={transitioning}
                      data-active={
                        !!match &&
                        !window.location.pathname.startsWith(
                          paths.appointmentSummaryData.name
                        )
                      }
                      className={styles.tabContent}
                    >
                      <SummariesTab
                        onGoToScanData={() => {
                          setTransitioning(true);
                          trackEvent(reportEvent.navigate("biomarkers"));
                          nav.summaryData.go({});
                        }}
                        onGoToHistory={() => {
                          trackEvent(reportEvent.navigate("history"));
                          nav.summaryHistory.go({});
                        }}
                      />
                    </div>
                  )}
                </PathRoute>
                <PathRoute path={paths.appointmentSummaryData}>
                  {(match) => (
                    <div
                      data-transitioning={transitioning}
                      data-active={!!match}
                      className={styles.tabContent}
                    >
                      <ScanDataTab />
                    </div>
                  )}
                </PathRoute>
              </div>
            </div>
          </div>
        </div>
      </FullScreenPageLayout>
      <PathRoute path={paths.appointmentSummaryHistory}>
        {(match) => (
          <Backdrop
            active={!!match}
            kind="subtle"
            onOutsideClick={nav.summary.on({})}
          >
            <Sticky>{!!match && <ReportHistory />}</Sticky>
          </Backdrop>
        )}
      </PathRoute>
      <PathRoute path={paths.appointmentSummaryHistoryItem}>
        {(match) =>
          match && (
            <Backdrop
              active
              kind="transparent"
              onOutsideClick={nav.summaryHistory.on({})}
            >
              <Sticky>
                <ReportHistoryItem messageId={match.params.messageId} />
              </Sticky>
            </Backdrop>
          )
        }
      </PathRoute>
      <MetricDetailsPanel />
    </ViewStack>
  );
}
