import { APITypesV2 } from "@cur8/api-client";
import { fromAPI } from "@cur8/rich-entity";
import { skipToken, useQuery } from "@tanstack/react-query";
import { APIClient } from "lib/api/client";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { QueryOptions } from "typings/query";

const queryKey = (patientId: string, visitId: string | undefined) =>
  ["visit", patientId, visitId] as const;

const queryFn = (apiClient: APIClient, patientId: string, visitId: string) =>
  apiClient.visit
    .fetchVisit({ patientId, visitId })
    .result.then(fromAPI.toVisit);

type Body = Awaited<ReturnType<typeof queryFn>>;
type Key = ReturnType<typeof queryKey>;

export function useVisitQuery<T = Body>(
  visitId: string | undefined,
  options: QueryOptions<Body, Key, T> = {}
) {
  const { patientId } = useSession();
  const apiClient = useAPIClient();

  return useQuery({
    ...options,
    queryFn: visitId ? () => queryFn(apiClient, patientId, visitId) : skipToken,
    queryKey: queryKey(patientId, visitId),
  });
}

export function usePreVisitConsentStudiesForVisitId(visitId: string) {
  return useVisitQuery(visitId, {
    select: (data) => {
      return data.consents?.filter(
        (consent) =>
          consent.displayStages?.includes(
            APITypesV2.ConsentDisplayStage.PreVisit
          ) &&
          consent.consentType === APITypesV2.ConsentType.Study &&
          consent.signatureType === APITypesV2.ConsentSignatureType.Self
      );
    },
  });
}
