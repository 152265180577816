import { localize } from "render/context/InternationalizationContext";

const Subtitle = localize({
  en_GB: <>Your Neko Medical Team</>,
  sv_SE: <>Ditt Neko Medical Team</>,
});

export const Trans = {
  Subtitle,
};
