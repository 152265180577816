import { Typography } from "@cur8/maneki";
import { useLatestScanQuery } from "render/hooks/api/queries/useLatestScanQuery";
import { PostScanShareViewEntrypoint } from "render/views/PostScanShareView/components/PostScanShareViewEntrypoint";
import { MetricsSection } from "render/views/Report/AppointmentDataView/components/MetricsSection";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function ScanDataTab() {
  const latestScanQuery = useLatestScanQuery();
  const latestScanDate = latestScanQuery.data?.at(0)?.timestamp;

  return (
    <div className={styles.container} data-testid="scan-data-tab">
      <div className={styles.header}>
        <div className={styles.titles}>
          <Typography variant="display-s">
            <Trans.Title />
          </Typography>
          {latestScanDate && (
            <div className={styles.updated}>
              <Typography variant="body-s" color="subtle">
                <Trans.LastUpdated />
              </Typography>
              <Typography variant="body-s">
                &nbsp;
                {latestScanDate.toFormat("dd MMMM yyyy")}
              </Typography>
            </div>
          )}
        </div>
        <div className={styles.actions}>
          <PostScanShareViewEntrypoint />
        </div>
      </div>
      <MetricsSection />
      <Typography variant="body-s">
        <Trans.Disclaimer />
      </Typography>
    </div>
  );
}
