import { Typography } from "@cur8/maneki";

export function formatToDesignSystem(elements: HTMLCollectionOf<Element>) {
  return Array.from(elements)
    .filter((element) => element.tagName in variants)
    .map((element) => {
      const variant = variants[element.tagName];
      const parsedInnerHTML = element.innerHTML.replace(
        /href="/g,
        `target="_blank" rel="noreferrer" href="`
      );
      return (
        <Typography key={parsedInnerHTML} {...variant}>
          <span dangerouslySetInnerHTML={{ __html: parsedInnerHTML }} />
        </Typography>
      );
    });
}

const variants: Record<
  string,
  Pick<React.ComponentProps<typeof Typography>, "variant" | "color">
> = {
  H1: { color: "default", variant: "title-m" },
  H2: { color: "default", variant: "title-s" },
  P: { color: "subtle", variant: "body-m" },
};
