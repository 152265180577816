import { getRiskWeight, RiskLevel } from "@cur8/measurements";
import { desc } from "lib/sort";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useRiskMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

type useBloodPressureValuesType = {
  scanNum?: number;
  side: "left" | "right";
};

const EMPTY = {
  metricRating: undefined,
  previousValue: undefined,
  ranges: undefined,
  scanDate: undefined,
  subLabel: undefined,
  value: undefined,
};

export function useBloodPressureValues({
  scanNum,
  side,
}: useBloodPressureValuesType) {
  const metricName =
    side === "left"
      ? "cardio.brachial_pressure.left"
      : "cardio.brachial_pressure.right";
  const { data, isLoading } = useRiskMetric(metricName);

  if (isLoading) {
    return "loading";
  }

  if (!data) {
    return EMPTY;
  }

  const { cur, prev } = getMetricHistory(data, scanNum);
  const { riskIntervals } = cur?.riskRanges.systolic ?? {};

  const [riskLevel = RiskLevel.Unknown] = [
    cur?.riskRanges.systolic.riskLevel,
    cur?.riskRanges.diastolic.riskLevel,
  ]
    .filter(isDefined)
    .toSorted(desc(getRiskWeight));

  // currently we are displaying only Systolic values
  return {
    maxNumOfRanges: 4,
    metricRating: riskLevel,
    previousValue: prev?.unit.systolic.mmHg,
    ranges: toChartRanges(riskIntervals),
    // https://dev.azure.com/cur8/Engineering/_workitems/edit/26138
    rangesCapHigh: 150,
    rangesCapLow: 70,
    scanDate: cur?.measurement.timestampStart,
    subLabel: !!cur && (
      <Trans.MetricUnit unit={`/ ${cur.unit.diastolic.mmHg}`} />
    ),
    value: cur?.unit.systolic.mmHg,
  };
}

function isDefined<T>(value: T | undefined): value is T {
  return Boolean(value);
}
