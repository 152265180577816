import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useSession } from "render/context/MSALContext";
import { Slot, fromAPI } from "@cur8/rich-entity";

export function useBookSlotMutation() {
  const apiClient = useAPIClient();
  const { patientId } = useSession();

  const mutation = useCallback(
    (slot: Slot) => {
      const payload = { patientId };
      return apiClient.bookingV2
        .bookSlot({ payload, slot })
        .result.then(fromAPI.toSlot);
    },
    [apiClient.bookingV2, patientId]
  );

  return mutation;
}
