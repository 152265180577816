import { Button, Typography } from "@cur8/maneki";
import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as ArrowIcon } from "assets/icons/24x24/24x24_arrow-right.svg";
import { ReactComponent as QuestionIcon } from "assets/icons/24x24/24x24_chat.svg";
import { ReactComponent as ExIcon } from "assets/icons/24x24/24x24_close.svg";
import background from "assets/images/dot-pattern.webp";
import { splitSlotId } from "lib/visit/splitSlotId";
import { useSlotQuery } from "render/hooks/api/queries/useSlotQuery";
import { useVisitPrerequisites } from "render/hooks/api/useVisitPrerequisites";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { IconButton } from "render/ui/trigger/IconButton";
import { StepsTodoList } from "render/views/appointment/AppointmentView/components/AppointmentInfoSection/components/StepsSection";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function AppointmentPrereqView({ slotId }: { slotId: string }) {
  const { visitId } = splitSlotId(slotId);
  const { data: slot } = useSlotQuery({ slotId });
  const visitPrerequisites = useVisitPrerequisites({
    origin: "prereq",
    visitId,
  });
  const contactUsPopup = useContactUsPopup();
  const nav = {
    appointment: useNav(paths.appointment),
  };

  const controls = {
    onCompleteClick: () => {
      nav.appointment.go({ slotId });
    },
    onNextStep: () => {
      visitPrerequisites.getNextStep()?.(slotId);
    },
  };

  return (
    <FullScreenPageLayout background="porcelain">
      <div className={styles.AppointmentPrereqView}>
        <LogoHeader
          sticky
          leftElement={
            <IconButton
              onClick={nav.appointment.on({ slotId })}
              icon={<ExIcon />}
            />
          }
          rightElement={
            <IconButton
              icon={<QuestionIcon display="block" />}
              onClick={contactUsPopup.emit}
            />
          }
        />
        <img className={styles.background} src={background} alt="Background" />

        {slot && visitPrerequisites.hasLoaded && (
          <>
            <div className={styles.content}>
              <div className={styles.text}>
                <Typography variant="display-s">
                  {visitPrerequisites.isReady ? (
                    <Trans.Title.Ready />
                  ) : (
                    <Trans.Title.NotReady />
                  )}
                </Typography>
                <Typography variant="body-m">
                  {visitPrerequisites.isReady ? (
                    <Trans.Description.Ready />
                  ) : (
                    <Trans.Description.NotReady />
                  )}
                </Typography>
              </div>

              <StepsTodoList steps={visitPrerequisites.steps} slot={slot} />

              <span className={styles.button}>
                <Button
                  onPress={
                    visitPrerequisites.isReady
                      ? controls.onCompleteClick
                      : controls.onNextStep
                  }
                  icon={<ArrowIcon />}
                >
                  {visitPrerequisites.isReady && <Trans.CTA.Done />}
                  {!visitPrerequisites.isReady && <Trans.CTA.ToDo />}
                </Button>
              </span>
            </div>
          </>
        )}
      </div>
    </FullScreenPageLayout>
  );
}
