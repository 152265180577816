import { APITypesV1 } from "@cur8/api-client";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { Accordion } from "render/ui/presentation/Accordion";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface InstructionsProps {
  returnAt: DateTime;
  returnLocation?: APITypesV1.DeviceReturnLocation;
  doctorsNote: string | null | undefined;
}

export function Instructions({
  returnAt,
  returnLocation,
  doctorsNote,
}: InstructionsProps) {
  const returnInfo = useMemo(() => {
    if (returnLocation == null) {
      return null;
    }
    return {
      from: returnAt.toFormat("HH:mm"),
      location: returnLocation,
      to: returnAt.plus({ minutes: 30 }).toFormat("HH:mm"),
    };
  }, [returnAt, returnLocation]);

  return (
    <ol className={styles.Instructions}>
      {!doctorsNote && (
        <li>
          <Accordion
            header={
              <span className={styles.header}>
                <Trans.HowItWorks.Header />
              </span>
            }
            text={
              <div className={styles.explanation}>
                <span>
                  <Trans.HowItWorks.Text1 />
                </span>
                <span>
                  <Trans.HowItWorks.Text2 />
                </span>
                <span>
                  <Trans.HowItWorks.Text3 />
                </span>
              </div>
            }
          />
        </li>
      )}

      {returnInfo && (
        <li>
          <Accordion
            header={
              <span className={styles.header}>
                <Trans.HowToReturn.Header />
              </span>
            }
            text={
              <div className={styles.explanation}>
                <span>
                  <Trans.HowToReturn.Text
                    from={returnInfo.from}
                    to={returnInfo.to}
                    place={<Trans.ReturnLocation.Regeringsgatan />}
                  />
                </span>
              </div>
            }
          />
        </li>
      )}
    </ol>
  );
}
