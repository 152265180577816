import { Answers } from "@cur8/questionnaire";
import { localeToISO639Code } from "lib/i18n/localeToISO639Code";
import {
  SkinConditions,
  SkinConditionsEnum,
  SkinConditionsSkinCancerType,
  SkinConditionsType,
  SkinConditionsTypeEnum,
} from "lib/questionnaire/question-flows/onboarding/questions/skinConditions";
import { Trans as TransQuestions } from "lib/questionnaire/question-flows/onboarding/questions/trans";
import { useLocale } from "render/context/InternationalizationContext";
import { ChoiceInput } from "render/ui/presentation/Questionnaire/components/ChoiceInput";
import { ChoiceInputWithDescriptions } from "render/ui/presentation/Questionnaire/components/ChoiceInputWithDescriptions";
import { MultiChoiceInputWithDescriptions } from "render/ui/presentation/Questionnaire/components/MultiChoiceInputWithDescriptions";
import { QuestionView } from "../../components/QuestionView";
import { Step, StepProps } from "../../step";
import { Trans } from "../../trans";

function SkinConditionsStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(SkinConditions);
  const label = TransQuestions.SkinConditions.Question();
  const { englishLabel } = TransQuestions.SkinConditions.Question;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={SkinConditions}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(SkinConditions);
      }}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.SkinConditionsExplanation />,
        openText: <Trans.General.WhyAreWeAsking />,
      }}
    >
      <ChoiceInput
        question={SkinConditions}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(SkinConditions, {
            answer,
            questionAnswer: {
              answer: {
                $type: "stringenum",
                value: SkinConditions.getValue(answer) || "",
              },
              englishLabel,
              label,
              languageCode,
              questionId: "skinConditions",
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

function SkinConditionsTypeStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(SkinConditionsType);
  const label = TransQuestions.SkinConditions.TypeQuestion();
  const { englishLabel } = TransQuestions.SkinConditions.TypeQuestion;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={SkinConditionsType}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(SkinConditionsType);
      }}
      cta={<Trans.General.Continue />}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.SkinConditionsExplanation />,
        openText: <Trans.General.WhyAreWeAsking />,
      }}
    >
      <MultiChoiceInputWithDescriptions
        question={SkinConditionsType}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(SkinConditionsType, {
            answer,
            questionAnswer: {
              answer: {
                $type: "textarray",
                value: SkinConditionsType.getValue(answer),
              },
              englishLabel,
              label,
              languageCode,
              questionId: "skinConditions.type",
            },
          });
        }}
      />
    </QuestionView>
  );
}

function SkinConditionsSkinCancerStep({
  answers,
  onAnswer,
  onNext,
  onPrev,
  currentQuestion,
  totalQuestions,
  onResetAnswer,
}: StepProps) {
  const answer = answers.get(SkinConditionsSkinCancerType);
  const label = TransQuestions.SkinConditions.SkinCancerQuestion();
  const { englishLabel } = TransQuestions.SkinConditions.SkinCancerQuestion;
  const locale = useLocale();
  const languageCode = localeToISO639Code(locale);

  return (
    <QuestionView
      currentQuestion={currentQuestion}
      totalQuestionCount={totalQuestions}
      question={SkinConditionsSkinCancerType}
      onNext={onNext}
      onPrev={() => {
        onPrev();
        onResetAnswer(SkinConditionsSkinCancerType);
      }}
      explanation={{
        closeText: <Trans.General.OkIUnderstand />,
        explanationText: <Trans.Steps.SkinConditionsSkinCancerExplanation />,
        openText: <Trans.General.WhyAreWeAsking />,
      }}
    >
      <ChoiceInputWithDescriptions
        question={SkinConditionsSkinCancerType}
        answer={answer}
        onAnswer={(answer) => {
          onAnswer(SkinConditionsSkinCancerType, {
            answer,
            questionAnswer: {
              answer: {
                $type: "stringenum",
                value: SkinConditionsSkinCancerType.getValue(answer) || "",
              },
              englishLabel,
              label,
              languageCode,
              questionId: "skinConditions.skinCancerType",
            },
          });
          onNext();
        }}
      />
    </QuestionView>
  );
}

export function filter(steps: Step[], answers: Answers) {
  steps.push(SkinConditionsStep);

  if (answers.lookup(SkinConditions) === SkinConditionsEnum.Yes) {
    steps.push(SkinConditionsTypeStep);
  }

  if (
    answers
      .lookup(SkinConditionsType)
      ?.includes(SkinConditionsTypeEnum.SkinCancer)
  ) {
    steps.push(SkinConditionsSkinCancerStep);
  }
}
