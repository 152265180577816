import { localize } from "render/context/InternationalizationContext";
import styles from "./styles.module.sass";

const Header = localize({
  en_GB: (
    <>
      Almost <span className={styles.whiteText}>there...</span>
    </>
  ),
  sv_SE: (
    <>
      Nästan <span className={styles.whiteText}>där...</span>
    </>
  ),
});

const DescriptionPart1 = localize({
  en_GB: <>To have a scan you must participate in our clinical studies.</>,
  sv_SE: <>För att göra en scan måste du delta i våra kliniska studier.</>,
});

const DescriptionPart2 = localize({
  en_GB: <>Please review the details on the next page to continue.</>,
  sv_SE: <>Gå igenom detaljerna på nästa sida för att fortsätta.</>,
});

const Review = localize({
  en_GB: "Review",
  sv_SE: "Granska",
});

export const Trans = {
  DescriptionPart1,
  DescriptionPart2,
  Header,
  Review,
};
