import { AriaAttributes } from "react";

export function pickDataAttributes(
  props: Record<string, unknown>
): Record<string, unknown> {
  const dataAttributes: Record<string, unknown> = {};

  Object.entries(props).forEach(([key, value]) => {
    if (key.startsWith("data-")) {
      dataAttributes[key] = value;
    }
  });

  return dataAttributes;
}

export function pickAriaAttributes(props: AriaAttributes): AriaAttributes {
  const ariaAttributes: AriaAttributes = {};

  Object.entries(props).forEach(([key, value]) => {
    if (key.startsWith("aria-")) {
      ariaAttributes[key as keyof AriaAttributes] = value;
    }
  });

  return ariaAttributes;
}
