import { Link, Typography } from "@cur8/maneki";
import { Patient, Site, Slot } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as CheckMarkIcon } from "assets/icons/16x16/16x16_checkmark.svg";
import { ReactComponent as ClockIcon } from "assets/icons/16x16/16x16_clock.svg";
import { ReactComponent as PinIcon } from "assets/icons/16x16/16x16_pin.svg";
import { ReactNode, RefObject, useEffect, useMemo, useState } from "react";
import { flushSync } from "react-dom";
import { useConfig } from "render/context/ConfigContext";
import { paths, queries } from "render/routes/paths";
import { Accordion } from "render/ui/presentation/AccordionV2";
import BookingDetailSquareImage from "./assets/img/booking-detail-square.webp";
import BookingDetailWideImage from "./assets/img/booking-detail-wide.webp";
import { ReactComponent as MapPinIcon } from "./assets/img/map_pin.svg";
import { CompleteProfileButton } from "./components/CompleteProfileButton";
import { HelpSection, InfoList, TipsSection } from "./components/Info";
import { StepsSection } from "./components/StepsSection";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

type AppointmentInfoSectionProps = {
  patient: Patient;
  scrollingElement: RefObject<HTMLElement>;
  slot: Slot;
  visitId: string;
};

export function AppointmentInfoSection({
  patient,
  scrollingElement,
  slot,
  visitId,
}: AppointmentInfoSectionProps) {
  const site = slot.room?.site;

  return (
    <div className={styles.AppointmentInfoSection}>
      <section className={styles.head}>
        <div className={styles.hero}>
          <ParallaxHeroImage
            siteId={site?.siteId}
            scrollingElement={scrollingElement}
          />
        </div>
      </section>

      <div className={styles.content}>
        <div className={styles.body}>
          <section className={styles.top}>
            <div>
              <div className={styles.badge}>
                <CheckMarkIcon />
                <Typography variant="eyebrow-m">
                  <Trans.Status />
                </Typography>
              </div>
              <Typography variant="display-s">
                <Trans.Title />
              </Typography>
            </div>

            {site && <SlotInfo site={site} slot={slot} />}
          </section>

          <section className={styles.stepsSection}>
            <div className={styles.stepsContainer}>
              <StepsSection slot={slot} visitId={visitId} />
            </div>
          </section>

          <section>
            <TipsSection />
          </section>

          <section className={styles.sectionFAQ}>
            <Typography variant="title-m">
              <Trans.FAQ.Title />
            </Typography>
            <Accordion>
              <Accordion.Item
                header={<Trans.FAQ.Skin.Header />}
                text={<Trans.FAQ.Skin.Body Ul={InfoList} />}
                id={Trans.FAQ.Skin.Header()}
              />
              <Accordion.Item
                header={<Trans.FAQ.Heart.Header />}
                text={<Trans.FAQ.Heart.Body Ul={InfoList} />}
                id={Trans.FAQ.Heart.Header()}
              />
              <Accordion.Item
                header={<Trans.FAQ.Blood.Header />}
                text={<Trans.FAQ.Blood.Body Ul={InfoList} />}
                id={Trans.FAQ.Blood.Header()}
              />
              <Accordion.Item
                header={<Trans.FAQ.Consultation.Header />}
                text={<Trans.FAQ.Consultation.Body Ul={InfoList} />}
                id={Trans.FAQ.Consultation.Header()}
              />
            </Accordion>
          </section>

          <section>
            <HelpSection patient={patient} />
          </section>

          <section className={styles.mobileProfileButton}>
            <CompleteProfileButton slot={slot} visitId={visitId} />
          </section>
        </div>
      </div>
    </div>
  );
}

type TimeLocationProps = {
  icon: ReactNode;
  title: ReactNode;
  subtitle: ReactNode;
  link: ReactNode;
};

function TimeLocation(props: TimeLocationProps) {
  return (
    <div className={styles.timeLocation}>
      <div className={styles.icon}>{props.icon}</div>
      <div className={styles.textOuter}>
        <div className={styles.textInner}>
          <Typography variant="label-m" color="default">
            {props.title}
          </Typography>
          <Typography variant="body-s" color="subtle">
            {props.subtitle}
          </Typography>
        </div>
        <div className={styles.link}>{props.link}</div>
      </div>
    </div>
  );
}

type SlotInfoProps = {
  site: Site;
  slot: Slot;
};

function SlotInfo({ slot, site }: SlotInfoProps) {
  const timeZoneId = site.timeZoneId;

  const { formattedTime, arriveTime } = useMemo(() => {
    const start = slot.startTime.setZone(timeZoneId);
    const end = slot.endTime.setZone(timeZoneId);
    const formattedTime = `${start.toFormat("ccc, d LLLL y, HH:mm")} - ${end.toFormat("HH:mm")}`;
    const arriveTime = start.minus({ minutes: 10 }).toFormat("HH:mm");
    return { arriveTime, formattedTime };
  }, [slot, timeZoneId]);

  const config = useConfig();
  const [hideMap, setHideMap] = useState(false);

  const address = site.address;

  // https://developers.google.com/maps/documentation/urls/get-started#search-action
  const mapQuery = new URLSearchParams({
    api: "1",
    query: `${address.name}, ${address.street}, ${address.city}`,
  });
  const mapUrl = `https://www.google.com/maps/search/?${mapQuery}`;

  const nav = {
    calendarLinks: useNav(paths.appointment, queries.appointment),
  };

  return (
    <>
      <TimeLocation
        icon={<ClockIcon />}
        title={formattedTime}
        subtitle={Trans.CheckIn({ time: arriveTime })}
        link={
          <Link
            href={nav.calendarLinks.to(
              { slotId: slot.slotId },
              { calendarVisible: [true] }
            )}
            size="small"
            variant="tertiary"
          >
            <Trans.TimeLocation.AddToCalendar />
          </Link>
        }
      />

      <TimeLocation
        icon={<PinIcon />}
        title={Trans.TimeLocation.siteName(site.siteName)}
        subtitle={address.street}
        link={
          <Link
            href={mapUrl}
            rel="noreferrer"
            size="small"
            target="_blank"
            variant="tertiary"
          >
            <Trans.TimeLocation.GetDirections />
          </Link>
        }
      />
      {hideMap ? null : (
        <a
          href={mapUrl}
          className={styles.map}
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={`${config.appConfig.siteAssetsUrl}/site/${site.siteId}/image/location.webp`}
            alt="map"
            loading="lazy"
            onError={() => setHideMap(true)}
          />
          <MapPinIcon className={styles.mapPin} />
        </a>
      )}
    </>
  );
}

type HeroImageProps = {
  scrollingElement: RefObject<HTMLElement>;
  siteId: string | undefined;
};

function ParallaxHeroImage(props: HeroImageProps) {
  const [scrollOffset, setScrollOffset] = useState(0);
  const [showFallback, setShowFallback] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const config = useConfig();

  useEffect(() => {
    const div = props.scrollingElement.current;
    if (!div) {
      return;
    }
    const handleScroll = () => flushSync(() => setScrollOffset(div.scrollTop));

    div.addEventListener("scroll", handleScroll, { passive: true });
    return () => div.removeEventListener("scroll", handleScroll);
  }, [props.scrollingElement]);

  const parallaxStrength = 0.3;

  if (!props.siteId) {
    return <div className={styles.image} />;
  }

  return (
    <picture>
      {!showFallback && (
        <>
          <source
            srcSet={`${config.appConfig.siteAssetsUrl}/site/${props.siteId}/image/background-square.jpg`}
            media="(max-width: 980px)"
          />
          <source
            srcSet={`${config.appConfig.siteAssetsUrl}/site/${props.siteId}/image/background-wide.jpg`}
          />
        </>
      )}

      <source srcSet={BookingDetailSquareImage} media="(max-width: 980px)" />
      <img
        alt="room"
        className={styles.image}
        data-loaded={loaded}
        height="1200"
        style={{
          transform: `translateY(${scrollOffset * parallaxStrength}px)`,
        }}
        width="3040"
        src={BookingDetailWideImage}
        onError={() => setShowFallback(true)}
        onLoad={() => setLoaded(true)}
      />
    </picture>
  );
}
