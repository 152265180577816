import { APITypesV1 } from "@cur8/api-client";
import { clamp } from "lib/math";
import { asc } from "lib/sort";
import React, { useMemo } from "react";
import { Trans } from "./trans";
import { ChartRange, MarkerData, RangeData, Variant } from "./types";

const VariantMap: Record<APITypesV1.RiskType, Variant> = {
  [APITypesV1.RiskType.Unknown]: "normal",
  [APITypesV1.RiskType.Optimal]: "normal",
  [APITypesV1.RiskType.Normal]: "normal",
  [APITypesV1.RiskType.LowRisk]: "warning",
  [APITypesV1.RiskType.Risk]: "warning",
  [APITypesV1.RiskType.ModerateRisk]: "danger",
  [APITypesV1.RiskType.HighRisk]: "danger",
  [APITypesV1.RiskType.ImmediateRisk]: "danger",
};

interface ChildrenData {
  values: MarkerData[];
  ranges: RangeData[];
}

interface RangeChartDataComposerProps {
  ranges: ChartRange[];
  rangesCapLow?: number;
  rangesCapHigh?: number;
  value?: number;
  previousValue?: number;
  children: (data: ChildrenData) => React.ReactElement;
  scanNum: number;
}

export function RangeChartDataComposer({
  ranges,
  rangesCapLow,
  rangesCapHigh,
  children,
  value,
  previousValue,
  scanNum,
}: RangeChartDataComposerProps) {
  const sortedRanges = useMemo(
    () => ranges.toSorted(asc((r) => r.to)),
    [ranges]
  );

  const cappedRanges = useMemo(() => {
    return sortedRanges.map((range, i, ranges) => {
      const isLowestRange = i === 0;
      const isHighestRange = i === ranges.length - 1;

      let rangeFrom = range.from;
      let rangeTo = range.to;

      if (rangesCapLow && isLowestRange) {
        // implement custom cap on the lowest range but prevent squishing
        rangeFrom = clamp(rangesCapLow, rangeFrom, rangeTo * 0.9);
      }

      if (rangesCapHigh && isHighestRange) {
        // implement custom cap on the highest range but prevent squishing
        rangeTo = clamp(rangesCapHigh, rangeFrom * 1.1, rangeTo);
      }

      // cap from infinity
      if (!isFinite(rangeFrom)) {
        rangeFrom = rangeTo * 0.9;
      }

      // cap to infinity
      if (!isFinite(rangeTo)) {
        rangeTo = rangeFrom * 2.2;
      }

      return {
        ...range,
        from: rangeFrom,
        to: rangeTo,
      };
    });
  }, [sortedRanges, rangesCapLow, rangesCapHigh]);

  const max = Math.max(...cappedRanges.map((range) => range.to));
  const min = Math.min(...cappedRanges.map((range) => range.from));

  const rangeData: RangeData[] = useMemo(() => {
    return cappedRanges.map((range) => ({
      from: range.from,
      label: range.label,
      to: range.to,
      variant: VariantMap[range.risk],
      width: Math.floor(((range.to - range.from) / (max - min)) * 100),
    }));
  }, [cappedRanges, max, min]);

  const values: MarkerData[] = useMemo(() => {
    if (value === undefined) return [];

    const areValuesTheSame = previousValue === value;
    const primaryVariant = areValuesTheSame ? "primary-outlined" : "primary";
    const valueMaker: MarkerData = {
      key: `marker_${value}_${primaryVariant}_${scanNum}`,
      label: <Trans.Scan scanNum={scanNum} />,
      value: clamp(value, min, max),
      variant: primaryVariant,
    };

    const previousValueMaker: MarkerData | undefined =
      previousValue !== undefined
        ? {
            key: `marker_${value}_outlined_${scanNum}`,
            label: <Trans.Scan scanNum={scanNum - 1} />,
            value: clamp(previousValue, min, max),
            variant: "outlined",
          }
        : undefined;

    return [previousValueMaker, valueMaker].filter(
      (value): value is MarkerData => value !== undefined
    );
  }, [value, previousValue, min, max, scanNum]);

  return <>{children({ ranges: rangeData, values })}</>;
}
