import type { ReactNode } from "react";
import styles from "./styles.module.sass";

type OpaqueLayerProps = Omit<JSX.IntrinsicElements["div"], "className"> & {
  children?: ReactNode;
  isActive: boolean | undefined;
  /** @default 'blur' */
  variant?: "blur" | "dark";
};

export function OpaqueLayer({
  children,
  isActive,
  variant = "blur",
  ...props
}: OpaqueLayerProps) {
  return (
    <div
      {...props}
      className={styles.OpaqueLayer}
      data-active={!!isActive}
      data-variant={variant}
    >
      {children}
    </div>
  );
}
